import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { Input } from '@mui/material';

import DepartmentsSelect from '../selects/departmentsSelect';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function EditSubjectDialog(props) {
  const { subject, subjectList, setSubjectList } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [currentDepartmentName, setCurrentDepartmentName] = React.useState('');

  const [departmentsList, setDepartmentsList] = React.useState([]);
  const [selectedDepartment, setSelectedDepartment] = React.useState('');
  React.useEffect(() => {
    const getDepartments = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/hotels/get-departments')
        .catch((e) => {
          if (e.response.status === 404) {
            console.log(e.response.data.message);
            // return e.response.data.message;
          }
        });
      if (res) {
        // console.log(res.data);
        const index = res.data.findIndex(
          (dep) => dep._id === subject.department,
        );
        setCurrentDepartmentName(res.data[index].name);
        setDepartmentsList(res.data);
      }
    };

    getDepartments();
  }, []);

  const editSubject = async function () {
    const subjectName = document.getElementById('subjectName').value;

    const updatedSubjectName =
      subjectName !== subject.name && subjectName !== ''
        ? subjectName
        : subject.name;

    const updatedDepartment =
      subject.department !== selectedDepartment && selectedDepartment !== ''
        ? selectedDepartment
        : subject.department;

    const index = subjectList.findIndex((sub) => sub._id === subject._id);
    subjectList[index].department = updatedDepartment;
    subjectList[index].name = updatedSubjectName;
    setSubjectList([...subjectList]);

    const dpeIndex = departmentsList.findIndex(
      (dep) => dep._id === subject.department,
    );
    setCurrentDepartmentName(departmentsList[dpeIndex].name);

    axios
      .put(process.env.REACT_APP_BASE_API_URL + '/hotels/edit-subject', {
        departmentId: updatedDepartment,
        subjectName: updatedSubjectName,
        subjectId: subject._id,
      })
      .catch((e) => console.log(e));

    handleClose();
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} aria-label="delete">
        <EditIcon />
      </IconButton>

      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Edit {subject.name} Subject
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Typography>Current Department: {currentDepartmentName}</Typography>
          <DepartmentsSelect
            departmentsList={departmentsList}
            setSelectedDepartment={setSelectedDepartment}
          />
          <Input
            id="subjectName"
            dir="rtl"
            placeholder="Enter New Subject name"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={editSubject}>
            Save
          </Button>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
