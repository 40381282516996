import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import AI from './../../assets/AII.PNG';
import './index.css';
const GettingStarting = () => {
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={6} sx={{ mt: 10, pl: 6 }}>
          <Typography sx={{ fontSize: '50px', fontWeight: '600' }}>
            IN VOICEBACK.AI REVIEWS THEY TRUST.
          </Typography>
          <Typography sx={{ fontSize: '24px' }}>
            Start collecting genuine recorded reviews from your happy customers
            and start upgrading your google star rate and business performance
            {/* Start collecting genuine reviews from your happy customers and start 
          upgrading your business performance */}
          </Typography>
          <a href="#pricing" style={{ color: 'black', textDecoration: 'none' }}>
            <Button
              className="starting-button"
              sx={{
                px: 2,
                my: 2,
              }}
              // onClick={() => navigate("/home")}
            >
              Get Started Now
            </Button>
          </a>
          <Typography>
            Need any help?<Link to="#"> Contact us</Link>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <img src={AI} alt="" />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GettingStarting;
