import React, { createContext, useContext, useState } from 'react';

const AppStateContext = createContext();

export const useAppState = () => useContext(AppStateContext);

export const AppStateProvider = ({ children }) => {
  const [storeId, setStoreId] = useState(null);
  const [storeVersion, setStoreVersion] = useState(null);

  const updateStoreId = (data) => {
    setStoreId(data);
  };

  const updateStoreVersion = (data) => {
    setStoreVersion(data);
  };

  return (
    <AppStateContext.Provider
      value={{ storeId, updateStoreId, storeVersion, updateStoreVersion }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
