import React, { useEffect } from 'react';
import {
  Typography,
  Container,
  Input,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  AppBar,
  Toolbar,
  Slide,
} from '@mui/material';
import axios from 'axios';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SubjectSentencesDialog(props) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);

  useEffect(() => {
    const results = props.subjectSentences.filter((sentence) =>
      sentence.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setSearchResults(results);
  }, [searchTerm, props.subjectSentences]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const deleteSentence = async (sentence) => {
    const index = searchResults.indexOf(sentence);
    searchResults.splice(index, 1);
    setSearchResults([...searchResults]);

    await axios.delete(
      process.env.REACT_APP_BASE_API_URL + '/italianSubjects/delete-sentence',
      {
        params: {
          sentence: sentence,
          type: props.selectedType,
          questionNumber: props.questionNumber,
          name: props.selectedSubject,
          direction: props.selectedDirection,
        },
      },
    );
  };

  return (
    <React.Fragment>
      <Container>
        <Dialog
          // fullScreen
          scroll="paper"
          open={props.openSubjectSentencesDialog}
          onClose={props.handleCloseSubjectSentencesDialog}
          TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={props.handleCloseSubjectSentencesDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                subject: {props.selectedSubject}
              </Typography>
            </Toolbar>
            <Input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleChange}
            />
          </AppBar>
          <List>
            {searchResults.map((sentence, index) => {
              return (
                <ListItem>
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteSentence(sentence)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <ListItemText primary={sentence} />
                </ListItem>
              );
            })}
          </List>
        </Dialog>
      </Container>
    </React.Fragment>
  );
}

export default SubjectSentencesDialog;
