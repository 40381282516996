import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from '@mui/material';

import axios from 'axios';

const theme = createTheme();

const scriptsComparison = () => {
  const [notSaleScript, setNotSaleScript] = useState([]);
  const [saleScript, setSaleScript] = useState([]);
  const [recommendations, setRecommendations] = useState([]);

  // const [saleSentences60to70Match, setSaleSentences60to70Match] = useState([]);
  // const [saleSentences70to80Match, setSaleSentences70to80Match] = useState([]);

  const [score, setScore] = useState(0);
  const [saleScriptSavedMessage, setSaleScriptSavedMessage] = useState('');

  const [error, setError] = useState('');
  const uploadAudioFile = async (e) => {
    e.preventDefault();
    setError('');
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    document.getElementById('audioFile').value = null;
    setNotSaleScript([]);
    setSaleScript([]);

    const uploadedAudioFileName = await axios.post(
      process.env.REACT_APP_BASE_API_URL + '/speechToText/upload-audio-file',
      formData,
    );

    const response = await axios
      .get(
        process.env.REACT_APP_BASE_API_URL + '/emotions/get-compared-scripts',
        {
          params: {
            fileName: uploadedAudioFileName.data,
          },
        },
      )
      .catch((error) => {
        if (error.response.data) {
          console.log(error.response.data);
          console.log(error.response.data.message);
          setError(error.response.data.message);
        } else {
          console.log(error);
        }
      });

    // console.log(response);
    if (!response) {
      return null;
    }

    // const scriptSentencesParams = response.data.scriptSentencesParams;
    // const matchBetween60and70 = [];
    // const matchBetween70and80 = [];
    // for (const sentence of scriptSentencesParams) {
    //   if (sentence.isSaleSentence && sentence.showSentence) {
    //     matchBetween70and80.push(sentence.saleSentence);
    //   } else if (!sentence.isSaleSentence && sentence.showSentence) {
    //     matchBetween60and70.push(sentence.saleSentence);
    //   }
    // }
    // setSaleSentences60to70Match(matchBetween60and70);
    // setSaleSentences70to80Match(matchBetween70and80);

    const comparedScripts = response.data.scripts;
    // console.log(response.data.recommendations);
    const recommendationsData = response.data.recommendations;
    const excelFilePath = response.data.excelFilePath;
    // console.log(response.data.excelFilePath);
    // console.log(response.data.scripts);

    if (excelFilePath) {
      await axios
        .get(
          process.env.REACT_APP_BASE_API_URL +
            '/emotions/download-recommendations-excel-file',
          { responseType: 'blob', params: { excelFilePath } },
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'recommendations.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          //const {message} = error.response.data;
          console.log(error);
          //setError(message);
        });
    }

    showComparedScriptsAndRecommendations(comparedScripts, recommendationsData);
  };

  const showComparedScriptsAndRecommendations = (
    comparedScripts,
    recommendations,
  ) => {
    if (isObjectEmpty(comparedScripts)) {
      setSaleScriptSavedMessage('The audio SALE script is saved');
    } else {
      setSaleScript(comparedScripts.saleScript.sentences);
      setScore(comparedScripts.score.toFixed(2));
      setNotSaleScript(comparedScripts.notSaleScript.sentences);
      setRecommendations(recommendations); //
    }
  };

  const isObjectEmpty = (objectName) => {
    return (
      Object.keys(objectName).length === 0 && objectName.constructor === Object
    );
  };

  // const saveSaleSentence = async () => {
  //   const sentence = document.getElementById("saleScriptInput").value.trim();
  //   document.getElementById("saleScriptInput").value = "";
  //   await axios.post(
  //     process.env.REACT_APP_BASE_API_URL + "/emotions/save-sale-sentence",
  //     { sentence }
  //   );
  // };

  const cellStyle = {
    border: '2px solid #E6E6E6',
    width: '2%',
    // height: "120px",
  };

  const cellTextStyle = {
    border: '2px solid #E6E6E6',
    width: '15%',
    // height: "120px",
  };

  // const emotionsDbInit = async () => {
  //   await axios.post(
  //     process.env.REACT_APP_BASE_API_URL + '/emotions/emotions-db-init',
  //   );
  // };
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Typography>{error}</Typography>
      {/* <Typography>Between 60% and 70%</Typography>
      {saleSentences60to70Match.map((sentence, index) => {
        return (
          <div>
            <Typography>{sentence}</Typography>
          </div>
        );
      })}
      <Typography>Between 70% and 80%</Typography>
      {saleSentences70to80Match.map((sentence, index) => {
        return (
          <div>
            <Typography>{sentence}</Typography>
          </div>
        );
      })}
      <Input
        id="saleScriptInput"
        type="text"
        placeholder="Enter a sale sentence"
      />
      <Button onClick={saveSaleSentence}>save sale sentence</Button> */}
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Button
            style={{
              border: '1px solid #E6E6E6',
              textTransform: 'unset',
              backgroundColor: '#E6E6E6',
              fontSize: '12px',
              margin: '5px',
              // fontFamily:"Elephant",
            }}
            component="label"
          >
            <input
              type="file"
              name="audioFile"
              id="audioFile"
              hidden
              accept=".wav"
              onChange={uploadAudioFile}
            />
            upload wav file
          </Button>
        </Grid>
        <Grid item>
          <Typography
            style={{
              margin: '5px',
              // fontFamily:"Elephant",
            }}
          >
            score {score}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} columns={10}>
          <Typography>{saleScriptSavedMessage}</Typography>
        </Grid>
        <Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    Sale script
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    Non sale script
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    Recommendations
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: '24px' }}>
                    {''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: 'green',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Text&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: 'green',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Type&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: 'green',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Emotion1&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: 'green',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Emotion2&nbsp;
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: 'red',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Text&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: 'red',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Type&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: 'red',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Emotion1&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: 'red',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Emotion2&nbsp;
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      backgroundColor: '#61dafb',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Text&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: '#61dafb',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Type&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: '#61dafb',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Emotion1&nbsp;
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: '#61dafb',
                      border: '2px solid #E6E6E6',
                    }}
                  >
                    Emotion2&nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notSaleScript.length > saleScript.length
                  ? notSaleScript.map((sentence, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 1 },
                        }}
                      >
                        <TableCell style={cellTextStyle}>
                          {saleScript[index] ? saleScript[index].text : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {saleScript[index]
                            ? saleScript[index].sentenceDirection
                            : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {saleScript[index] ? saleScript[index].emotion1 : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {saleScript[index] ? saleScript[index].emotion2 : ''}
                        </TableCell>
                        <TableCell
                          style={cellTextStyle}
                          component="th"
                          scope="row"
                        >
                          {sentence.text}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                          {sentence.sentenceDirection}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                          {sentence.emotion1}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                          {sentence.emotion2}
                        </TableCell>
                        <TableCell style={cellTextStyle}>
                          {recommendations[index]
                            ? recommendations[index].text
                            : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {recommendations[index]
                            ? recommendations[index].type
                            : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {recommendations[index]
                            ? recommendations[index].emotion1
                            : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {recommendations[index]
                            ? recommendations[index].emotion2
                            : ''}
                        </TableCell>
                      </TableRow>
                    ))
                  : saleScript.map((sentence, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 1 },
                        }}
                      >
                        <TableCell style={cellTextStyle}>
                          {sentence.text}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {sentence.sentenceDirection}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {sentence.emotion1}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {sentence.emotion2}
                        </TableCell>
                        <TableCell
                          style={cellTextStyle}
                          component="th"
                          scope="row"
                        >
                          {notSaleScript[index]
                            ? notSaleScript[index].text
                            : ''}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                          {notSaleScript[index]
                            ? notSaleScript[index].sentenceDirection
                            : ''}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                          {notSaleScript[index]
                            ? notSaleScript[index].emotion1
                            : ''}
                        </TableCell>
                        <TableCell align="center" style={cellStyle}>
                          {notSaleScript[index]
                            ? notSaleScript[index].emotion2
                            : ''}
                        </TableCell>
                        <TableCell style={cellTextStyle}>
                          {recommendations[index]
                            ? recommendations[index].text
                            : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {recommendations[index]
                            ? recommendations[index].type
                            : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {recommendations[index]
                            ? recommendations[index].emotion1
                            : ''}
                        </TableCell>
                        <TableCell style={cellStyle}>
                          {recommendations[index]
                            ? recommendations[index].emotion2
                            : ''}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>

      {/* <Button onClick={emotionsDbInit}>emotions db init</Button> */}
    </ThemeProvider>
  );
};

export default scriptsComparison;
