import React from "react";
import WordsSearch from "./../../components/wordSearch/index";
const Index = () => {
  return (
    <React.Fragment>
      <WordsSearch />
    </React.Fragment>
  );
};

export default Index;