import * as React from 'react';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';
// import InputBase from '@mui/material/InputBase';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';

import SaveHotelButton from '../buttons/saveHotelButton';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const versiones = ['v2'];

export default function SaveHotelDialog(props) {
  const { clientId, setHotelsList, hotelsList } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [disabledAddHotelButton, setDisabledAddHotelButton] =
    React.useState(true);
  React.useEffect(() => {
    if (!clientId) {
      setDisabledAddHotelButton(true);
    } else {
      setDisabledAddHotelButton(false);
    }
  }, [clientId]);

  const [hotelVersion, setHotelVersion] = React.useState('');
  const changeHotelVersion = function (e) {
    setHotelVersion(e.target.value);
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ textTransform: 'unset' }}
        size="small"
        disabled={disabledAddHotelButton}
      >
        Add Client Hotel
      </Button>

      <BootstrapDialog
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Add Hotel
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <FormControl sx={{ minWidth: '250px', mt: 2 }} variant="standard">
            <InputLabel shrink htmlFor="bootstrap-input">
              Select Version
            </InputLabel>
            <Select
              style={{ backgroundColor: '#CDCDCD' }}
              onChange={changeHotelVersion}
            >
              {versiones.map((ver, index) => {
                return (
                  <MenuItem value={ver} name={ver} key={index}>
                    {ver}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* <Input
            id="hotelVersion"
            dir="rtl"
            placeholder="Enter Hotel mobile App version"
            onChange={changeHotelVersion}
          /> */}
          <SaveHotelButton
            clientId={clientId}
            hotelVersion={hotelVersion}
            hotelsList={hotelsList}
            setHotelsList={setHotelsList}
            handleClose={handleClose}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
