import React, { useState, useEffect } from 'react';
import axios from 'axios';
import xlsx from 'xlsx';
import { useNavigate } from 'react-router-dom';
import logo from './../../assets/logo1.png';
import { types } from './../register/constants';
import {
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Button,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  AppBar,
  Toolbar,
  Slide,
} from '@mui/material';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import Highlighter from 'react-highlight-words';

import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor:
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'red',
    border: 'none',
    fontSize: 16,
    padding: '15px 12px',
    width: '100%',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Index = () => {
  const navigate = useNavigate();

  const [error, setError] = useState('');

  const [buttonIndex, setButtonIndex] = useState(-1);
  const [buttonDir, setButtonDir] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [positiveSubjectsList, setPositiveSubjectsList] = useState([]);
  const [negativeSubjectsList, setNegativeSubjectsList] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedDirection, setSelectedDirection] = useState('');

  const [positiveSubjectCount, setPositiveSubjectCount] = useState([]);
  const [negativeSubjectCount, setNegativeSubjectCount] = useState([]);

  const [subjectSentences, setSubjectSentences] = useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);

  const [positiveWords, setPositiveWords] = useState([]);
  const [negativeWords, setNegativeWords] = useState([]);

  const [feedbacks123stars, setfeedbacks123stars] = useState('');
  const [feedbacks345stars, setfeedbacks345stars] = useState('');

  const [openRemoveSubjectModal, setOpenRemoveSubjectModal] =
    React.useState(false);
  const handleClickOpenRemoveSubjectModal = () => {
    setOpenRemoveSubjectModal(true);
  };

  const handleCloseRemoveSubjectModal = () => {
    setOpenRemoveSubjectModal(false);
  };

  // search bar
  useEffect(() => {
    const results = subjectSentences.filter((sentence) =>
      sentence.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setSearchResults(results);
  }, [searchTerm, subjectSentences]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const positiveSubjectKeyDown = (e) => {
    if (e.key === 'Enter') {
      addPositiveSubject();
    }
  };

  const negativeSubjectKeyDown = (e) => {
    if (e.key === 'Enter') {
      addNegativeSubject();
    }
  };

  const addSentenceKeyDown = (e) => {
    if (e.key === 'Enter') {
      addSentence();
    }
  };

  const changeSelectType = async (e) => {
    const selectedType = e.target.value;
    setError('');

    const resPositive = await axios.get(
      process.env.REACT_APP_BASE_API_URL +
        '/subjectsEnglish/positive-subjects-by-type',
      { params: { type: selectedType } },
    );
    //console.log(resPositive.status)
    setPositiveSubjectsList([...resPositive.data]);

    const resNegative = await axios.get(
      process.env.REACT_APP_BASE_API_URL +
        '/subjectsEnglish/negative-subjects-by-type',
      { params: { type: selectedType } },
    );
    //console.log(resNegative.data)
    setNegativeSubjectsList([...resNegative.data]);
  };

  const addPositiveSubject = async () => {
    setError('');
    const type = document.getElementById('typeSelect').textContent;
    const subject = document
      .getElementById('positiveSubjectInput')
      .value.trim();

    if (type.length > 1 && subject.length > 0) {
      const res = await axios.post(
        process.env.REACT_APP_BASE_API_URL +
          '/subjectsEnglish/add-positive-subject',
        { name: subject, type },
      );
      if (res.status === 200) {
        positiveSubjectsList.push(subject);
        setPositiveSubjectsList([...positiveSubjectsList]);
        document.getElementById('positiveSubjectInput').value = '';
      }
    } else {
      setError('All fileds required');
    }
  };

  const addNegativeSubject = async () => {
    setError('');
    const type = document.getElementById('typeSelect').textContent;
    const subject = document
      .getElementById('negativeSubjectInput')
      .value.trim();

    if (type.length > 1 && subject.length > 0) {
      const res = await axios.post(
        process.env.REACT_APP_BASE_API_URL +
          '/subjectsEnglish/add-negative-subject',
        { name: subject, type },
      );
      if (res.status === 200) {
        negativeSubjectsList.push(subject);
        setNegativeSubjectsList([...negativeSubjectsList]);
        document.getElementById('negativeSubjectInput').value = '';
      }
    } else {
      setError('All fileds required');
    }
  };

  const deletePositiveSubject = async (btnIndex) => {
    setError('');
    setSelectedSubject('');
    setSelectedDirection('');

    handleClickOpenRemoveSubjectModal();
    setButtonIndex(btnIndex);
    setButtonDir('positive');
    // const subjectToDelete = document.getElementById("pos" + btnIndex).textContent;
    // const type = document.getElementById("typeSelect").textContent;
    // const index = positiveSubjectsList.indexOf(subjectToDelete);

    // if (index > -1) { // only splice array when item is found
    //     positiveSubjectsList.splice(index, 1); // 2nd parameter means remove one item only
    //     setPositiveSubjectsList([...positiveSubjectsList])
    //     const res = await axios.delete(process.env.REACT_APP_BASE_API_URL+"/subjectsEnglish/delete-positive-subject",{params:{name: subjectToDelete, type}});
    //     console.log(res.data);
    // }else{
    //     console.log("not found");
    // }
  };

  const deleteNegativeSubject = async (btnIndex) => {
    setError('');
    setSelectedSubject('');
    setSelectedDirection('');

    handleClickOpenRemoveSubjectModal();
    setButtonIndex(btnIndex);
    setButtonDir('negative');
    // const subjectToDelete = document.getElementById("neg" + btnIndex).textContent;
    // const type = document.getElementById("typeSelect").textContent;
    // const index = negativeSubjectsList.indexOf(subjectToDelete);

    // if (index > -1) { // only splice array when item is found
    //     negativeSubjectsList.splice(index, 1); // 2nd parameter means remove one item only
    //     setNegativeSubjectsList([...negativeSubjectsList])
    //     const res = await axios.delete(process.env.REACT_APP_BASE_API_URL+"/subjectsEnglish/delete-negative-subject",{params:{name: subjectToDelete, type}});
    // }else{
    //     console.log("not found");
    // }
  };

  const deleteSubject = (buttonIndex, buttonDir) => {
    setError('');
    handleCloseRemoveSubjectModal();
    let subjectToDelete = '';
    let type = '';
    let index = -1;

    if (buttonDir === 'negative') {
      subjectToDelete = document.getElementById(
        'neg' + buttonIndex,
      ).textContent;
      type = document.getElementById('typeSelect').textContent;
      index = negativeSubjectsList.indexOf(subjectToDelete);
      if (index > -1) {
        // only splice array when item is found
        negativeSubjectsList.splice(index, 1); // 2nd parameter means remove one item only
        setNegativeSubjectsList([...negativeSubjectsList]);

        // delete subject from db
        axios
          .delete(
            process.env.REACT_APP_BASE_API_URL +
              '/subjectsEnglish/delete-negative-subject',
            {
              params: {
                name: subjectToDelete,
                type,
              },
            },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log('not found');
      }
    } else {
      //positive
      subjectToDelete = document.getElementById(
        'pos' + buttonIndex,
      ).textContent;
      type = document.getElementById('typeSelect').textContent;
      index = positiveSubjectsList.indexOf(subjectToDelete);
      if (index > -1) {
        // only splice array when item is found
        positiveSubjectsList.splice(index, 1); // 2nd parameter means remove one item only
        setPositiveSubjectsList([...positiveSubjectsList]);

        // delete subject from db
        axios
          .delete(
            process.env.REACT_APP_BASE_API_URL +
              '/subjectsEnglish/delete-positive-subject',
            {
              params: {
                name: subjectToDelete,
                type,
              },
            },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log('not found');
      }
    }
  };

  const addSentence = async () => {
    setError('');
    const type = document.getElementById('typeSelect').textContent;
    const sentence = document.getElementById('sentenceInput').value.trim();

    if (type.length <= 1) {
      setError('select type');
      return;
    }
    if (!sentence) {
      setError('add sencense to the input');
      return;
    }
    if (sentence.indexOf(' ') === -1) {
      setError('sentence has only one word!');
      return;
    }
    if (!selectedSubject || !selectedDirection) {
      setError('select subject');
      return;
    }

    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + '/subjectsEnglish/add-sentence',
        {
          name: selectedSubject,
          type,
          direction: selectedDirection,
          sentence,
        },
      )
      .catch((e) => console.log(e));
    document.getElementById('sentenceInput').value = '';
  };

  const deleteSentenceFromSubject = async (index, selectedSubject) => {
    const sentenceToDelete = subjectSentences[index];
    subjectSentences.splice(index, 1);
    setSubjectSentences([...subjectSentences]);

    await axios.delete(
      process.env.REACT_APP_BASE_API_URL +
        '/subjectsEnglish/delete-sentence-from-subject',
      {
        params: {
          sentence: sentenceToDelete,
          subject: selectedSubject,
        },
      },
    );
  };

  const selectSubject = async (e, dir) => {
    setSelectedSubject(e.target.textContent);
    setSelectedDirection(dir);
    handleClickOpen();
    const res = await axios.get(
      process.env.REACT_APP_BASE_API_URL +
        '/subjectsEnglish/all-subject-sentences',
      { params: { subject: e.target.textContent, direction: dir } },
    );
    setSubjectSentences(res.data);
  };

  const readUploadFile = async (e) => {
    setError('');
    e.preventDefault();
    let type = document.getElementById('typeSelect').textContent;
    if (type.length <= 1) {
      type = 'General';
    }
    const feedbacks = await readFeedbacks(e);

    let feedbacks123stars = feedbacks.feedbacks123;
    let feedbacks345stars = feedbacks.feedbacks345;

    // const res1 = await axios.post(process.env.REACT_APP_BASE_API_URL+"/procceseFeedback/fix-negative-sentences",{feedbacks:feedbacks123stars})
    // feedbacks123stars = res1.data;

    // const res2 = await axios.post(process.env.REACT_APP_BASE_API_URL+"/procceseFeedback/fix-positive-sentences",{feedbacks:feedbacks345stars})
    // feedbacks345stars = res2.data;

    const res3 = await axios.post(
      process.env.REACT_APP_BASE_API_URL +
        '/procceseFeedback/proccese-negative-english-feedback',
      { feedbacks: feedbacks123stars, type },
    );
    feedbacks123stars = res3.data.feedback;
    setNegativeSubjectCount([...res3.data.arr]);

    const res4 = await axios.post(
      process.env.REACT_APP_BASE_API_URL +
        '/procceseFeedback/proccese-positive-english-feedback',
      { feedbacks: feedbacks345stars, type },
    );
    feedbacks345stars = res4.data.feedback;
    setPositiveSubjectCount([...res4.data.arr]);

    const positiveWordsToRemark = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/subjectsEnglish/sentences-words',
      {
        params: {
          type,
          direction: 'positive',
        },
      },
    );
    setPositiveWords(positiveWordsToRemark.data);

    const negativeWordsToRemark = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/subjectsEnglish/sentences-words',
      {
        params: {
          type,
          direction: 'negative',
        },
      },
    );
    setNegativeWords(negativeWordsToRemark.data);

    setfeedbacks123stars(feedbacks123stars);
    setfeedbacks345stars(feedbacks345stars);
  };

  const readFeedbacks = (e) => {
    return new Promise((resolve, reject) => {
      let feedbacks345stars = '';
      let feedbacks123stars = '';
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);

          for (const row of json) {
            if (row.__rowNum__ > 1) {
              const stars = row.__EMPTY_2;
              const feedback = row.__EMPTY_1;
              if (stars >= 3) {
                feedbacks345stars += ' ' + feedback;
              }
              if (stars <= 3) {
                feedbacks123stars += ' ' + feedback;
              }
            }
          }
          resolve({
            feedbacks123: feedbacks123stars,
            feedbacks345: feedbacks345stars,
          });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
      document.getElementById('upload').value = '';
    });
  };

  return (
    <React.Fragment>
      <Container>
        <Box
          textAlign="center"
          style={{ backgroundColor: 'black' }}
          item
          xs={2}
          onClick={() => navigate('/user/dashboard')}
        >
          <img src={logo} alt="" style={{ cursor: 'pointer' }} />
        </Box>
        <center>
          <Button onClick={() => navigate('/admin-options-main')}>
            Admin Main Menu
          </Button>
          <Typography style={{ color: 'red' }}>{error}</Typography>
        </center>
        <Box container spacing={3} marginTop="5%" margin="50px">
          <Grid xs={5}>
            <FormControl sx={{ minWidth: '100%', mt: 2 }} variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                Type*
              </InputLabel>
              <Select
                id="typeSelect"
                style={{ width: '300px' }}
                onChange={changeSelectType}
                input={<BootstrapInput />}
              >
                {types.map((type) => (
                  <MenuItem value={type} name={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <div>
            <Dialog
              open={openRemoveSubjectModal}
              onClose={handleCloseRemoveSubjectModal}
              buttonIndex={buttonIndex}
              buttonDir={buttonDir}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Remove Jbject'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You about to remove a subject are you sure you want to remove
                  this subject?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteSubject(buttonIndex, buttonDir)}>
                  Remove
                </Button>
                <Button onClick={handleCloseRemoveSubjectModal} autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Grid marginTop="50px" container spacing={2} direction="row" xs={12}>
            <Grid style={{ border: '1px solid #5FEFC0' }} xs={5}>
              <h2>
                <center>Positive subjects</center>
              </h2>
              {positiveSubjectsList.map((subject, index) => {
                return (
                  <div>
                    <IconButton
                      onClick={() => deletePositiveSubject(index)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Button
                      id={'pos' + index}
                      value={subject}
                      name={subject}
                      onClick={(e) => selectSubject(e, 'positive')}
                      style={{ textTransform: 'unset' }}
                    >
                      {subject}
                    </Button>
                  </div>
                );
              })}
              <Input
                style={{ backgroundColor: '#BDDAFF' }}
                dir="rtl"
                id="positiveSubjectInput"
                onKeyDown={positiveSubjectKeyDown}
              ></Input>
              <Button
                style={{ textTransform: 'unset', margin: '4px' }}
                size="small"
                variant="outlined"
                onClick={addPositiveSubject}
              >
                Add Posivite Subject
              </Button>
            </Grid>
            <Grid style={{ border: '1px solid #5FEFC0' }} xs={5}>
              <h2>
                <center>Negative subjects</center>
              </h2>
              {negativeSubjectsList.map((subject, index) => {
                return (
                  <div>
                    <IconButton
                      onClick={() => deleteNegativeSubject(index)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Button
                      id={'neg' + index}
                      value={subject}
                      name={subject}
                      onClick={(e) => selectSubject(e, 'negative')}
                      style={{ textTransform: 'unset' }}
                    >
                      {subject}
                    </Button>
                  </div>
                );
              })}
              <Input
                style={{ backgroundColor: '#BDDAFF' }}
                dir="rtl"
                id="negativeSubjectInput"
                onKeyDown={negativeSubjectKeyDown}
              ></Input>
              <Button
                style={{ textTransform: 'unset', margin: '4px' }}
                size="small"
                variant="outlined"
                onClick={addNegativeSubject}
              >
                Add Negative Subject
              </Button>
            </Grid>
          </Grid>
          <Grid textAlign="right">
            <h3>
              {selectedSubject} {selectedDirection}
            </h3>
            <Input
              id="sentenceInput"
              dir="rtl"
              style={{ backgroundColor: '#BDDAFF' }}
              onKeyDown={addSentenceKeyDown}
            ></Input>
            <Button
              style={{ textTransform: 'unset' }}
              size="small"
              variant="outlined"
              onClick={addSentence}
            >
              Add Sentence
            </Button>
          </Grid>
        </Box>
        <Grid container spacing={2} xs={10} display="flex">
          <Grid xs={5}>
            <Typography>
              <center>
                <h4>Positive found subjects</h4>
              </center>
            </Typography>
            <Grid textAlign="center" style={{ border: '1px solid #5FEFC0' }}>
              {positiveSubjectCount.map((subject) => {
                return (
                  <Typography paddingRight="4px">
                    {subject.count} {subject.subject} {subject.type}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
          <Grid xs={5}>
            <Typography>
              <center>
                <h4>Negative found subjects</h4>
              </center>
            </Typography>
            <Grid textAlign="center" style={{ border: '1px solid #5FEFC0' }}>
              {negativeSubjectCount.map((subject) => {
                return (
                  <Typography paddingRight="4px">
                    {subject.count} {subject.subject} {subject.type}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ textAlign: 'right', marginTop: '2.5%' }}>
          <Button
            variant="contained"
            style={{ textTransform: 'unset' }}
            component="label"
          >
            <input
              type="file"
              name="upload"
              id="upload"
              hidden
              onChange={readUploadFile}
            />
            Upload File
          </Button>
        </Grid>
        <Box marginTop="2%" marginBottom="2%">
          <Grid
            style={{ border: '1px solid #5FEFC0', wordWrap: 'break-word' }}
            marginBottom="10px"
          >
            <Typography>
              <center>
                <h4>Positive feedbacks</h4>
              </center>
            </Typography>
            {feedbacks345stars}
            <Highlighter
              searchWords={positiveWords}
              autoEscape={true}
              textToHighlight={feedbacks345stars}
            />
          </Grid>
          <Grid style={{ border: '1px solid #5FEFC0', wordWrap: 'break-word' }}>
            <Typography>
              <center>
                <h4>Negative feedbacks</h4>
              </center>
            </Typography>
            {feedbacks123stars}
            <Highlighter
              searchWords={negativeWords}
              autoEscape={true}
              textToHighlight={feedbacks123stars}
            />
          </Grid>
        </Box>
        <Dialog
          // fullScreen
          scroll="paper"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {selectedSubject} {selectedDirection}
              </Typography>
            </Toolbar>
            <Input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleChange}
            />
          </AppBar>
          <List>
            {searchResults.map((sentence, index) => {
              return (
                <ListItem>
                  <ListItemText primary={sentence} />
                  <IconButton
                    aria-label="delete"
                    onClick={() =>
                      deleteSentenceFromSubject(index, selectedSubject)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        </Dialog>
      </Container>
    </React.Fragment>
  );
};

export default Index;
