import React from 'react';
import WordClassSelect from './WordClassSelect';
import { useState } from 'react';
import { Button } from '@mui/material';
import { TextField } from '@material-ui/core';

const axios = require('axios');

const Index = () => {
  const [word1, setWord1] = useState('');
  const [word2, setWord2] = useState('');
  const [word3, setWord3] = useState('');
  const [word4, setWord4] = useState('');
  const [word5, setWord5] = useState('');
  const [word6, setWord6] = useState('');

  const handleClick = () => {
    const arr = [word1, word2, word3, word4, word5, word6];
    document.getElementById('textField').value = arr.join(' ');
  };

  const getJobId = async () => {
    // const form = new FormData();
    // form.append('json', '{\n    "models": {\n      "face": {}\n    }\n  }');
    // form.append('file', fs.readFileSync('faces.zip'), 'faces.zip');

    const jobIdOptions = {
      method: 'POST',
      url: 'https://api.hume.ai/v0/batch/jobs',
      headers: {
        accept: 'application/json; charset=utf-8',
        'content-type': 'application/json; charset=utf-8',
        'X-Hume-Api-Key': 'qsGAy2nRKVQVtX8nERAzYnmY2SDEkH7fxhyuquqYTZ3hTcbH',
      },
      data: `{
              "urls": [  
                  "https://iep.utm.edu/wp-content/media/hume-bust.jpg"  
              ],
              "models":{"face":{"fps_pred":3,"prob_threshold":0.99,"identify_faces":false,"min_face_size":60,"save_faces":false},"prosody":{"granularity":"utterance","identify_speakers":false,"window":{"length":4,"step":1}},"language":{"granularity":"word","identify_speakers":false},"ner":{"identify_speakers":false}},"transcription":{"language":null},"notify":false
            }`,
    };
    const job_id = await axios.request(jobIdOptions).catch(function (error) {
      console.log(error);
    });
    // console.log(job_id.data.job_id);
    // setJobId(job_id.data.job_id);

    return job_id.data.job_id;
  };

  const getJobStatus = async (job_id) => {
    const jobStatusOptions = {
      method: 'GET',
      url: `https://api.hume.ai/v0/batch/jobs/${job_id}`,
      headers: {
        accept: 'application/json; charset=utf-8',
        //   'content-type': 'application/json; charset=utf-8',
        'X-Hume-Api-Key': 'qsGAy2nRKVQVtX8nERAzYnmY2SDEkH7fxhyuquqYTZ3hTcbH',
      },
    };

    const job_status = await axios
      .request(jobStatusOptions)
      .catch(function (error) {
        console.log(error);
      });
    // console.log(job_status.data.state.status);
    return job_status.data.state.status;
  };

  const getPredidtion = async (job_id) => {
    const predictionOptions = {
      method: 'GET',
      url: `https://api.hume.ai/v0/batch/jobs/${job_id}/predictions`,
      headers: {
        'X-Hume-Api-Key': 'qsGAy2nRKVQVtX8nERAzYnmY2SDEkH7fxhyuquqYTZ3hTcbH',
        accept: 'application/json; charset=utf-8',
        //   'content-type': 'application/json; charset=utf-8',
      },
    };

    const predictions = await axios
      .request(predictionOptions)
      .catch(function (error) {
        console.log(error);
      });
    console.log(predictions.data);
    // return (predictions.data[0].results.predictions[0].models.face.grouped_predictions[0].predictions[0].emotions);
  };

  const humeTest = async () => {
    const jobId = await getJobId();
    console.log(jobId);
    let jobStatus = await getJobStatus(jobId);
    while (jobStatus !== 'COMPLETED' && jobStatus !== 'FAILED') {
      sleep(1000);
      jobStatus = await getJobStatus(jobId);
      console.log(jobStatus);
    }

    const predictions = await getPredidtion(jobId);
    console.log(predictions);

    // createWebSocket();
  };

  // const createWebSocket = () => {
  //     // Create a new WebSocket connection
  //     const socket = new WebSocket('wss://api.hume.ai/v0/stream/models?apikey=qsGAy2nRKVQVtX8nERAzYnmY2SDEkH7fxhyuquqYTZ3hTcbH');

  //     socket.addEventListener('open', event => {

  //         // Create a JSON object to send
  //         const message = {
  //             "models": {
  //                 "language": {}
  //             },
  //             "raw_text": true,
  //             "data": "Mary had a little lamb"
  //         };

  //         // Convert the JSON object to a string
  //         const jsonString = JSON.stringify(message);

  //         // Send the JSON message over the socket
  //         socket.send(jsonString);

  //         console.log('Connected to the server');
  //     });

  //     // Listen for messages from the server
  //     socket.addEventListener('message', event => {
  //     const data = JSON.parse(event.data);
  //         console.log('Received data:', data);
  //     // Do something with the received data
  //     });

  //     // Connection closed
  //     socket.addEventListener('close', event => {
  //         console.log('Disconnected from the server');
  //     });

  //     // Handle errors
  //     socket.addEventListener('error', event => {
  //         console.error('WebSocket error:', event);
  //     });
  // }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const humeTest1 = async () => {
    //    const jobIdData = await axios.get(process.env.REACT_APP_BASE_API_URL+"/speechToText/get-job-id");
    //    const jobId = jobIdData.data.job_id;
    //    console.log(jobId);
    //    let jobStatus = await getJobStatus(jobId);
    //     while (jobStatus !== "COMPLETED" && jobStatus !== "FAILED"){
    //         sleep(1000);
    //         jobStatus = await getJobStatus(jobId);
    //         console.log(jobStatus)
    //     }
    //     const predictions = await getPredidtion(jobId)
    //     console.log(predictions);
  };

  return (
    <React.Fragment>
      <WordClassSelect selectNumber="1" setWord1={setWord1} />
      <WordClassSelect selectNumber="2" setWord2={setWord2} />
      <WordClassSelect selectNumber="3" setWord3={setWord3} />
      <WordClassSelect selectNumber="4" setWord4={setWord4} />
      <WordClassSelect selectNumber="5" setWord5={setWord5} />
      <WordClassSelect selectNumber="6" setWord6={setWord6} />
      <Button onClick={handleClick}>create sentence</Button>
      <Button onClick={humeTest}>test</Button>
      <Button onClick={humeTest1}>test1</Button>
      <TextField
        id="textField"
        variant="outlined"
        placeholder="random sentence"
        // disabled={true}
        multiline
        // minRows={10}
        // maxRows={10}

        // onChange={textFieldChange}
        style={{
          width: 360,
          overflow: 'auto',
        }}
      />
    </React.Fragment>
  );
};

export default Index;
