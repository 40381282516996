import React from 'react';
// import CssBaseline from "@mui/material/CssBaseline";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import Sheet from '@mui/joy/Sheet';
import { Modal, TextField, Grid, Button, Typography, Box } from '@mui/material';

import axios from 'axios';

// const theme = createTheme();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  justifyContent: 'center',
  alignItems: 'center',
};

export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addNewClient = async function () {
    const clientName = document.getElementById('clientName').value;
    const clientEmail = document.getElementById('clientEmail').value;
    const savedClient = await axios
      .post(
        process.env.REACT_APP_BASE_API_URL +
          '/emotions/save-new-emotions-client',
        { clientName, clientEmail },
      )
      .catch((error) => {
        // TODO set error client not saved
      });
    console.log(savedClient);
  };

  return (
    <Grid>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Button
          sx={{ my: 2, color: 'white', display: 'block' }}
          onClick={handleOpen}
        >
          Register
        </Button>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Sheet
          variant="outlined"
          sx={{
            p: 3,
            bgcolor: '#F5F5F5',
            textAlign: 'center',
          }}
        >
          <Box sx={style}>
            <Typography variant="h6" component="h2">
              Enter new client details
            </Typography>
            <TextField
              id="clientName"
              type="text"
              size="small"
              label="Client Name"
              maxWidth="300px"
              sx={{ m: 2 }}
            />
            <TextField
              id="clientEmail"
              type="email"
              size="small"
              label="Client Email"
              maxWidth="300px"
              sx={{ m: 2 }}
            />
            <Grid container direction="row-reverse">
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                sx={{ cursor: 'pointer', m: 1 }}
                variant="outlined"
                onClick={addNewClient}
              >
                Save
              </Button>
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                variant="outlined"
                sx={{ cursor: 'pointer', m: 1 }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Box>
        </Sheet>
      </Modal>
    </Grid>
  );
}
