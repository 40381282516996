import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import CloseIcon from '@mui/icons-material/Close';

import { Input } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SaveSentenceDialog(props) {
  const { subject } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addSentece = function () {
    const sentence = document.getElementById('sentence').value;

    if (sentence) {
      axios.post(process.env.REACT_APP_BASE_API_URL + '/hotels/add-sentence', {
        sentence,
        subjectId: subject._id,
      });
      subject.sentences.push(sentence);
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} aria-label="delete">
        <PlaylistAddIcon />
      </IconButton>

      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Add sentence to subject
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent>
          <Input id="sentence" dir="rtl" placeholder="Enter sentence" />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={addSentece}>
            Save
          </Button>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
