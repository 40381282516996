import React from "react";
import Analytics from "./../../components/historyAnalytics/index";
const Index = () => {
  return (
    <React.Fragment>
      <Analytics />
    </React.Fragment>
  );
};

export default Index;
