import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

import { Input, List, ListItem, ListItemText } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function StoreEmailsContactsDialog(props) {
  const { storeId } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addContact = async function () {
    const email = document.getElementById('email').value.trim();
    document.getElementById('email').value = null;

    if (!email) {
      return;
    }

    if (!storeId) {
      console.log('no storeId');
      return;
    }

    axios
      .post(process.env.REACT_APP_BASE_API_URL + '/stores/add-email-to-store', {
        email,
        storeId,
      })
      .catch((e) => console.log(e));

    console.log(email);
    emailsList.push(email);
    setEmailsList([...emailsList]);
  };

  const deleteContact = function (email, index) {
    axios.delete(
      process.env.REACT_APP_BASE_API_URL + '/stores/delete-email-store',
      {
        params: {
          storeId,
          index,
        },
      },
    );
    emailsList.splice(index, 1);
    setEmailsList([...emailsList]);
  };

  const [emailsList, setEmailsList] = React.useState([]);
  React.useEffect(() => {
    const getEmailsList = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/stores/get-store-emails', {
          params: {
            storeId,
          },
        })
        .catch((e) => console.log(e));
      if (res) {
        setEmailsList(res.data);
      }
    };
    if (storeId) {
      getEmailsList();
    }
  }, [storeId]);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          backgroundColor: 'black',
          color: 'white',
          textTransform: 'unset',
          border: '1px solid black',
          marginTop: '10px',
          // padding: "1px",
          //   minWidth: '175px',
          //   minHeight: '35px',
        }}
      >
        General Daily Data
      </Button>
      <BootstrapDialog
        // fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Emails Contacts
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <List>
            {emailsList.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item} />
                <IconButton
                  onClick={() => deleteContact(item, index)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
          <Input id="email" placeholder="Enter Emails" />
          <Tooltip title="add email">
            <IconButton onClick={addContact}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
