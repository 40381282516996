import React, { useState, useEffect } from 'react';
import { Grid, Input, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

import RemoveSubjectEnDialog from './RemoveSubjectEnDialog';
import SubjectSentencesEnDialog from './SubjectSentencesEnDialog';

function PosNegTablesEn(props) {
  const {
    selectedType,
    // selectedSubject,
    // setSelectedSubject,
    // selectedSubjectSentences,
    // setSelectedSubjectSentences,
  } = props;
  const [openDeleteSubjectDialog, setOpenDeleteSubjectDialog] =
    React.useState(false);
  const closeDeleteSubjectDialog = () => setOpenDeleteSubjectDialog(false);

  const [openSubjectSentencesDialog, setOpenSubjectSentencesDialog] =
    React.useState(false);
  const closeSubjectSentencesDialog = () =>
    setOpenSubjectSentencesDialog(false);

  const [subjectToRemove, setSubjectToRemove] = useState('');
  const [selectedSubject, setSelectedSubject] = useState(null);

  const [positiveSubjectsList, setPositiveSubjectsList] = useState([]);
  const [negativeSubjectsList, setNegativeSubjectsList] = useState([]);

  const [error, setError] = useState('');

  useEffect(() => {
    setError('');
    setSelectedSubject(null);

    if (selectedType) {
      const getSubjectsData = async (sentiment) => {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            '/subjects-en/subjects-en-by-sentiment',
          {
            params: {
              type: selectedType,
              sentiment,
            },
          },
        );
        if (sentiment === 'positive') {
          setPositiveSubjectsList([...response.data]);
        } else {
          setNegativeSubjectsList([...response.data]);
        }
      };
      getSubjectsData('positive');
      getSubjectsData('negative');
    }
  }, [selectedType]);

  const positiveSubjectKeyDown = (e) => {
    if (e.key === 'Enter') {
      addSubject('positive');
    }
  };
  const negativeSubjectKeyDown = (e) => {
    if (e.key === 'Enter') {
      addSubject('negative');
    }
  };

  const addSubject = async function (sentiment) {
    setError('');
    let name = '';
    if (sentiment === 'positive') {
      name = document.getElementById('positiveSubjectInput').value.trim();
    } else {
      name = document.getElementById('negativeSubjectInput').value.trim();
    }
    if (!name) return;
    if (selectedType.length > 1) {
      const savedSubject = await axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/subjects-en/save-subject-en',
          {
            name,
            type: selectedType,
            sentiment,
          },
        )
        .catch((error) => {
          const { message } = error.response.data;
          console.log(message);
          //setError(message);
        });

      if (sentiment === 'positive') {
        positiveSubjectsList.push(savedSubject.data);
        setPositiveSubjectsList([...positiveSubjectsList]);
      } else {
        negativeSubjectsList.push(savedSubject.data);
        setNegativeSubjectsList([...negativeSubjectsList]);
      }
    } else {
      setError('Select Type');
    }

    document.getElementById('positiveSubjectInput').value = null;
    document.getElementById('negativeSubjectInput').value = null;
  };

  const deleteSubject = (subject) => {
    setSubjectToRemove(subject);
    setOpenDeleteSubjectDialog(true);
  };

  const selectSubject = async (subject) => {
    setSelectedSubject(subject);
    setOpenSubjectSentencesDialog(true);
  };

  return (
    <Grid marginLeft="5%">
      <div style={{ padding: '20px' }}>
        <h1 style={{ color: 'red' }}>{error}</h1>
      </div>
      <Grid
        //marginTop="50px"
        container
        minWidth="100%"
        //spacing={2}
        direction="row"
        xs={12}
      >
        <Grid minWidth="50%" style={{ border: '1px solid #5FEFC0' }} xs={5}>
          <h2>
            <center>Positive subjects</center>
          </h2>
          {positiveSubjectsList.map((subject) => {
            return (
              <div>
                <IconButton
                  onClick={() => deleteSubject(subject)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
                <Button
                  // id={'pos' + index}
                  key={subject._id}
                  value={subject._id}
                  name={subject.name}
                  onClick={() => selectSubject(subject)}
                  style={{ textTransform: 'unset' }}
                >
                  {subject.name}
                </Button>
              </div>
            );
          })}
          <center>
            <Input
              style={{ backgroundColor: '#BDDAFF' }}
              id="positiveSubjectInput"
              onKeyDown={positiveSubjectKeyDown}
            ></Input>

            <Button
              style={{ textTransform: 'unset', margin: '4px' }}
              size="small"
              variant="outlined"
              onClick={() => addSubject('positive')}
            >
              Add Posivite Subject
            </Button>
          </center>
        </Grid>
        <Grid minWidth="50%" style={{ border: '1px solid #5FEFC0' }} xs={5}>
          <h2>
            <center>Negative subjects</center>
          </h2>
          {negativeSubjectsList.map((subject) => {
            return (
              <div>
                <IconButton
                  onClick={() => deleteSubject(subject)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
                <Button
                  key={subject._id}
                  value={subject._id}
                  name={subject.name}
                  onClick={() => selectSubject(subject)}
                  style={{ textTransform: 'unset' }}
                >
                  {subject.name}
                </Button>
              </div>
            );
          })}
          <center>
            <Input
              style={{ backgroundColor: '#BDDAFF' }}
              id="negativeSubjectInput"
              onKeyDown={negativeSubjectKeyDown}
            ></Input>

            <Button
              style={{ textTransform: 'unset', margin: '4px' }}
              size="small"
              variant="outlined"
              onClick={() => addSubject('negative')}
            >
              Add Negative Subject
            </Button>
          </center>
        </Grid>
        <RemoveSubjectEnDialog
          openDeleteSubjectDialog={openDeleteSubjectDialog}
          closeDeleteSubjectDialog={closeDeleteSubjectDialog}
          subjectToRemove={subjectToRemove}
          positiveSubjectsList={positiveSubjectsList}
          setPositiveSubjectsList={setPositiveSubjectsList}
          negativeSubjectsList={negativeSubjectsList}
          setNegativeSubjectsList={setNegativeSubjectsList}
        />
        <SubjectSentencesEnDialog
          openSubjectSentencesDialog={openSubjectSentencesDialog}
          closeSubjectSentencesDialog={closeSubjectSentencesDialog}
          selectedSubject={selectedSubject}
        />
      </Grid>
    </Grid>
  );
}

export default PosNegTablesEn;
