import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);
export const options = {
  // responsive: true,
  // labels: {
  //   offsetY: -18,
  // },
  // plugins: {
  //   legend: {
  //     position: "top",
  //     display: false,
  //   },
  //   title: {
  //     display: false,
  //     text: "Analytics",
  //   },
  // },
  // legend: {
  //   labels: {
  //     fontColor: "blue",
  //     fontSize: 18,
  //   },
  // },
  // scales: {
  //   x: {
  //     grid: {
  //       offset: true,
  //     },
  //   },
  //   yAxes: [
  //     {
  //       id: "A",
  //       type: "linear",
  //       position: "left",
  //     },
  //     {
  //       id: "B",
  //       type: "linear",
  //       position: "right",
  //       ticks: {
  //         max: 50,
  //         min: 0,
  //       },
  //     },
  //   ],
  // },
};
const labels = ['Question 1', 'Question 2', 'Question 3', 'Question 4'];
const Index = (props) => {
  const {
    q1Positive,
    q2Positive,
    q3Positive,
    q4Positive,
    q1Negative,
    q2Negative,
    q3Negative,
    q4Negative,
  } = props;

  const getData = () => {
    const q1TotalPositive = totalSubjectsCount(q1Positive);
    const q2TotalPositive = totalSubjectsCount(q2Positive);
    const q3TotalPositive = totalSubjectsCount(q3Positive);
    const q4TotalPositive = totalSubjectsCount(q4Positive);
    const q1TotalNegative = totalSubjectsCount(q1Negative);
    const q2TotalNegative = totalSubjectsCount(q2Negative);
    const q3TotalNegative = totalSubjectsCount(q3Negative);
    const q4TotalNegative = totalSubjectsCount(q4Negative);
    return {
      labels,
      width: 1,
      datasets: [
        {
          label: 'Positive',
          data: [
            q1TotalPositive,
            q2TotalPositive,
            q3TotalPositive,
            q4TotalPositive,
          ],
          backgroundColor: '#7df2cc',
          barThickness: '10',
          borderRadius: '5',
          barPercentage: 1.0,
          categoryPercentage: 1.0,
        },
        {
          label: 'Negative',
          data: [
            q1TotalNegative,
            q2TotalNegative,
            q3TotalNegative,
            q4TotalNegative,
          ],
          backgroundColor: '#ff9c90',
          barThickness: '10',
          borderRadius: '5',
          barPercentage: 1.0,
          categoryPercentage: 1.0,
        },
      ],
    };
  };

  const totalSubjectsCount = (subjectCounts) => {
    let count = 0;
    for (const subjectCount of subjectCounts) {
      count += subjectCount.count;
    }
    return count;
  };

  // if (
  //   q1Positive.length < 1 &&
  //   q2Positive.length < 1 &&
  //   q3Positive.length < 1 &&
  //   q4Positive.length < 1 &&
  //   q1Negative.length < 1 &&
  //   q2Negative.length < 1 &&
  //   q3Negative.length < 1 &&
  //   q4Negative.length < 1
  // ) {
  //   const data = getData();
  //   return (
  //     <React.Fragment>
  //       <Box>
  //         <Bar options={options} data={data} height="100px" />
  //       </Box>
  //     </React.Fragment>
  //   );
  // }

  const data = getData();

  return (
    <React.Fragment>
      <Box>
        <Bar options={options} data={data} height="100px" />
      </Box>
    </React.Fragment>
  );
};

export default Index;
