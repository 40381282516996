import React from "react";
import StoreQrCodeCompotenet from "../../components/storeQrCode";

const StoreQrCode = () => {
  return (
    <React.Fragment>
      <StoreQrCodeCompotenet />
    </React.Fragment>
  );
};

export default StoreQrCode;