import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Container,
  Input,
  Button,
  IconButton,
  Dialog,
  ListItemText,
  ListItem,
  List,
  AppBar,
  Toolbar,
  Slide,
} from '@mui/material';
import axios from 'axios';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SubjectSentencesEnDialog(props) {
  const {
    selectedSubject,
    openSubjectSentencesDialog,
    closeSubjectSentencesDialog,
  } = props;
  const [subjectSentences, setSubjectSentences] = useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const [error, setError] = React.useState('');

  useEffect(() => {
    setError('');
    const getSubjectSentences = async function (subjectId) {
      const sentences = await axios.get(
        process.env.REACT_APP_BASE_API_URL +
          '/subjects-en/subject-en-all-sentences',
        { params: { subjectId } },
      );
      setSubjectSentences(sentences.data);
    };
    if (selectedSubject) {
      getSubjectSentences(selectedSubject._id);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (subjectSentences) {
      const results = subjectSentences.filter((sentence) =>
        sentence.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setSearchResults(results);
    }
  }, [searchTerm, subjectSentences]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const deleteSentence = async (sentence, index) => {
    setError('');
    searchResults.splice(index, 1);
    setSearchResults([...searchResults]);

    selectedSubject.sentences.splice(index, 1);

    await axios.delete(
      process.env.REACT_APP_BASE_API_URL +
        '/subjects-en/delete-subject-en-sentence',
      { params: { subject: selectedSubject, sentence } },
    );
  };

  const addSentence = async () => {
    setError('');
    const sentence = document.getElementById('sentenceInput').value.trim();
    if (!sentence) return;
    document.getElementById('sentenceInput').value = '';

    if (selectedSubject && !selectedSubject.sentences.includes(sentence)) {
      selectedSubject.sentences.push(sentence);
      setSearchResults(selectedSubject.sentences);

      await axios.post(
        process.env.REACT_APP_BASE_API_URL +
          '/subjects-en/save-sentence-to-subject',
        { subject: selectedSubject },
      );
    } else {
      setError('Sentence Exists');
    }
  };
  const inputChange = () => {
    setError('');
  };
  const addSentenceKeyDown = (e) => {
    if (e.key === 'Enter') {
      addSentence();
    }
  };
  return (
    <React.Fragment>
      <Container>
        <Dialog
          // fullScreen
          scroll="paper"
          open={openSubjectSentencesDialog}
          onClose={closeSubjectSentencesDialog}
          TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={closeSubjectSentencesDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <h3 style={{ color: 'red' }}>{error}</h3>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                subject: {!selectedSubject ? '' : selectedSubject.name}
              </Typography>
            </Toolbar>
            <Input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleChange}
            />
          </AppBar>
          <List>
            {searchResults.map((sentence, index) => {
              return (
                <ListItem key={index}>
                  <ListItemText primary={sentence} />
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteSentence(sentence, index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
          <Grid textAlign="right">
            {/* <h3>Subject: {!selectedSubject ? '' : selectedSubject.name}</h3> */}
            <Input
              id="sentenceInput"
              style={{ backgroundColor: '#BDDAFF' }}
              onChange={inputChange}
              onKeyDown={addSentenceKeyDown}
            ></Input>
            <Button
              style={{ textTransform: 'unset' }}
              size="small"
              variant="outlined"
              onClick={addSentence}
            >
              Add Sentence
            </Button>
          </Grid>
        </Dialog>
      </Container>
    </React.Fragment>
  );
}

export default SubjectSentencesEnDialog;
