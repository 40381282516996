import React, {useState} from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

import {Input, Grid, Button, List, ListItem, ListItemText} from '@mui/material';

function SentencesDialog(props) {
  const {subject} = props;
  const [query, setQuery] = useState('');
  // Initialized with a few items
  const [items, setItems] = useState(subject.sentences);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleDelete = (sentence) => {
    axios.delete(
      process.env.REACT_APP_BASE_API_URL + '/hotels/delete-sentence',
      {params: {subjectId: subject._id, sentence}}
    );
    setItems(items.filter((item) => item !== sentence));
  };

  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(query.toLowerCase())
  );

  return (
    <React.Fragment>
      <Button
        id={subject._id}
        value={subject._id}
        name={subject.name}
        onClick={handleClickOpen}
        style={{textTransform: 'unset'}}
      >
        {subject.name}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Search Sentence
          <IconButton>
            <CloseIcon onClick={handleClose}></CloseIcon>
          </IconButton>
        </DialogTitle>

        <Grid style={{padding: '20px'}}>
          <Input
            type="text"
            placeholder="Search..."
            value={query}
            onChange={handleChange}
          />
          <List>
            {filteredItems.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item} />
                <IconButton
                  onClick={() => handleDelete(item)}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
}

export default SentencesDialog;
