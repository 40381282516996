import React, { useState } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import logo from './../../assets/logo1.png';
import { types } from './../register/constants';

import PosNegTables from '../adminPosNegTables/PosNegTables';

import AllNotFoundSentences from './AllNotFoundSentences';
import SentencesPrefix from './sentencesPrefix';

import {
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Button,
} from '@mui/material';

const Index = () => {
  const navigate = useNavigate();

  const [selectedType, setSelectedType] = useState('');
  const [selectedQuestionNuber, setSelectedQuestionNuber] = useState();

  const [error, setError] = useState('');

  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedDirection, setSelectedDirection] = useState('');

  // const [storeId, setStoreId] = useState('');
  // const [questions, setQuestions] = useState([]);

  // useEffect(() => {
  //   const getStoreQuestions = async function () {
  //     const storeQuestions = await axios.get(
  //       process.env.REACT_APP_BASE_API_URL + '/stores/get-store-questions',
  //       { params: { storeId } },
  //     );
  //     setQuestions(storeQuestions.data);
  //   };
  //   if (storeId) {
  //     getStoreQuestions();
  //   }
  // }, [storeId]);

  const addSentenceKeyDown = (e) => {
    if (e.key === 'Enter') {
      addSentence();
    }
  };

  const changeSelectType = async (e) => {
    const selectedType = e.target.value;
    setSelectedType(selectedType);
    setError('');
  };

  const addSentence = async () => {
    const sentence = document.getElementById('sentenceInput').value.trim();

    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + '/italianSubjects/add-sentence',
        {
          name: selectedSubject,
          type: selectedType,
          questionNumber: selectedQuestionNuber,
          direction: selectedDirection,
          sentence,
        },
      )
      .then((res) => {
        const { message } = res.data;
        setError(message);
      })
      .catch((error) => {
        const { message } = error.response.data;
        setError(message);
      });
    document.getElementById('sentenceInput').value = '';
  };

  return (
    <React.Fragment>
      <Container>
        <Box
          textAlign="center"
          style={{ backgroundColor: 'black' }}
          item
          xs={2}
          onClick={() => navigate('/user/dashboard')}
        >
          <img src={logo} alt="" style={{ cursor: 'pointer' }} />
        </Box>
        <center>
          <h2>עברית</h2>
          <Button onClick={() => navigate('/admin-options-main')}>
            Admin Main Menu
          </Button>
        </center>
        <center>
          <Typography style={{ color: 'red' }}>{error}</Typography>
        </center>
        <SentencesPrefix language="Hebrew" />
        <Grid xs={4} padding="10px">
          <Box maxWidth="300px">
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                id="typeSelect"
                onChange={changeSelectType}
                defaultValue=""
              >
                {types.map((type) => (
                  <MenuItem value={type} name={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <AllNotFoundSentences storeVersion={1} />

        <Grid container direction="row" spacing={0} textAlign="right" xs={12}>
          <PosNegTables
            questionNumber="1"
            // question={questions[0]}
            selectedType={selectedType}
            setSelectedQuestionNuber={setSelectedQuestionNuber}
            setSelectedSubject={setSelectedSubject}
            setSelectedDirection={setSelectedDirection}
          />
        </Grid>
        <Grid>
          <h3>
            {' '}
            table:{selectedQuestionNuber} {selectedType} {selectedSubject}{' '}
            {selectedDirection}
          </h3>
          <Input
            id="sentenceInput"
            dir="rtl"
            style={{ backgroundColor: '#BDDAFF' }}
            onKeyDown={addSentenceKeyDown}
          ></Input>
          <Button
            style={{ textTransform: 'unset' }}
            size="small"
            variant="outlined"
            onClick={addSentence}
          >
            Add Sentence
          </Button>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;
