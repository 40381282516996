import React from 'react';
import { Container, Paper } from '@mui/material';

//import Paper from 'material-ui/Paper';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
  backgroundColor: '#ffffff',
};

// const mainText = 'Main Text';
// const thankYouText = 'Thank you text';
// const recordingInstructions = 'Recording instructions';
// const requestReviewText = 'Request review text';
// const starsRatingScreenText = 'Stars rating screen text';

const Index = () => {
  return (
    <React.Fragment>
      <Container
        maxWidth={false}
        style={{
          // backgroundColor: "#ffffff",
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <Paper style={style}></Paper>
      </Container>
    </React.Fragment>
  );
};
export default Index;
