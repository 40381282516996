import { Button, Grid, Box, Typography, Container } from "@mui/material";
import React from "react";
import registerSide from "./../../assets/registerSide.png";
import logo from "./../../assets/logo.png";
import verify from "./../../assets/verify.png";
import { useNavigate } from "react-router-dom";

import "./index.css";

const Index = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={5} className="image-side">
            <img src={registerSide} alt="" />
          </Grid>
          <Grid
            item
            md={7}
            className="ScrollMenu"
            sx={{
              p: 6,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <img src={logo} alt="" />
              <Button
                variant="outlined"
                className="back-home"
                onClick={() => navigate("/")}
              >
                Go Back Home
              </Button>
            </Box>
            <Box className="main-box">
              <div className="image-box">
                <img src={verify} alt="" />
              </div>
            </Box>
            <Typography
              className="email-sent"
              sx={{
                my: 2,
              }}
            >
              Can access now
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                className="go-back-login"
                sx={{
                  py: 2,
                  px: 12,
                }}
                onClick={() => navigate("/login")}
              >
                Proceed to Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;
