import React from 'react';
import AdminOptionis1QuestionEngCompotenet from '../../components/adminOptions1QuestionEng';

const AdminOptionis1QuestionEng = () => {
  return (
    <React.Fragment>
      <AdminOptionis1QuestionEngCompotenet />
    </React.Fragment>
  );
};

export default AdminOptionis1QuestionEng;
