import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Input,
  Button,
  IconButton,
} from '@mui/material';

import Highlighter from 'react-highlight-words';

import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
// import { questions } from '../adminOptions4Question/constnats';

import RemoveSubjectDialog from './RemoveSubjectDialog';
import SubjectSentencesDialog from './SubjectSentencesDialog';

function PosNegQ4Tables(props) {
  const [positiveSubjectInput, setPositiveSubjectInput] = useState('');
  const positiveSubjectInputChange = (e) => {
    setPositiveSubjectInput(e.target.value);
  };
  const [negativeSubjectInput, setNegativeSubjectInput] = useState('');
  const negativeSubjectInputChange = (e) => {
    setNegativeSubjectInput(e.target.value);
  };

  const [openRemoveSubjectModal, setOpenRemoveSubjectModal] =
    React.useState(false);
  const handleClickOpenRemoveSubjectModal = () =>
    setOpenRemoveSubjectModal(true);
  const handleCloseRemoveSubjectModal = () => setOpenRemoveSubjectModal(false);

  const [openSubjectSentencesDialog, setOpenSubjectSentencesDialog] =
    React.useState(false);
  const handleClickOpenSubjectSentencesDialog = () =>
    setOpenSubjectSentencesDialog(true);
  const handleCloseSubjectSentencesDialog = () =>
    setOpenSubjectSentencesDialog(false);

  const [remainingPositiveText, setRemainingPositiveText] = useState('');
  const [remainingNegativeText, setRemainingNegativeText] = useState('');

  const [positiveSubjectCount, setPositiveSubjectCount] = useState([]);
  const [negativeSubjectCount, setNegativeSubjectCount] = useState([]);

  const [subjectToRemove, setSubjectToRemove] = useState('');
  const [subjectToRemoveDirection, setSubjectToRemoveDirection] = useState('');

  const [positiveSubjectsList, setPositiveSubjectsList] = useState([]);
  const [negativeSubjectsList, setNegativeSubjectsList] = useState([]);

  const [subjectSentences, setSubjectSentences] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedDirection, setSelectedDirection] = useState('');

  const [positiveWordsToMark, setPositiveWordsToMark] = useState([]);
  const [negativeWordsToMark, setNegativeWordsToMark] = useState([]);

  useEffect(() => {
    const getPositiveSubjectsCountArray = async () => {
      await axios
        .post(
          process.env.REACT_APP_BASE_API_URL +
            '/italianSubjects/proccese-italian-feedback',
          {
            feedbacks: props.positiveText,
            type: props.selectedType,
            direction: 'positive',
            questionNumber: props.questionNumber,
          },
        )
        .then((res) => {
          setRemainingPositiveText(res.data.feedback);
          setPositiveSubjectCount([...res.data.arr]);
          // props.setPositiveCountArray([...res.data.arr]); //needed in index.js file to generate excel chrts
          //   for (const a of res.data.arr) {
          //     console.log("positive count " + a.count);
          //     console.log("positive subject " + a.subject);
          //   }
        })
        .catch((e) => console.log(e));

      const positiveWordsToMark = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/italianSubjects/sentences-words',
        {
          params: {
            type: props.selectedType,
            direction: 'positive',
            questionNumber: props.questionNumber,
          },
        },
      );

      setPositiveWordsToMark([...positiveWordsToMark.data]);
    };
    getPositiveSubjectsCountArray();
  }, [props.positiveText]);

  useEffect(() => {
    const getNegativeSubjectsCountArray = async () => {
      await axios
        .post(
          process.env.REACT_APP_BASE_API_URL +
            '/italianSubjects/proccese-italian-feedback',
          {
            feedbacks: props.negativeText,
            type: props.selectedType,
            direction: 'negative',
            questionNumber: props.questionNumber,
          },
        )
        .then((res) => {
          setRemainingNegativeText(res.data.feedback);
          setNegativeSubjectCount([...res.data.arr]);
          // props.setNegativeCountArray([...res.data.arr]); //needed in index.js file to generate excel chrts
          //   for (const a of res.data.arr) {
          //     console.log("negative count " + a.count);
          //     console.log("negative subject " + a.subject);
          //   }
        })
        .catch((e) => console.log(e));

      const negativeWordsToMark = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/italianSubjects/sentences-words',
        {
          params: {
            type: props.selectedType,
            direction: 'negative',
            questionNumber: props.questionNumber,
          },
        },
      );
      setNegativeWordsToMark([...negativeWordsToMark.data]);
    };
    getNegativeSubjectsCountArray();
  }, [props.negativeText]);

  useEffect(() => {
    props.setSelectedDirection('');
    props.setSelectedSubject('');
    props.setSelectedQuestionNuber('');

    if (props.selectedType) {
      const getPositiveData = async () => {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            '/italianSubjects/subjects-by-type-and-question',
          {
            params: {
              type: props.selectedType,
              questionNumber: props.questionNumber,
              direction: 'positive',
            },
          },
        );
        setPositiveSubjectsList([...response.data]);
      };

      getPositiveData();

      const getNegativeData = async () => {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            '/italianSubjects/subjects-by-type-and-question',
          {
            params: {
              type: props.selectedType,
              questionNumber: props.questionNumber,
              direction: 'negative',
            },
          },
        );
        setNegativeSubjectsList([...response.data]);
      };

      getNegativeData();
    }
  }, [props.selectedType]);

  const positiveSubjectKeyDown = () => {};
  const negativeSubjectKeyDown = () => {};

  const addPositiveSubject = async (e) => {
    //setError("");

    if (props.selectedType.length > 1 && positiveSubjectInput.length > 0) {
      await axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/italianSubjects/add-subject',
          {
            name: positiveSubjectInput,
            type: props.selectedType,
            direction: 'positive',
            questionNumber: props.questionNumber,
          },
        )
        .then((res) => {
          const { message } = res.data;
          console.log(message);
          //setError(message);
          positiveSubjectsList.push(positiveSubjectInput);
          setPositiveSubjectsList([...positiveSubjectsList]);
          setPositiveSubjectInput('');
        })
        .catch((error) => {
          const { message } = error.response.data;
          console.log(message);
          //setError(message);
        });
    } else {
      //setError("Select Type");
    }
  };
  const addNegativeSubject = async () => {
    //setError("");

    if (props.selectedType.length > 1 && negativeSubjectInput.length > 0) {
      await axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/italianSubjects/add-subject',
          {
            name: negativeSubjectInput,
            type: props.selectedType,
            direction: 'negative',
            questionNumber: props.questionNumber,
          },
        )
        .then((res) => {
          const { message } = res.data;
          console.log(message);
          //setError(message);
          negativeSubjectsList.push(negativeSubjectInput);
          setNegativeSubjectsList([...negativeSubjectsList]);
          setNegativeSubjectInput('');
        })
        .catch((error) => {
          const { message } = error.response.data;
          console.log(message);
          //setError(message);
        });
    } else {
      //setError("Select Type");
    }
  };

  const deleteSubject = (subject, dir) => {
    setSubjectToRemove(subject);
    setSubjectToRemoveDirection(dir);

    handleClickOpenRemoveSubjectModal();
  };

  const selectSubject = async (e, dir) => {
    setSelectedSubject(e.target.textContent);
    setSelectedDirection(dir);

    props.setSelectedDirection(dir);
    props.setSelectedSubject(e.target.textContent);
    props.setSelectedQuestionNuber(props.questionNumber);

    const res = await axios.get(
      process.env.REACT_APP_BASE_API_URL +
        '/italianSubjects/all-subject-sentences',
      {
        params: {
          name: e.target.textContent,
          direction: dir,
          questionNumber: props.questionNumber,
          type: props.selectedType,
        },
      },
    );
    setSubjectSentences(res.data);
    handleClickOpenSubjectSentencesDialog();
  };

  return (
    <Grid
      Container
      marginLeft="5%"
      maxWidth={'100%'}
      style={{ border: '1px solid #5FEFC0' }}
    >
      <center>
        <div style={{ padding: '20px' }}>
          <Typography>
            {props.selectedType} Question Number {props.questionNumber}{' '}
          </Typography>
          <Typography>{props.question}</Typography>
        </div>
        <Grid
          //marginTop="50px"
          container
          //spacing={2}
          direction="row"
          xs={10}
        >
          <Grid style={{ border: '1px solid #5FEFC0' }} xs={5}>
            <h2>
              <center>Positive subjects</center>
            </h2>
            {positiveSubjectsList.map((subject, index) => {
              return (
                <div>
                  <IconButton
                    onClick={() => deleteSubject(subject, 'positive')}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Button
                    id={'pos' + index}
                    value={subject}
                    name={subject}
                    onClick={(e) => selectSubject(e, 'positive')}
                    style={{ textTransform: 'unset' }}
                  >
                    {subject}
                  </Button>
                </div>
              );
            })}
            <center>
              <Input
                value={positiveSubjectInput}
                style={{ backgroundColor: '#BDDAFF' }}
                id="positiveSubjectInput"
                onChange={positiveSubjectInputChange}
                onKeyDown={positiveSubjectKeyDown}
              ></Input>
            </center>
            <Button
              style={{ textTransform: 'unset', margin: '4px' }}
              size="small"
              variant="outlined"
              onClick={addPositiveSubject}
            >
              Add Posivite Subject
            </Button>
          </Grid>
          <Grid style={{ border: '1px solid #5FEFC0' }} xs={5}>
            <h2>
              <center>Negative subjects</center>
            </h2>
            {negativeSubjectsList.map((subject, index) => {
              return (
                <div>
                  <IconButton
                    onClick={() => deleteSubject(subject, 'negative')}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Button
                    id={'neg' + index}
                    value={subject}
                    name={subject}
                    onClick={(e) => selectSubject(e, 'negative')}
                    style={{ textTransform: 'unset' }}
                  >
                    {subject}
                  </Button>
                </div>
              );
            })}
            <center>
              <Input
                value={negativeSubjectInput}
                style={{ backgroundColor: '#BDDAFF' }}
                id="negativeSubjectInput"
                onChange={negativeSubjectInputChange}
                onKeyDown={negativeSubjectKeyDown}
              ></Input>
            </center>
            <Button
              style={{ textTransform: 'unset', margin: '4px' }}
              size="small"
              variant="outlined"
              onClick={addNegativeSubject}
            >
              Add Negative Subject
            </Button>
          </Grid>
          <Grid container spacing={2} xs={10} direction="row">
            <Box xs={5} padding={'20px'}>
              <Typography>
                <center>
                  <h4>Positive subjects found</h4>
                </center>
              </Typography>
              <Grid textAlign="center" style={{ border: '1px solid #5FEFC0' }}>
                {positiveSubjectCount.map((subject) => {
                  return (
                    <Typography>
                      {subject.count} {subject.subject} {subject.type}
                    </Typography>
                  );
                })}
              </Grid>
            </Box>
            <Box xs={5} padding={'20px'}>
              <Typography>
                <center>
                  <h4>Negative subjects found</h4>
                </center>
              </Typography>
              <Grid textAlign="center" style={{ border: '1px solid #5FEFC0' }}>
                {negativeSubjectCount.map((subject) => {
                  return (
                    <Typography>
                      {subject.count} {subject.subject} {subject.type}
                    </Typography>
                  );
                })}
              </Grid>
            </Box>
          </Grid>
          <RemoveSubjectDialog
            openRemoveSubjectModal={openRemoveSubjectModal}
            handleCloseRemoveSubjectModal={handleCloseRemoveSubjectModal}
            subjectToRemove={subjectToRemove}
            subjectToRemoveDirection={subjectToRemoveDirection}
            selectedType={props.selectedType}
            questionNumber={props.questionNumber}
            positiveSubjectsList={positiveSubjectsList}
            setPositiveSubjectsList={setPositiveSubjectsList}
            negativeSubjectsList={negativeSubjectsList}
            setNegativeSubjectsList={setNegativeSubjectsList}
          />
          <SubjectSentencesDialog
            openSubjectSentencesDialog={openSubjectSentencesDialog}
            handleCloseSubjectSentencesDialog={
              handleCloseSubjectSentencesDialog
            }
            subjectSentences={subjectSentences}
            selectedType={props.selectedType}
            questionNumber={props.questionNumber}
            selectedSubject={selectedSubject}
            selectedDirection={selectedDirection}
          />
          <Box marginTop="2%" marginBottom="2%" maxWidth={'100%'}>
            <Grid
              style={{ border: '1px solid #5FEFC0', wordWrap: 'break-word' }}
              marginBottom="10px"
            >
              <Typography>
                <center>
                  <h4>Positive feedbacks</h4>
                </center>
              </Typography>
              <Highlighter
                searchWords={positiveWordsToMark}
                autoEscape={true}
                textToHighlight={remainingPositiveText}
              />
            </Grid>
            <Grid
              style={{ border: '1px solid #5FEFC0', wordWrap: 'break-word' }}
            >
              <Typography>
                <center>
                  <h4>Negative feedbacks</h4>
                </center>
              </Typography>
              <Highlighter
                searchWords={negativeWordsToMark}
                autoEscape={true}
                textToHighlight={remainingNegativeText}
              />
            </Grid>
          </Box>
        </Grid>
      </center>
    </Grid>
  );
}

export default PosNegQ4Tables;
