import React from 'react';
import { useState } from 'react';
import axios from 'axios';

import { Grid, FormControl, Select, MenuItem } from '@mui/material';

const wordClasses = [
  'subjects',
  'verbs',
  'objects',
  'coordinatingConjunctions',
  'articles',
  'pronouns',
  'adjectives',
  'adverbs',
  'conjunctioins',
  'interjections',
  'nouns',
  'preposition',
];

const WordClassSelect = (props) => {
  const [wordClass, setWordClass] = useState('');

  const handleChange = async (event) => {
    setWordClass(event.target.value);
    switch (props.selectNumber) {
      case '1':
        // const call = await axiosCall(event.target.value);
        // console.log(call)
        props.setWord1(await axiosCall(event.target.value));
        break;
      case '2':
        props.setWord2(await axiosCall(event.target.value));
        break;
      case '3':
        props.setWord3(await axiosCall(event.target.value));
        break;
      case '4':
        props.setWord4(await axiosCall(event.target.value));
        break;
      case '5':
        props.setWord5(await axiosCall(event.target.value));
        break;
      case '6':
        props.setWord6(await axiosCall(event.target.value));
        break;
      default:
        break;
    }
  };

  const axiosCall = (wordClass) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          process.env.REACT_APP_BASE_API_URL + '/speechToText/get-random-word',
          { params: { wordClass } },
        )
        .then((res) => {
          // console.log(res.data)
          resolve(res.data);
        })
        .catch((e) => console.log(e));
    });
  };

  return (
    <React.Fragment>
      <Grid sx={{ minWidth: 120 }}>
        <FormControl>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            // labelId="demo-simple-select-label"
            // id="demo-simple-select"
            value={wordClass}
            // label="Age"
            onChange={handleChange}
          >
            {wordClasses.map((wordClass) => (
              <MenuItem value={wordClass} name={wordClass}>
                {wordClass}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
};

export default WordClassSelect;
