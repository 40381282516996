import * as React from 'react';
import axios from 'axios';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

export default function HotelsSelect({
  selectedClientId,
  setHotelId,
  hotelsList,
  setHotelsList,
}) {
  //   const [hotelsList, setHotelsList] = React.useState([]);
  const [disabledHotelsSelect, setDisabledHotelsSelect] = React.useState(false);
  React.useEffect(() => {
    if (!selectedClientId) {
      setDisabledHotelsSelect(true);
    } else {
      setDisabledHotelsSelect(false);
    }
  }, [selectedClientId]);

  React.useEffect(() => {
    if (selectedClientId) {
      const getHotels = async function () {
        const res = await axios
          .get(process.env.REACT_APP_BASE_API_URL + '/hotels/get-user-hotels', {
            params: { user: selectedClientId },
          })
          .catch((e) => {
            if (e.response.status === 404) {
              console.log(e.response.data.message);
              // return e.response.data.message;
            }
          });
        if (res) {
          setHotelsList(res.data);
        }
      };
      getHotels();
    }
  }, [selectedClientId]);

  const changeSelectHotels = function (e) {
    setHotelId(e.target.value);
  };
  return (
    <React.Fragment>
      <Grid>
        <FormControl sx={{ minWidth: '250px', mt: 2 }} variant="standard">
          <InputLabel shrink htmlFor="bootstrap-input">
            Client Hotels
          </InputLabel>
          <Select
            style={{ backgroundColor: '#CDCDCD' }}
            onChange={changeSelectHotels}
            disabled={disabledHotelsSelect}
          >
            {hotelsList.map((hotel) => {
              return (
                <MenuItem value={hotel._id} name={hotel.name} key={hotel._id}>
                  {hotel.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
