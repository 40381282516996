import {
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Container,
} from '@mui/material';

import React, { useState, useEffect } from 'react';
import * as EmailValidator from 'email-validator';
import registerSide from './../../assets/registerSide.png';
import logo from './../../assets/logo.png';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';

import './index.css';

import axios from 'axios';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: '1px solid #69EFC8',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#2b2b2b',
    // border: "1px solid #ced4da",
    border: 'none',
    fontSize: 16,
    // width: 'auto',
    width: '100%',
    padding: '15px 12px',

    // transition: theme.transitions.create([
    //   "border-color",
    //   "background-color",
    //   "box-shadow",
    // ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  );
}

const Index = () => {
  const [keepLogIn, setKeepLogIn] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [serverErrorText, setServerErrorText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('keydown', pressMainBtn);
    return () => {
      window.removeEventListener('keydown', pressMainBtn);
    };
  }, []);

  const pressMainBtn = (e) => {
    if (e.key === 'Enter') {
      btnLoginClick();
    }
  };

  const btnLoginClick = () => {
    const email = document.getElementById('email').value.trim();
    const password = document.getElementById('password').value.trim();
    let isError = false;

    if (!email) {
      setEmailError('Email is required');
      isError = true;
    } else {
      const isEmailValid = EmailValidator.validate(email);
      if (!isEmailValid) {
        setEmailError('Email isn`t valid');
        isError = true;
      } else {
        if (emailError) {
          setEmailError('');
        }
      }
    }

    if (!password) {
      setPasswordError('Password is required');
      isError = true;
    } else {
      if (passwordError) {
        setPasswordError('');
      }
    }

    if (isError) {
      return;
    }

    const data = {
      email: email,
      password: password,
      keepLogIn: keepLogIn,
    };

    localStorage.setItem('userEmail', email);
    axios
      .post(process.env.REACT_APP_BASE_API_URL + `/users/login`, data)
      .then((res) => {
        const { token } = res.data;
        localStorage.setItem('token', token);
        navigate('/user/dashboard');
      })
      .catch((error) => {
        console.log(error);

        let serverErrorTextLocal = '';

        switch (error.response.status) {
          case 401:
            serverErrorTextLocal = 'User with that email is not found!';
            break;
          case 402:
            serverErrorTextLocal = 'Please, verify your email';
            break;
          case 403:
            serverErrorTextLocal = 'Incorrect password!';
            break;
          default:
            break;
        }

        if (serverErrorTextLocal) {
          setServerErrorText(serverErrorTextLocal);
        }
      });
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={5} style={{ background: '#5FEFC0', width: '100%' }}>
            <img src={registerSide} alt="" />
          </Grid>
          <Grid
            item
            md={7}
            className="classScroll"
            sx={{
              p: 6,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <img src={logo} alt="" />
              <Button
                variant="outlined"
                className="back-home"
                onClick={() => navigate('/')}
              >
                Go Back Home
              </Button>
            </Box>
            <Typography
              sx={{ fontSize: '48px', fontWeight: '500', lineHeight: '1' }}
            >
              Hi, Welcome
              <br /> Back!
            </Typography>
            <Typography sx={{ my: 3 }}>
              Still don’t have an account?
              <Link to="/register"> Register here</Link>
            </Typography>

            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Email*
              </InputLabel>
              <BootstrapInput
                type="email"
                id="email"
                fullWidth
                placeholder="email@gmail.com"
              />
              <Typography sx={{ color: 'red' }}>{emailError}</Typography>
            </FormControl>

            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Password*
              </InputLabel>
              <BootstrapInput
                type="password"
                id="password"
                fullWidth
                placeholder="Password"
              />
              <Typography sx={{ color: 'red' }}>{passwordError}</Typography>
            </FormControl>

            <Box
              sx={{
                my: 2,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <BpCheckbox />
                <Typography
                  sx={{ fontSize: '14px', ml: 1 }}
                  onClick={() => setKeepLogIn(true)}
                >
                  Keep me loggin
                </Typography>
              </Box>
              <Typography
                className="forgot-text"
                sx={{
                  mt: 1,
                }}
                onClick={() => navigate('/forgot-password')}
              >
                Forgot Password?
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                className="go-back-login"
                sx={{
                  px: 10,
                  py: 1,
                }}
                onClick={btnLoginClick}
              >
                Login
              </Button>
            </Box>
            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <Typography sx={{ color: 'red' }}>{serverErrorText}</Typography>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;
