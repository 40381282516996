import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  //   Typography,
  //   Card,
  //   Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  //   Divider,
  //   Menu,
  //   Paper,
  //   Input,
  //   Button,
  //   IconButton,
  //   Dialog,
  //   ListItemText,
  //   ListItem,
  //   List,
  //   AppBar,
  //   Toolbar,
  //   Slide,
} from '@mui/material';
import AddNewStoreModal from './addNewStoreModal';
import EditStoreDetalis from './editStoreDetails';
import DeleteStoreModal from './deleteStoreModal';
import axios from 'axios';

function SelectClientStores(props) {
  const { storesVersion } = props;
  const [storeId, setStoreId] = useState('');
  const [clientId, setClientId] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [storesList, setStoresList] = useState([]);

  useEffect(() => {
    const getAllClientsDetails = async () => {
      const clients = await axios.get(
        process.env.REACT_APP_BASE_API_URL + `/users/get-users`,
      );
      setClientsList(clients.data);
      // console.log(clients.data);
    };
    getAllClientsDetails();
  }, []);

  const selectedClient = async function (event) {
    const clientId = event.target.value;
    setClientId(clientId);
    const stores = await axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          `/stores/get-stores-by-version-and-user-id`,
        {
          params: {
            userId: clientId,
            version: storesVersion,
          },
        },
      )
      .catch((error) => {
        if (error.response) {
          console.log(JSON.stringify(error.response.data));
        }
      });
    setStoresList(stores.data);
  };

  const selectStoreId = function (event) {
    setStoreId(event.target.value);
  };

  return (
    <Grid
      item
      xs={6}
      style={{
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        padding: '20px',
        // textAlign: 'center',
      }}
    >
      <h2>Stores Version {storesVersion}</h2>
      <Box maxWidth="300px" paddingTop="10px">
        <FormControl fullWidth size="small">
          <InputLabel>Clients List</InputLabel>
          <Select onChange={selectedClient}>
            {clientsList.map((client) => (
              <MenuItem value={client._id} name={client.name}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box maxWidth="300px" paddingTop="10px">
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Stores List</InputLabel>
          <Select onChange={selectStoreId}>
            {storesList.map((store) => (
              <MenuItem value={store._id} name={store.name}>
                {store.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <AddNewStoreModal
        clientId={clientId}
        setStoresList={setStoresList}
        storesList={storesList}
        version={storesVersion}
      />
      <EditStoreDetalis
        storeId={storeId}
        setStoresList={setStoresList}
        storesList={storesList}
        version={storesVersion}
      />

      <DeleteStoreModal
        storesList={storesList}
        setStoresList={setStoresList}
        version={storesVersion}
        // setUserStoreId={setStoreId}
      />
    </Grid>
  );
}

export default SelectClientStores;
