import React from "react";
import Emotions from "../../components/emotions/index";
const Index = () => {
  return (
    <React.Fragment>
      <Emotions/>
    </React.Fragment>
  );
};

export default Index;