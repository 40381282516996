import {
  Grid,
  Button,
  Box,
  Typography,
  Card,
  Container,
  InputLabel,
} from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { useAppState } from '../../AppStateContext';
// import {
//   getPositiveSolution,
//   getNegativeSolution,
//   getPositiveSubjects,
//   getNegativeSubjects,
// } from './solutions';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import star from './../../assets/star.png';
import arrow from './../../assets/Arrow 1.png';
import arrow2 from './../../assets/Arrow 2.png';
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Graph from './../graph/index.js';
import ReactEcharts from 'echarts-for-react';
import excellent from './../../assets/excellent.png';
import veryGood from './../../assets/verygood.png';
import good from './../../assets/good.png';
import fair from './../../assets/fair.png';

import dotsImage from '../../assets/dots.webp';

import QuestionsCharts from './../graph/questionCharts.js';

import './index.css';

const Index = () => {
  const [satisfactionChart, setSatisfactionChart] = useState([
    {
      image: excellent,
      title: 'Excellent',
      score: 0,
    },
    {
      image: veryGood,
      title: 'Very Good',
      score: 0,
    },
    {
      image: good,
      title: 'Good',
      score: 0,
    },
    {
      image: fair,
      title: 'Fair',
      score: 0,
    },
  ]);

  const [options, setOptions] = useState({
    series: [
      {
        type: 'pie',
        radius: ['50%', '70%'],
        data: [],
      },
    ],
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [isOpenStartDate, setIsOpenStartDate] = useState(false);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);

  const changeStartDate = (e) => {
    const timeZoneOffset = e.getTimezoneOffset();
    const startDateTimestamp = e.getTime() - timeZoneOffset * 60 * 1000;
    const utcStartDate = new Date(startDateTimestamp);
    setStartDate(utcStartDate);
    setIsOpenStartDate(false);
  };

  const changeEndDate = (e) => {
    const timeZoneOffset = e.getTimezoneOffset();
    const endDateTimestamp = e.getTime() - timeZoneOffset * 60 * 1000;
    const utcEndDate = new Date(endDateTimestamp);
    setEndDate(utcEndDate);
    setIsOpenEndDate(false);
  };

  const startDateClick = () => {
    setIsOpenStartDate(!isOpenStartDate);
  };

  const endDateClick = () => {
    setIsOpenEndDate(!isOpenEndDate);
  };

  const [storeReviews, setStoreReviews] = useState([]);
  const [satisfactionScore, setSatisfactionScore] = useState(0);
  useEffect(() => {
    let satisfactionChartData = [];
    if (storeReviews.length > 0) {
      //{ value: fiarCount },
      // { value: goodCount },
      // { value: veryGoodCount },
      // { value: excellentCount },
      satisfactionChartData = getSatisfactionChartData(storeReviews);
      const positiveScore =
        satisfactionChartData[3].value + // excellentCount
        satisfactionChartData[2].value + // veryGoodCount
        satisfactionChartData[1].value; // goodCount
      const totalScores = positiveScore + satisfactionChartData[0].value; // satisfactionChartData[0].value => fiarCount
      setSatisfactionScore(Math.round((positiveScore / totalScores) * 100));

      // setTotalReviews(storeReviews.length);
      // setTotalPositive(positiveScore);
      // setTotalNegative(satisfactionChartData[0].value);

      setSatisfactionChart([
        {
          image: excellent,
          title: 'Excellent',
          score: satisfactionChartData[3].value,
        },
        {
          image: veryGood,
          title: 'Very Good',
          score: satisfactionChartData[2].value,
        },
        {
          image: good,
          title: 'Good',
          score: satisfactionChartData[1].value,
        },
        {
          image: fair,
          title: 'Fair',
          score: satisfactionChartData[0].value,
        },
      ]);
    } else {
      // setTotalReviews(0);
      // setTotalPositive(0);
      // setTotalNegative(0);
      setSatisfactionScore(0);

      setSatisfactionChart([
        {
          image: excellent,
          title: 'Excellent',
          score: 0,
        },
        {
          image: veryGood,
          title: 'Very Good',
          score: 0,
        },
        {
          image: good,
          title: 'Good',
          score: 0,
        },
        {
          image: fair,
          title: 'Fair',
          score: 0,
        },
      ]);
    }
    const reversedArray = satisfactionChartData.reverse();
    setOptions({
      toolbox: {
        show: true,
      },
      tooltip: {
        trigger: 'item',
        formatter: '{d}%',
      },
      legend: {
        orient: 'vertical',
        x: 'left',
        data: [],
        textStyle: {
          color: ['#74788d'],
        },
      },
      color: ['#62E9C3', '#437FEC', '#EDA24B', '#FF9C90', '#3c4ccf'],
      series: [
        {
          name: 'Satisfaction',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: true,
          label: {
            normal: {
              show: true,
              position: 'center',
            },
          },
          labelLine: {
            normal: {
              show: true,
            },
          },
          data: reversedArray,
        },
      ],
    });
  }, [storeReviews]);

  const { storeId, storeVersion } = useAppState(); // storeId = updateSotoreId
  useEffect(() => {
    if (storeId) {
      getOldestRiview();
      getStoreReviews();
      setPositiveSummary('');
      setNegativeSummary('');
    }
  }, [storeId]);

  const getStoreReviews = async function () {
    const storeReviews = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/reviews/store-reviews',
      { params: { storeId } },
    );

    setStoreReviews(storeReviews.data);
  };

  const getOldestRiview = async function () {
    const oldestReviwe = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/reviews/oldest-review',
      { params: { storeId } },
    );
    // setStartDate to oldest date in reviews data base
    if (Object.keys(oldestReviwe.data).length > 0) {
      setStartDate(new Date(oldestReviwe.data.date));
    } else {
      setStartDate(new Date());
    }
  };

  const getSatisfactionChartData = function (reviews) {
    let fiarCount = 0,
      goodCount = 0,
      veryGoodCount = 0,
      excellentCount = 0;

    for (const review of reviews) {
      switch (review.stars) {
        case 1:
        case 2:
          fiarCount++;
          break;
        case 3:
          goodCount++;
          break;
        case 4:
          veryGoodCount++;
          break;
        case 5:
          excellentCount++;
          break;
        default:
          break;
      }
    }

    if (!fiarCount && !goodCount && !veryGoodCount && !excellentCount) {
      return [];
    }
    return [
      { value: fiarCount },
      { value: goodCount },
      { value: veryGoodCount },
      { value: excellentCount },
    ];
  };

  useEffect(() => {
    if (startDate && endDate && storeId) {
      getReviewsChartsData(startDate, endDate, storeId);
      // console.log(startDate, endDate, storeId);
    }
  }, [startDate, endDate]);

  const [q1Positive, setQ1Positive] = useState([]);
  const [q2Positive, setQ2Positive] = useState([]);
  const [q3Positive, setQ3Positive] = useState([]);
  const [q4Positive, setQ4Positive] = useState([]);

  const [q1Negative, setQ1Negative] = useState([]);
  const [q2Negative, setQ2Negative] = useState([]);
  const [q3Negative, setQ3Negative] = useState([]);
  const [q4Negative, setQ4Negative] = useState([]);

  const [allFoundedPositiveSentences, setAllFoundedPositiveSentences] =
    useState([]);
  const [allFoundedNegativeSentences, setAllFoundedNegativeSentences] =
    useState([]);

  const [totalPositive, setTotalPositive] = useState(0);
  const [totalNegative, setTotalNegative] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);

  const getReviewsChartsData = async function (startDate, endDate, storeId) {
    const start = new Date(startDate).toISOString();
    const end = new Date(endDate).toISOString();
    // console.log("D", startDate, endDate, storeId);
    const questionsChartsData = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/reviews/get-reviews-charts-data',
      {
        params: {
          storeVersion,
          storeId,
          start,
          end,
        },
      },
    );

    if (questionsChartsData.data) {
      const {
        q1Positive,
        q2Positive,
        q3Positive,
        q4Positive,
        q1Negative,
        q2Negative,
        q3Negative,
        q4Negative,
      } = questionsChartsData.data;
      setQ1Positive(q1Positive.subjectCount);
      setQ1Negative(q1Negative.subjectCount);
      let positiveFoundedSentences = [];
      let negayiveFoundedSentences = [];
      if (storeVersion === 4) {
        setQ2Positive(q2Positive.subjectCount);
        setQ3Positive(q3Positive.subjectCount);
        setQ4Positive(q4Positive.subjectCount);

        setQ2Negative(q2Negative.subjectCount);
        setQ3Negative(q3Negative.subjectCount);
        setQ4Negative(q4Negative.subjectCount);
        positiveFoundedSentences = [...q4Positive.foundSentences];

        negayiveFoundedSentences = [...q4Negative.foundSentences];
      }
      positiveFoundedSentences = [
        ...positiveFoundedSentences,
        ...q1Positive.foundSentences,
      ];

      negayiveFoundedSentences = [
        ...negayiveFoundedSentences,
        ...q1Negative.foundSentences,
      ];

      setAllFoundedPositiveSentences(positiveFoundedSentences);
      // alert("setAllFoundedPositiveSentences");
      setAllFoundedNegativeSentences(negayiveFoundedSentences);
      // alert("setAllFoundedNegativeSentences");
      const q1TotalPositive = totalSubjectsCount(q1Positive.subjectCount);
      const q1TotalNegative = totalSubjectsCount(q1Negative.subjectCount);
      let q234TotalPos = 0;
      let q234TotalNeg = 0;
      if (storeVersion === 4) {
        const q2TotalPositive = totalSubjectsCount(q2Positive.subjectCount);
        const q3TotalPositive = totalSubjectsCount(q3Positive.subjectCount);
        const q4TotalPositive = totalSubjectsCount(q4Positive.subjectCount);
        const q2TotalNegative = totalSubjectsCount(q2Negative.subjectCount);
        const q3TotalNegative = totalSubjectsCount(q3Negative.subjectCount);
        const q4TotalNegative = totalSubjectsCount(q4Negative.subjectCount);
        q234TotalPos = q2TotalPositive + q3TotalPositive + q4TotalPositive;
        q234TotalNeg = q2TotalNegative + q3TotalNegative + q4TotalNegative;
      }

      const totalPositive = q234TotalPos + q1TotalPositive;
      setTotalPositive(totalPositive);

      const totalNegative = q234TotalNeg + q1TotalNegative;
      setTotalNegative(totalNegative);
      setTotalReviews(totalNegative + totalPositive);
    } else {
      setQ1Positive([]);
      setQ2Positive([]);
      setQ3Positive([]);
      setQ4Positive([]);

      setQ1Negative([]);
      setQ2Negative([]);
      setQ3Negative([]);
      setQ4Negative([]);

      setTotalPositive(0);
      setTotalNegative(0);
      setTotalReviews(0);

      setAllFoundedPositiveSentences([]);
      setAllFoundedNegativeSentences([]);

      setPositiveSummary('');
      setNegativeSummary('');
    }
  };

  const totalSubjectsCount = (subjectCounts) => {
    let count = 0;
    for (const subjectCount of subjectCounts) {
      count += subjectCount.count;
    }
    return count;
  };

  const [playDots, setPlayDots] = useState(false);
  const [positiveSummary, setPositiveSummary] = useState('');
  const createPositiveSummary = async () => {
    if (allFoundedPositiveSentences <= 0) {
      return;
    }
    setPositiveSummary('');
    setPlayDots(true);
    const summary = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/reviews/get-positive-summary',
      { params: { text: allFoundedPositiveSentences.join('.') } },
    );
    // console.log(summary.data);
    setPositiveSummary(summary.data);
    setPlayDots(false);
  };

  const [negativeSummary, setNegativeSummary] = useState('');
  const createNegativeSummary = async () => {
    if (allFoundedNegativeSentences <= 0) {
      return;
    }
    setNegativeSummary('');
    setPlayDots(true);
    const summary = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/reviews/get-negative-summary',
      { params: { text: allFoundedNegativeSentences.join('.') } },
    );
    // console.log(summary.data);
    setNegativeSummary(summary.data);
    setPlayDots(false);
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid
            item
            container
            xs={9}
            sx={{ pr: 2, mt: 3 }}
            justifyContent={'space-between'}
          >
            <Grid
              item
              xs={3.9}
              sx={{ background: '#fff', width: '100%', p: 3 }}
            >
              <Typography sx={{ color: '#4cd9ed' }}>All Feedbacks</Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ fontSize: '40px', fontWeight: '600' }}>
                  {totalReviews}
                </Typography>
                <Box
                  sx={{
                    borderRadius: '50px',
                    background: '#edf5f5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '60px',
                    height: '60px',
                  }}
                >
                  <img src={star} alt="" />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={3.9}
              sx={{ background: '#fff', width: '100%', p: 3 }}
            >
              <Typography sx={{ color: '#5FEFC0' }}>Positive</Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ fontSize: '40px', fontWeight: '600' }}>
                  {totalPositive}
                </Typography>
                <Box
                  sx={{
                    borderRadius: '50px',
                    background: '#edf5f5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '60px',
                    height: '60px',
                  }}
                >
                  <img src={arrow} alt="" />
                </Box>
              </Box>
              {/* <div style={{ fontSize: "12px" }}>
                <img src={positiveArrow} alt="" />
                <lable style={{ fontSize: "11px", color: "#69F0C4" }}>
                  0%{" "}
                </lable>
                Up from last month
              </div> */}
            </Grid>
            <Grid
              item
              xs={3.9}
              sx={{ background: '#fff', width: '100%', p: 3 }}
            >
              <Typography sx={{ color: '#FF9C90' }}>Negative</Typography>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography sx={{ fontSize: '40px', fontWeight: '600' }}>
                  {totalNegative}
                </Typography>
                <Box
                  sx={{
                    borderRadius: '50px',
                    background: '#edf5f5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '60px',
                    height: '60px',
                  }}
                >
                  <img src={arrow2} alt="" />
                </Box>
              </Box>
              {/* <div style={{ fontSize: "12px" }}>
                <img src={negativeArrow} alt="" />
                <lable style={{ fontSize: "11px", color: "#FF9C90" }}>
                  0%{" "}
                </lable>
                Down from last month
              </div> */}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  pt: 2,
                  px: 2,
                }}
                xs={2}
              >
                <Grid xs={14} style={{ textAlign: 'center' }}>
                  <div>
                    <Typography sx={{ fontSize: '16px' }}>Analytics</Typography>
                  </div>

                  <Grid container direction="row" spacing={2}>
                    <Grid xs={3} style={{ textAlign: 'center' }}>
                      <Button
                        style={{ textTransform: 'unset' }}
                        onClick={startDateClick}
                      >
                        <b>Select start date</b>
                      </Button>
                      <InputLabel>
                        <b>{moment(startDate).format('MMMM Do YYYY')}</b>
                      </InputLabel>
                      <Grid
                        style={{
                          display: isOpenStartDate ? 'inline' : 'none',
                        }}
                      >
                        <Calendar
                          value={startDate}
                          onChange={changeStartDate}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={3} style={{ textAlign: 'center' }}>
                      <Button
                        style={{ textTransform: 'unset' }}
                        onClick={endDateClick}
                      >
                        <b>Select end Date</b>
                      </Button>
                      <InputLabel>
                        <b>{moment(endDate).format('MMMM Do YYYY')}</b>
                      </InputLabel>
                      <Grid
                        style={{
                          display: isOpenEndDate ? 'inline' : 'none',
                        }}
                      >
                        <Calendar
                          value={endDate}
                          onChange={changeEndDate}
                          minDate={startDate}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {storeVersion === 1 ? (
              <Grid
                item
                container
                xs={12}
                sx={{ pr: 2, mt: 3 }}
                justifyContent={'space-between'}
                // id="pdf-content"
              >
                <Grid item xs={12} style={{ paddingBottom: '4px' }}>
                  <Card sx={{ p: 3 }}>
                    <QuestionsCharts
                      question={''}
                      positiveSubjects={q1Positive}
                      negativeSubjects={q1Negative}
                    />
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Card>
                  <Grid item xs={12}>
                    <Graph
                      q1Positive={q1Positive}
                      q2Positive={q2Positive}
                      q3Positive={q3Positive}
                      q4Positive={q4Positive}
                      q1Negative={q1Negative}
                      q2Negative={q2Negative}
                      q3Negative={q3Negative}
                      q4Negative={q4Negative}
                    />
                  </Grid>
                </Card>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sx={{ p: 3, background: '#404040', my: 3 }}
            ></Grid>
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    bgcolor: '#7DF2CC',
                    fontWeight: 'bold',
                  }}
                >
                  Positive feedbacks
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    bgcolor: '#FF9C90',
                    fontWeight: 'bold',
                  }}
                >
                  Negative feedbacks
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sx={{
                background: '#fff',
                // minHeight: 300,
                // width: '100%',
                // overflowX: 'auto',
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  py: 3,
                  px: 2,
                  maxHeight: 300,
                  maxWidth: '100%',
                  border: '1px solid',
                  overflowX: 'auto',
                  overflowY: 'auto',
                }}
              >
                <ul class="styled-list">
                  {allFoundedPositiveSentences.map((sentence, index) => (
                    <li key={index}>{sentence}</li>
                  ))}
                </ul>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  py: 3,
                  px: 2,
                  maxHeight: 300,
                  maxWidth: '100%',
                  border: '1px solid',
                  overflowX: 'auto',
                  overflowY: 'auto',
                }}
              >
                <ul class="styled-list">
                  {allFoundedNegativeSentences.map((sentence, index) => (
                    <li key={index}>{sentence}</li>
                  ))}
                </ul>
              </Grid>
            </Grid>

            <Button
              style={{ textTransform: 'unset', color: 'black' }}
              variant="outlined"
              sx={{
                cursor: 'pointer',
                background: '#7DF2CC',
                fontWeight: 'bold',
                my: 3,
              }}
              onClick={createPositiveSummary}
            >
              Ai Summary
            </Button>
            <Grid>
              {playDots ? (
                <img
                  alt=""
                  src={dotsImage}
                  style={{ width: '150px', height: '75px', margin: '10px' }}
                ></img>
              ) : (
                ''
              )}
            </Grid>
            <Button
              style={{ textTransform: 'unset', color: 'black' }}
              variant="outlined"
              sx={{
                cursor: 'pointer',
                background: '#FF9C90',
                fontWeight: 'bold',

                my: 3,
              }}
              onClick={createNegativeSummary}
            >
              Ai Summary
            </Button>

            <Grid item container xs={12}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    bgcolor: '#7DF2CC',
                    fontWeight: 'bold',
                  }}
                >
                  Positive Ai Summary
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    textAlign: 'center',
                    bgcolor: '#FF9C90',
                    fontWeight: 'bold',
                  }}
                >
                  Negative Ai Summary
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ background: '#fff' }}>
              <Grid
                item
                xs={6}
                dir="rtl"
                sx={{
                  py: 3,
                  px: 2,
                  maxHeight: 300,
                  border: '1px solid',
                  overflow: 'auto',
                }}
              >
                {positiveSummary}
              </Grid>

              <Grid
                item
                xs={6}
                dir="rtl"
                sx={{
                  py: 3,
                  px: 2,
                  border: '1px solid',
                  maxHeight: 300,
                  overflow: 'auto',
                }}
              >
                {negativeSummary}
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ p: 3, background: '#404040', my: 3 }}
            ></Grid>
          </Grid>

          <Grid item xs={3} sx={{ my: 3, position: 'relative' }}>
            <Grid
              item
              sx={{
                background: '#fff',
                p: 1,
                height: 'fit-content',
                width: '100%',
              }}
            >
              <Typography>Customer Satisfaction</Typography>
              <Box
                style={{
                  transform: 'translate(0px,100px)',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div>
                  <Typography
                    sx={{
                      fontSize: '32px',
                      fontWeight: '600',
                      paddingLeft: '20px',
                    }}
                  >
                    {satisfactionScore}%
                  </Typography>
                  <Typography sx={{ fontSize: '18px' }}>
                    Satisfaction
                  </Typography>
                  <Typography sx={{ fontSize: '18px', paddingLeft: '25px' }}>
                    score
                  </Typography>
                </div>
              </Box>
              <Box style={{ transform: 'translate(0px,-90px)' }}>
                <ReactEcharts style={{ height: '300px' }} option={options} />
              </Box>
            </Grid>

            <Box
              sx={{
                background: '#D9D9D9',
                p: 3,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            ></Box>
            {satisfactionChart.map((obj) => (
              <Card sx={{ p: 2.1, display: 'flex', mb: 0.3 }}>
                <Grid item container>
                  <Grid
                    lg={5}
                    item
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      color: '#000000',
                    }}
                  >
                    <img src={obj.image} alt="" />
                    <Typography>{obj.title}</Typography>
                  </Grid>
                  <Grid item lg={7}>
                    <Typography sx={{ textAlign: 'right' }}>
                      {obj.score}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;
