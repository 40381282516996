import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import predectve from "./../../assets/predective.png";
import Data from "./../../assets/data engineer.png";
import History from "./../../assets/history.png";
import predectve2 from "./../../assets/predective2.png";
import predectve3 from "./../../assets/predective3.png";
import "./index.css";
const Feature = () => {
  let data = [
    {
      image: predectve,
      title: "Predictive Analytics",
      dscription:
        `Predicative analytics
        accurate data about your stores performance and client satisfaction rate.`,
    },
    {
      image: Data,
      title: "Data Engineer",
      dscription:
        `We Big data analysis, Machine learning & Artificial intelligence
        For accurate results and improvement suggestions.`,
    },
    {
      image: History,
      title: "Stars in Google",
      dscription:
        `As an official Licensed Partner, we are able to push your 4-5 starts reviews 
        into Google Search improving your business search results.`,
    },
    {
      image: predectve2,
      title: "Display reviews",
      dscription:
        `We integrate with most of the tools you’re already familiar with. 
        Create a published and share option with these influential marketing 
        integrations.`,
    },
    {
      image: predectve3,
      title: "Solutions Analytics",
      dscription:
        `Get the best solutions results for your business week spots 
        based on local and global professional brands knowledge.`,
    },
  ];
  return (
    <React.Fragment>
      <Box sx={{ background: "#fff", p: 6 }} id="feature">
        <Grid xs={12}>
          <Box className="feature-box">Features</Box>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Typography sx={{ fontSize: " 36px", fontWeight: "600" }}>
            The Future Of Review Collection & Data Analysis
            </Typography>
          </Grid>
          {data.map((obj) => (
            <Grid item xs={4} sx={{ mb: 8 }}>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <img src={obj.image} alt="" />
              </Box>
              <Typography
                className="feature-typography"
                sx={{
                  py: 2,
                }}
              >
                {obj.title}
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "250px" }}>
                  <Typography className="feature-typography1">
                    {obj.dscription}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Feature;
