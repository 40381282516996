import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Grid, Typography, Container } from '@mui/material';

import logo from './../../assets/logo.png';
import bgImage from './../../assets/bgImage.png';

//import Paper from 'material-ui/Paper';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  height: '400px',

  //bgcolor: "background.paper",
  //border: "2px solid #fff",
  boxShadow: 24,
  backgroundImage: `url(${bgImage})`,
  backgroundRepeat: 'no-repeat',
  p: 4,
  //backgroundColor: "#fff0ff",
};

const Index = () => {
  const [bgColor, setBgColr] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const [mainText, setMainText] = useState('');
  // const [thankYouText, setThankYouText] = useState('');
  const [recordingInstructions, setRecordingInstructions] = useState('');
  const [requestReviewText, setRequestReviewText] = useState('');
  // const [starsRatingScreenText, setStarsRatingScreenText] = useState('');

  useEffect(() => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    async function fetchData() {
      const res = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/stores/get-store',
        { params: { id } },
      );
      const { data } = res;
      //console.log(data);
      setBgColr(
        `rgba(${data.bgColor.r}, ${data.bgColor.g}, ${data.bgColor.b}, ${data.bgColor.a})`,
      );
      setLogoUrl(data.logo);
      setMainText(data.mainText);
      // setThankYouText(data.thankYouText);
      setRecordingInstructions(data.recordingInstructions);
      setRequestReviewText(data.requestReviewText);
      // setStarsRatingScreenText(data.starsRatingScreenText);
    }
    fetchData();
  }, []);

  const [dimensions, setDimensions] = useState({ width: '', height: '' });
  const imgElement = React.useRef(null);
  const onLogoLoad = (height, width) => {
    const ratio = width / height;

    if (height > 100) {
      height = 100;
    }
    width = ratio * height;
    if (width > 500) {
      width = 500;
    }

    setDimensions({ width, height });
  };
  return (
    <React.Fragment>
      <Container
        style={{
          height: 'calc(var(--vh, 1vh) * 100)',
          backgroundColor: bgColor,
        }}
      >
        <img
          alt=""
          src={logoUrl}
          ref={imgElement}
          onLoad={() => {
            onLogoLoad(
              imgElement.current.naturalHeight,
              imgElement.current.naturalWidth,
            );
          }}
          //style={{display: "block", marginLeft: "auto", marginRight: "auto"}}
          width={dimensions.width}
          height={dimensions.height}
        />

        <Grid style={style}>
          <Grid
            style={{
              position: 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              wordWrap: 'break-word',
              //justifyContent: "center"
            }}
          >
            <Typography style={{ paddingLeft: '10px' }}>{mainText}</Typography>
            <Typography style={{ paddingLeft: '10px' }}>
              {recordingInstructions}
            </Typography>
            <center>
              <Button style={{}}>Rec</Button>
            </center>
            <Typography style={{ paddingLeft: '10px' }}>
              {requestReviewText}
            </Typography>
            <br />
            <br />
            <br />
            <br />

            <Grid
              style={{
                //justifyContent: "center",
                position: 'relative',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',

                justifyContent: 'center',
              }}
              container
              spacing={2}
            >
              <Grid xs={2} style={{ textAlign: 'left' }}>
                <Typography style={{ fontSize: '11px', dir: 'ltr' }}>
                  Powered by
                </Typography>
              </Grid>
              <Grid xs={2} style={{ border: '1px solid #ffff' }}>
                <img
                  alt=""
                  style={{ width: '90px', height: '30px' }}
                  src={logo}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default Index;
