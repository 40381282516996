import './App.css';
import React, { useEffect } from 'react';
import Register from './pages/register/index';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { gapi } from 'gapi-script';
import User from './components/user/index';
import Login from './pages/login/login';
import RegisterVerifyEmail from './pages/registerVerifyEmail/index';
import ForgotPassword from './pages/forgotPassword/index';
import ForgotNewPassword from './pages/forgotNewPassword';
import VerifyForgotPassword from './pages/verifyForgotPassword/index';
import RegisterEmailSend from './pages/registerSendEmail/index';
import Dashboard from './pages/dashboard';
import Home from './pages/home/index';
import Analytics from './pages/historyAnalytics/index';
// import LandingPage from './pages/landingPage/index';
import AdminOptionsMain from './pages/adminOptionMain/index';
import AdminOptionsHe from './pages/adminOptionsHe/index';
import AdminOptionisEnglish from './pages/adminOptionsEnglish';
import AdminOptions4Questions from './pages/adminOptions4Questions';
import AdminOptions1Question from './pages/adminOptions1Question';
import AdminOptionis1QuestionEng from './pages/adminOptions1QuestionEng';
import AdminOptionsHotels from './pages/adminOptionsHotels';
import StoreQrCode from './pages/storeQrCode';
import ThenkYou from './pages/thenkYou';
import WordsSearch from './pages/wordsSearch';
import SpeechToTextSignup from './pages/speechToTextSignup';
import SpeechToTextSignin from './pages/speechToTextSignin';
import SpeechToTextHome from './pages/speechToTextHome';
import SpeechToText from './pages/speechToText';
import RandonSentence from './pages/randomSentence';
import Emotions from './pages/emotions/index';

function App() {
  useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        clientId:
          '592329387187-cl5mnbnnltoge1u5rficm5ievloear1b.apps.googleusercontent.com',
        plugin_name: 'chat',
      });
    });
  }, []);

  return (
    <div
    //  className="App"
    >
      <Router>
        <Routes>
          <Route path="/register" element={<Register />} />

          <Route path="/user" element={<User />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="analytics" element={<Analytics />} />
            {/* <Route path="landing-page" element={<LandingPage />} /> */}
          </Route>

          <Route exact path="/" element={<Home />} />

          <Route path="/login" element={<Login />} />
          <Route
            path="/register-email-verify"
            element={<RegisterVerifyEmail />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-new-password" element={<ForgotNewPassword />} />
          <Route
            path="/verify-forgot-password"
            element={<VerifyForgotPassword />}
          />
          <Route path="/admin-options-main" element={<AdminOptionsMain />} />
          <Route path="/admin-options-he" element={<AdminOptionsHe />} />
          <Route
            path="/admin-options-english"
            element={<AdminOptionisEnglish />}
          />
          <Route
            path="/admin-options-4questions"
            element={<AdminOptions4Questions />}
          />
          <Route
            path="/admin-options-1question"
            element={<AdminOptions1Question />}
          />
          <Route
            path="/admin-options-1question-eng"
            element={<AdminOptionis1QuestionEng />}
          />
          <Route
            path="/admin-options-hotels"
            element={<AdminOptionsHotels />}
          />
          <Route path="/words-search" element={<WordsSearch />} />

          <Route path="/register-email-send" element={<RegisterEmailSend />} />
          <Route path="/store-qr-code" element={<StoreQrCode />} />
          <Route path="/thenk-you" element={<ThenkYou />} />

          <Route path="/speech2text-singup" element={<SpeechToTextSignup />} />
          <Route path="/speech2text-singin" element={<SpeechToTextSignin />} />
          <Route path="/speech2text-home" element={<SpeechToTextHome />} />
          <Route path="/speech2text" element={<SpeechToText />} />
          <Route path="/random-sentence" element={<RandonSentence />} />

          <Route path="/emotions" element={<Emotions />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
