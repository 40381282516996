import React, { useState } from 'react';
import { Box, Typography, Container, Grid, Button } from '@mui/material';
import Column from './column';
import SaveHotelDialog from './dialogs/saveHotelDialog';
import SaveDepartmentDialog from './dialogs/saveDepartmentDialog';
import SaveHotelDepartmentDialog from './dialogs/saveHotelDepartmentDialog';
// import SaveHotelButton from './buttons/saveHotelButton';
// import SaveSubjectDialog from './dialogs/saveSubjectDialog';
import ClientsSelect from './selects/clientsSelect';
import HotelsSelect from './selects/hotelsSelect';
// import HotelDepartmentSelect from './selects/hotelDepartmentSelect';
// import DepartmentSelect from './selects/departmentSelect';
import logo from '../../assets/logo1.png';
import { useNavigate } from 'react-router-dom';
// import {getHotelsClients} from './hotelsAux/gatData';

const Index = () => {
  const navigate = useNavigate();
  // const [clientsList, setClientsList] = useState([]);

  const [selectedClientId, setSelectedClientId] = useState('');
  const handleDataFromClientSelect = (id) => {
    setSelectedClientId(id);
  };

  const [selectedHotelId, setSelectedHotelId] = React.useState('');
  const handeleDataFromHotelSelect = (id) => {
    setSelectedHotelId(id);
  };

  const [hotelsList, setHotelsList] = useState([]);
  const [hotelDepartmentsList, setHotelDepartmentsList] = useState([]);

  return (
    <>
      <Container maxWidth="lg">
        <Box
          textAlign="center"
          style={{ backgroundColor: 'black' }}
          item
          xs={2}
          onClick={() => navigate('/user/dashboard')}
        >
          <img src={logo} alt="" style={{ cursor: 'pointer' }} />
        </Box>
        <Typography style={{ textAlign: 'center' }}>
          <h2>Hotels Clients</h2>
        </Typography>
        <center>
          <Button onClick={() => navigate('/admin-options-main')}>
            Admin Main Menu
          </Button>
        </center>
        <Grid container direction="row" xs={6.5}>
          <Grid xs={4}>
            <ClientsSelect setClientId={handleDataFromClientSelect} />
          </Grid>
          <Grid xs={4} style={{ marginTop: '29px' }}>
            <SaveHotelDialog
              clientId={selectedClientId}
              hotelsList={hotelsList}
              setHotelsList={setHotelsList}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" xs={6.5}>
          <Grid xs={4}>
            <HotelsSelect
              selectedClientId={selectedClientId}
              setHotelId={handeleDataFromHotelSelect}
              hotelsList={hotelsList}
              setHotelsList={setHotelsList}
            />
          </Grid>
          <Grid xs={4} style={{ marginTop: '33px' }}>
            <SaveHotelDepartmentDialog
              hotelDepartmentsList={hotelDepartmentsList}
              setHotelDepartmentsList={setHotelDepartmentsList}
              selectedHotelId={selectedHotelId}
            />
          </Grid>

          <Grid sx={{ mt: '10px' }}></Grid>
        </Grid>

        <Grid textAlign="right">
          <SaveDepartmentDialog hotelId={selectedHotelId} />
        </Grid>
        <Grid container marginTop="15px" minWidth="100%" direction="row" xs={5}>
          <Column sentiment="positive" />
          <Column sentiment="negavite" />
        </Grid>
      </Container>
    </>
  );
};

export default Index;
