import React, { useState } from 'react';

import axios from 'axios';
import { Grid, Typography, Container, Button } from '@mui/material';
const Index = () => {
  const [poccesedfeedbacks, setPoccesedfeedbacks] = useState('');

  const readUploadAudioFile = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    // await fetch(process.env.REACT_APP_BASE_API_URL+'/procceseFeedbackWords/upload-audio', {
    // method: 'POST',
    // body: formData,
    // }).then((res) => {
    // return res.json();
    // }).then((res) =>{
    //     console.log(res);
    // }).catch((e) =>
    // console.log(e)
    // );

    const uploadedAudioFile = await axios.post(
      process.env.REACT_APP_BASE_API_URL +
        '/procceseFeedbackWords/upload-audio',
      formData,
    );
    console.log(uploadedAudioFile.data);

    const res = await axios.post(
      process.env.REACT_APP_BASE_API_URL +
        '/procceseFeedbackWords/proccese-feedback-words',
      { fileName: uploadedAudioFile.data },
    );

    setPoccesedfeedbacks(res.data);

    //const feedbacks = await readFeedbacks(e);

    //let allFeedbacks = feedbacks.feedbacks;
    document.getElementById('audioFile').value = '';
  };

  // const readFeedbacks = (e) => {
  //     return new Promise((resolve, reject) => {
  //         let feedbacks= "";
  //         if (e.target.files) {
  //             const reader = new FileReader();
  //             reader.onload = (e) => {
  //                 const data = e.target.result;
  //                 const workbook = xlsx.read(data, { type: "array" });
  //                 const sheetName = workbook.SheetNames[0];
  //                 const worksheet = workbook.Sheets[sheetName];
  //                 const json = xlsx.utils.sheet_to_json(worksheet);

  //                 for(const row of json){
  //                     if(row.__rowNum__ > 1){
  //                         const feedback = row.__EMPTY_1;
  //                         feedbacks += " " + feedback;
  //                     }
  //                 }
  //                 resolve({feedbacks});
  //             };
  //             reader.readAsArrayBuffer(e.target.files[0]);
  //         }
  //         document.getElementById("uploadFeedbacks").value = "";
  //     })
  // }

  return (
    <React.Fragment>
      <Container>
        <Grid style={{ border: '1px solid #5FEFC0', wordWrap: 'break-word' }}>
          <Typography>
            <center>
              <h4>feedbacks</h4>
            </center>
          </Typography>
          <Typography>{poccesedfeedbacks}</Typography>
        </Grid>
        <Button
          variant="contained"
          style={{ textTransform: 'unset' }}
          component="label"
        >
          <input
            type="file"
            name="audioFile"
            id="audioFile"
            hidden
            onChange={readUploadAudioFile}
          />
          Upload Audio File
        </Button>
      </Container>
    </React.Fragment>
  );
};

export default Index;
