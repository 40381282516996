import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import logo from './../../assets/logo1.png';
import { types } from './../register/constants';

import PosNegTablesEn from '../adminPosNegTables/PosNegTablesEn';

import AllNotFoundSentencesEn from './AllNotFoundSentencesEn';
import SentencesPrefix from '../adminOptions1Question/sentencesPrefix';

import {
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from '@mui/material';

const Index = () => {
  const navigate = useNavigate();

  const [error, setError] = useState('');
  const [selectedType, setSelectedType] = useState('');

  const changeSelectType = async (e) => {
    setSelectedType(e.target.value);
    setError('');
  };

  return (
    <React.Fragment>
      <Container>
        <Box
          textAlign="center"
          style={{ backgroundColor: 'black' }}
          item
          xs={2}
          onClick={() => navigate('/user/dashboard')}
        >
          <img src={logo} alt="" style={{ cursor: 'pointer' }} />
        </Box>
        <center>
          <h2>English</h2>
          <Button onClick={() => navigate('/admin-options-main')}>
            Admin Main Menu
          </Button>
        </center>

        <center>
          <Typography style={{ color: 'red' }}>{error}</Typography>
        </center>
        <SentencesPrefix language="English" />
        <Grid item s={4} padding="10px">
          <Box maxWidth="300px">
            <FormControl fullWidth size="small">
              <InputLabel>Type</InputLabel>
              <Select
                id="typeSelect"
                onChange={changeSelectType}
                defaultValue=""
              >
                {types.map((type, index) => (
                  <MenuItem value={type} name={type} key={index}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <AllNotFoundSentencesEn selectedType={selectedType} storeVersion={1} />

        <Box container spacing={3} marginTop="5%" margin="50px">
          <Grid
            container
            direction="row"
            spacing={0}
            //display="-ms-flexbox"
            xs={12}
          >
            <PosNegTablesEn selectedType={selectedType} />
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Index;
