import { Grid, Container } from '@mui/material';
import React from 'react';
import Header from './header';
import GettingStarting from './gettingStarting';
import Feature from './feature';
// import Packges from './packges';
import Resources from './resources';

const Dashboard = () => {
  return (
    <React.Fragment>
      <Container sx={{ p: 3 }}>
        <Header />
        <GettingStarting />
        <Feature />
        {/* <Packges /> */}
        <Resources />
      </Container>
      <Grid item xs={12} sx={{ background: '#5fefc0', p: 4 }}>
        Copyright © 2022 VoiceBacked – All Rights Reserved. | Privacy Policy |
        Contact Us
      </Grid>
    </React.Fragment>
  );
};

export default Dashboard;
