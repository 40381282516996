import {
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as EmailValidator from 'email-validator';
import registerSide from './../../assets/registerSide.png';
import logo from './../../assets/logo.png';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import './index.css';
import { termsCondLink } from './constants';
import { types } from './constants';
import axios from 'axios';

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: '1px solid #69EFC8',
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#2b2b2b',
    // border: "1px solid #ced4da",
    border: 'none',
    fontSize: 16,
    // width: 'auto',
    width: '100%',
    padding: '15px 12px',
    // transition: theme.transitions.create([
    //   "border-color",
    //   "background-color",
    //   "box-shadow",
    // ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});
const Index = () => {
  const navigate = useNavigate();
  const [nameError, setNameError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [userAllreadyExists, setUserAllreadyExists] = useState('');
  const [typeError, setTypeError] = useState('');
  const [termsCondError, setTermsCondError] = useState('');
  // const [serverErrorText, setServerErrorText] = useState('');
  const [registerTypes, setRegisterTypes] = useState([]);

  useEffect(() => {
    window.addEventListener('keydown', pressMainBtn);

    const tempTypesArray = types.slice();
    tempTypesArray.splice(tempTypesArray.indexOf('General'), 1);
    setRegisterTypes([...tempTypesArray]);

    return () => {
      window.removeEventListener('keydown', pressMainBtn);
    };
  }, []);

  const pressMainBtn = (e) => {
    if (e.key === 'Enter') {
      btnRegisterClick();
    }
  };

  const handleChange = (e) => {};

  const btnRegisterClick = () => {
    const name = document.getElementById('name').value.trim();
    const company = document.getElementById('company').value.trim();
    const email = document.getElementById('email').value.trim();
    const password = document.getElementById('password').value.trim();
    const confirmPassword = document
      .getElementById('confirmPassword')
      .value.trim();
    const type = document.getElementById('typeSelect').textContent;
    const isTermsCondChecked =
      document.getElementById('termsCondCheckbox').checked;
    let isError = false;

    if (!name) {
      setNameError('Name is required');
      isError = true;
    } else {
      if (nameError) {
        setNameError('');
      }
    }

    if (!company) {
      setCompanyError('Company is required');
      isError = true;
    } else {
      if (companyError) {
        setCompanyError('');
      }
    }

    if (!email) {
      setEmailError('Email is required');
      isError = true;
    } else {
      const isEmailValid = EmailValidator.validate(email);
      if (!isEmailValid) {
        setEmailError('Email isn`t valid');
        isError = true;
      } else {
        if (emailError) {
          setEmailError('');
        }
      }
    }

    if (!password) {
      setPasswordError('Password is required');
      isError = true;
    } else {
      if (passwordError) {
        setPasswordError('');
      }
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm password is required');
      isError = true;
    } else {
      if (password !== confirmPassword) {
        setConfirmPasswordError(
          'Password and confirm field have to consist the same value',
        );
        isError = true;
      } else {
        if (confirmPasswordError) {
          setConfirmPasswordError('');
        }
      }
    }

    if (type.length <= 1) {
      setTypeError('Type is required');
      isError = true;
    } else {
      if (typeError) {
        setTypeError('');
      }
    }

    if (!isTermsCondChecked) {
      setTermsCondError(
        'Please, agree with Terms and Conditions and Privacy Policy to proceed the applications',
      );
      isError = true;
    } else {
      //if (termsCondError) {
      setTermsCondError('');
      //}
    }

    if (isError) {
      return;
    }

    const data = {
      name: name,
      company: company,
      email: email,
      password: password,
      typeName: type,
    };

    setUserAllreadyExists('');
    axios
      .post(process.env.REACT_APP_BASE_API_URL + `/users/signup`, data)
      .then((res) => {
        console.log('res', res);
        localStorage.setItem('userEmail', email);
        navigate('/register-email-send');
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setUserAllreadyExists(error.response.data.message);
        }
        console.log('register error', error);
      });
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={5} className="main-grid">
            <img src={registerSide} alt="" />
          </Grid>
          <Grid
            item
            md={7}
            className="classScroll"
            sx={{
              p: 6,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <img src={logo} alt="" />
              <Button
                variant="outlined"
                className="back-home"
                onClick={() => navigate('/')}
              >
                Go Back Home
              </Button>
            </Box>
            <Typography className="text" sx={{ lineHeight: '1' }}>
              Hi, Welcome
              <br /> Back!
            </Typography>
            <Typography sx={{ my: 3 }}>
              Have an account?<Link to="/login"> Login Here</Link>
            </Typography>
            <FormControl variant="standard" fullWidth>
              <InputLabel shrink htmlFor="bootstrap-input">
                Name*
              </InputLabel>
              <BootstrapInput fullWidth placeholder="Name" id="name" />
              <Typography sx={{ color: 'red' }}>{nameError}</Typography>
            </FormControl>
            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Email*
              </InputLabel>
              <BootstrapInput
                fullWidth
                type="email"
                placeholder="email@gmail.com"
                id="email"
              />
              <Typography sx={{ color: 'red' }}>
                {userAllreadyExists}
              </Typography>
              <Typography sx={{ color: 'red' }}>{emailError}</Typography>
            </FormControl>
            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Company Name*
              </InputLabel>
              <BootstrapInput fullWidth placeholder="Company" id="company" />
              <Typography sx={{ color: 'red' }}>{companyError}</Typography>
            </FormControl>
            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Password*
              </InputLabel>
              <BootstrapInput
                fullWidth
                type="password"
                placeholder="Password"
                id="password"
              />
              <Typography sx={{ color: 'red' }}>{passwordError}</Typography>
            </FormControl>
            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <InputLabel shrink htmlFor="bootstrap-input">
                Confirm Password*
              </InputLabel>
              <BootstrapInput
                fullWidth
                type="password"
                placeholder="Confirm password"
                id="confirmPassword"
              />
              <Typography sx={{ color: 'red' }}>
                {confirmPasswordError}
              </Typography>
            </FormControl>
            <FormControl sx={{ minWidth: '100%', mt: 2 }} variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                Type*
              </InputLabel>
              <Select
                onChange={handleChange}
                id="typeSelect"
                input={<BootstrapInput fullWidth />}
              >
                {registerTypes.map((type) => (
                  <MenuItem value={type} name={type}>
                    {type}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"fashion"} selected>Fashion</MenuItem>
                <MenuItem value={"restaurants"}>Restaurants/Coffee shops</MenuItem> */}
              </Select>
              <Typography sx={{ color: 'red' }}>{typeError}</Typography>
            </FormControl>
            <Box
              sx={{
                my: 2,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Checkbox
                sx={{
                  '&:hover': { bgcolor: 'transparent' },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                id="termsCondCheckbox"
                inputProps={{ 'aria-label': 'Checkbox demo' }}
                onChange={(event) => {}}
              />
              <Typography sx={{ my: 3 }}>
                By clicking "RESGISTER" I agree to the{' '}
                <a href={termsCondLink} target="blank">
                  Terms and Conditions and Privacy Policy
                </a>
              </Typography>
              <Typography sx={{ color: 'red' }}>{termsCondError}</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                className="go-back-login"
                sx={{
                  px: 10,
                  py: 1,
                }}
                onClick={btnRegisterClick}
              >
                {' '}
                Register
              </Button>
            </Box>
          </Grid>

          {/* <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
            <Typography sx={{ color: "red" }}>{userAllreadyExists}</Typography>
          </FormControl> */}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;
