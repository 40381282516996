import React, { useState, useEffect } from 'react';
import { Grid, Typography, Input, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

import RemoveSubjectDialog from './RemoveSubjectDialog';
import SubjectSentencesDialog from './SubjectSentencesDialog';

function PosNegTables(props) {
  const [positiveSubjectInput, setPositiveSubjectInput] = useState('');
  const positiveSubjectInputChange = (e) => {
    setPositiveSubjectInput(e.target.value);
  };
  const [negativeSubjectInput, setNegativeSubjectInput] = useState('');
  const negativeSubjectInputChange = (e) => {
    setNegativeSubjectInput(e.target.value);
  };

  const [openRemoveSubjectModal, setOpenRemoveSubjectModal] =
    React.useState(false);
  const handleClickOpenRemoveSubjectModal = () =>
    setOpenRemoveSubjectModal(true);
  const handleCloseRemoveSubjectModal = () => setOpenRemoveSubjectModal(false);

  const [openSubjectSentencesDialog, setOpenSubjectSentencesDialog] =
    React.useState(false);
  const handleClickOpenSubjectSentencesDialog = () =>
    setOpenSubjectSentencesDialog(true);
  const handleCloseSubjectSentencesDialog = () =>
    setOpenSubjectSentencesDialog(false);

  const [subjectToRemove, setSubjectToRemove] = useState('');
  const [subjectToRemoveSentiment, setSubjectToRemoveSentiment] = useState('');

  const [positiveSubjectsList, setPositiveSubjectsList] = useState([]);
  const [negativeSubjectsList, setNegativeSubjectsList] = useState([]);

  const [subjectSentences, setSubjectSentences] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedDirection, setSelectedDirection] = useState('');

  useEffect(() => {
    props.setSelectedDirection('');
    props.setSelectedSubject('');
    props.setSelectedQuestionNuber('');

    if (props.selectedType) {
      const getPositiveData = async () => {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            '/italianSubjects/subjects-by-type-and-question',
          {
            params: {
              type: props.selectedType,
              questionNumber: props.questionNumber,
              direction: 'positive',
            },
          },
        );
        setPositiveSubjectsList([...response.data]);
      };

      getPositiveData();

      const getNegativeData = async () => {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            '/italianSubjects/subjects-by-type-and-question',
          {
            params: {
              type: props.selectedType,
              questionNumber: props.questionNumber,
              direction: 'negative',
            },
          },
        );
        setNegativeSubjectsList([...response.data]);
      };

      getNegativeData();
    }
  }, [props.selectedType]);

  const positiveSubjectKeyDown = () => {};
  const negativeSubjectKeyDown = () => {};

  const addPositiveSubject = async (e) => {
    //setError("");

    if (props.selectedType.length > 1 && positiveSubjectInput.length > 0) {
      await axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/italianSubjects/add-subject',
          {
            name: positiveSubjectInput,
            type: props.selectedType,
            direction: 'positive',
            questionNumber: props.questionNumber,
          },
        )
        .then((res) => {
          const { message } = res.data;
          console.log(message);
          //setError(message);
          positiveSubjectsList.push(positiveSubjectInput);
          setPositiveSubjectsList([...positiveSubjectsList]);
          setPositiveSubjectInput('');
        })
        .catch((error) => {
          const { message } = error.response.data;
          console.log(message);
          //setError(message);
        });
    } else {
      //setError("Select Type");
    }
  };
  const addNegativeSubject = async () => {
    //setError("");

    if (props.selectedType.length > 1 && negativeSubjectInput.length > 0) {
      await axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/italianSubjects/add-subject',
          {
            name: negativeSubjectInput,
            type: props.selectedType,
            direction: 'negative',
            questionNumber: props.questionNumber,
          },
        )
        .then((res) => {
          const { message } = res.data;
          console.log(message);
          //setError(message);
          negativeSubjectsList.push(negativeSubjectInput);
          setNegativeSubjectsList([...negativeSubjectsList]);
          setNegativeSubjectInput('');
        })
        .catch((error) => {
          const { message } = error.response.data;
          console.log(message);
          //setError(message);
        });
    } else {
      //setError("Select Type");
    }
  };

  const deleteSubject = (subject, dir) => {
    setSubjectToRemove(subject);
    setSubjectToRemoveSentiment(dir);

    handleClickOpenRemoveSubjectModal();
  };

  const selectSubject = async (e, dir) => {
    setSelectedSubject(e.target.textContent);
    setSelectedDirection(dir);

    props.setSelectedDirection(dir);
    props.setSelectedSubject(e.target.textContent);
    props.setSelectedQuestionNuber(props.questionNumber);

    const res = await axios.get(
      process.env.REACT_APP_BASE_API_URL +
        '/italianSubjects/all-subject-sentences',
      {
        params: {
          name: e.target.textContent,
          direction: dir,
          questionNumber: props.questionNumber,
          type: props.selectedType,
        },
      },
    );
    setSubjectSentences(res.data);
    handleClickOpenSubjectSentencesDialog();
  };

  return (
    <Grid marginLeft="5%">
      <div style={{ padding: '20px' }}>
        <Typography>
          {props.selectedType} Question Number {props.questionNumber}{' '}
        </Typography>
      </div>
      <Grid
        //marginTop="50px"
        container
        //spacing={2}
        minWidth="100%"
        direction="row"
        xs={12}
      >
        <Grid minWidth="50%" style={{ border: '1px solid #5FEFC0' }} xs={5}>
          <h2>
            <center>Positive subjects</center>
          </h2>
          {positiveSubjectsList.map((subject, index) => {
            return (
              <div>
                <Button
                  id={'pos' + index}
                  value={subject}
                  name={subject}
                  onClick={(e) => selectSubject(e, 'positive')}
                  style={{ textTransform: 'unset' }}
                >
                  {subject}
                </Button>
                <IconButton
                  onClick={() => deleteSubject(subject, 'positive')}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          })}
          <center>
            <Input
              value={positiveSubjectInput}
              style={{ backgroundColor: '#BDDAFF' }}
              id="positiveSubjectInput"
              onChange={positiveSubjectInputChange}
              onKeyDown={positiveSubjectKeyDown}
            ></Input>
            <Button
              style={{ textTransform: 'unset', margin: '4px' }}
              size="small"
              variant="outlined"
              onClick={addPositiveSubject}
            >
              Add Posivite Subject
            </Button>
          </center>
        </Grid>
        <Grid minWidth="50%" style={{ border: '1px solid #5FEFC0' }} xs={5}>
          <h2>
            <center>Negative subjects</center>
          </h2>
          {negativeSubjectsList.map((subject, index) => {
            return (
              <div>
                <Button
                  id={'neg' + index}
                  value={subject}
                  name={subject}
                  onClick={(e) => selectSubject(e, 'negative')}
                  style={{ textTransform: 'unset' }}
                >
                  {subject}
                </Button>
                <IconButton
                  onClick={() => deleteSubject(subject, 'negative')}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            );
          })}
          <center>
            <Input
              value={negativeSubjectInput}
              style={{ backgroundColor: '#BDDAFF' }}
              id="negativeSubjectInput"
              onChange={negativeSubjectInputChange}
              onKeyDown={negativeSubjectKeyDown}
            ></Input>
            <Button
              style={{ textTransform: 'unset', margin: '4px' }}
              size="small"
              variant="outlined"
              onClick={addNegativeSubject}
            >
              Add Negative Subject
            </Button>
          </center>
        </Grid>
        <RemoveSubjectDialog
          openRemoveSubjectModal={openRemoveSubjectModal}
          handleCloseRemoveSubjectModal={handleCloseRemoveSubjectModal}
          subjectToRemove={subjectToRemove}
          subjectSentiment={subjectToRemoveSentiment}
          selectedType={props.selectedType}
          questionNumber={props.questionNumber}
          positiveSubjectsList={positiveSubjectsList}
          setPositiveSubjectsList={setPositiveSubjectsList}
          negativeSubjectsList={negativeSubjectsList}
          setNegativeSubjectsList={setNegativeSubjectsList}
        />
        <SubjectSentencesDialog
          openSubjectSentencesDialog={openSubjectSentencesDialog}
          handleCloseSubjectSentencesDialog={handleCloseSubjectSentencesDialog}
          subjectSentences={subjectSentences}
          selectedType={props.selectedType}
          questionNumber={props.questionNumber}
          selectedSubject={selectedSubject}
          selectedDirection={selectedDirection}
        />
      </Grid>
    </Grid>
  );
}

export default PosNegTables;
