import React from "react";
import ThenkYouCompotenet from "../../components/thenkYou";

const ThenkYou = () => {
  return (
    <React.Fragment>
      <ThenkYouCompotenet />
    </React.Fragment>
  );
};

export default ThenkYou;