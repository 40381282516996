import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Input } from '@mui/material';

import DepartmentsSelect from '../selects/departmentsSelect';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SaveSubjectDialog(props) {
  const { sentiment, subjectList, setSubjectList } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addSubject = async function () {
    const name = document.getElementById('subjectName').value.trim();
    const res = await axios
      .post(process.env.REACT_APP_BASE_API_URL + '/hotels/add-subject', {
        name,
        sentiment,
        department: selectedDepartment,
        sentences: [],
      })
      .catch((e) => {
        if (e.response.status === 409 || e.response.status === 204) {
          console.log(e.response.data.message);
          // return e.response.data.message;
        }
      });

    if (res) {
      subjectList.push(res.data);
      setSubjectList([...subjectList]);
      // console.log(res.data.message);
    }
    handleClose();
  };

  const [selectedDepartment, setSelectedDepartment] = React.useState('');
  const [departmentsList, setDepartmentsList] = React.useState([]);
  React.useEffect(() => {
    const getDepartments = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/hotels/get-departments')
        .catch((e) => {
          if (e.response.status === 404) {
            console.log(e.response.data.message);
            // return e.response.data.message;
          }
        });
      if (res) {
        // console.log(res.data);
        setDepartmentsList(res.data);
      }
    };

    getDepartments();
  }, []);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ textTransform: 'unset', margin: '4px' }}
        size="small"
        // disabled={disabledAddSubjectButton}
      >
        Add {sentiment} Subject
      </Button>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Add {sentiment} Subject
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DepartmentsSelect
            departmentsList={departmentsList}
            setSelectedDepartment={setSelectedDepartment}
          />
          <Input id="subjectName" dir="rtl" placeholder="Enter Subject" />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={addSubject}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
