import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import logo from './../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import './index.css';
const Header = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Box sx={{ height: '120px' }}>
        <Grid container>
          <Grid item xs={3}>
            <img src={logo} alt="" />
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              mt: 2,
              cursor: 'pointer',
            }}
          >
            <Typography onClick={() => navigate('/')}>Home</Typography>
            <a
              href="#feature"
              style={{ color: 'black', textDecoration: 'none' }}
            >
              {' '}
              <Typography>Features</Typography>
            </a>
            <a
              href="#pricing"
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <Typography>Pricing</Typography>
            </a>
            <a
              href="#resource"
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <Typography>Resources</Typography>
            </a>
          </Grid>

          <Grid
            item
            xs={4}
            sx={{ justifyContent: 'space-around', display: 'flex' }}
          >
            <Button
              variant="outlined"
              className="header-button"
              sx={{
                px: 8,
              }}
              onClick={() => navigate('/login')}
            >
              Login
            </Button>
            <Button
              variant="contained"
              className="header-button2"
              sx={{
                px: 8,
              }}
              onClick={() => navigate('/register')}
            >
              Register
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Header;
