import React from "react";
import VerifyEmail from "../../components/registerVerifyEmail/index";
const Index = () => {
  return (
    <React.Fragment>
      <VerifyEmail />
    </React.Fragment>
  );
};

export default Index;
