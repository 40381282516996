import {
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Container,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import registerSide from './../../assets/registerSide.png';
import logo from './../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import './index.css';
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#2b2b2b',
    // border: "1px solid #ced4da",
    border: 'none',
    fontSize: 16,
    padding: '15px 12px',
    width: '100%',
    // transition: theme.transitions.create([
    //   "border-color",
    //   "background-color",
    //   "box-shadow",
    // ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },
  },
}));

const Index = () => {
  const navigate = useNavigate();
  const [tempPasswordError, setTempPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  useEffect(() => {
    window.addEventListener('keyup', pressMainBtn);
    return () => {
      window.removeEventListener('keyup', pressMainBtn);
    };
  }, []);

  const pressMainBtn = (e) => {
    if (e.code === 'Enter') {
      handleResetEmail();
    }
  };

  const handleResetEmail = () => {
    const tempPassword = document.getElementById('tempPassword').value.trim();
    const password = document.getElementById('password').value.trim();
    const confirmPassword = document
      .getElementById('confirmPassword')
      .value.trim();
    let isError = false;

    if (!tempPassword) {
      setTempPasswordError('Temporary password is required');
      isError = true;
    } else {
      if (tempPasswordError) {
        setTempPasswordError('');
      }
    }

    if (!password) {
      setPasswordError('Password is required');
      isError = true;
    } else {
      if (passwordError) {
        setPasswordError('');
      }
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm password is required');
      isError = true;
    } else {
      if (password !== confirmPassword) {
        setConfirmPasswordError(
          'Password and confirm field have to consist the same value',
        );
        isError = true;
      } else {
        if (confirmPasswordError) {
          setConfirmPasswordError('');
        }
      }
    }

    if (isError) {
      return;
    }

    const data = {
      tempPassword: tempPassword,
      password: password,
    };

    axios
      .post(process.env.REACT_APP_BASE_API_URL + `/users/reset-password`, data)
      .then((res) => {
        navigate('/register-email-verify');
      })
      .catch((error) => console.log('register error', error));
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid
            item
            md={5}
            style={{ background: '#5FEFC0', width: '100%', height: '110vh' }}
          >
            <img src={registerSide} alt="" />
          </Grid>
          <Grid
            item
            md={7}
            className="ScrollMenu"
            sx={{
              p: 6,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <img src={logo} alt="" />
              <Button
                variant="outlined"
                className="go-back"
                onClick={() => navigate('/')}
              >
                Go Back Home
              </Button>
            </Box>
            <Box className="box1">
              <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Temp password*
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  type="password"
                  placeholder="Password"
                  id="tempPassword"
                />
                <Typography sx={{ color: 'red' }}>
                  {tempPasswordError}
                </Typography>
              </FormControl>
              <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Password*
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  type="password"
                  placeholder="Password"
                  id="password"
                />
                <Typography sx={{ color: 'red' }}>{passwordError}</Typography>
              </FormControl>
              <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
                <InputLabel shrink htmlFor="bootstrap-input">
                  Confirm Password*
                </InputLabel>
                <BootstrapInput
                  fullWidth
                  type="password"
                  placeholder="Confirm password"
                  id="confirmPassword"
                />
                <Typography sx={{ color: 'red' }}>
                  {confirmPasswordError}
                </Typography>
              </FormControl>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                className="button1"
                sx={{
                  py: 2,
                  px: 12,
                  my: 6,
                }}
                onClick={handleResetEmail}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;
