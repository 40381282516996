import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { Input } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SaveDepartmentDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [departmentsList, setDepartmentsList] = React.useState([]);
  React.useEffect(() => {
    const getDepartments = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/hotels/get-departments')
        .catch((e) => {
          if (e.response.status === 404) {
            console.log(e.response.data.message);
            // return e.response.data.message;
          }
        });
      if (res) {
        // console.log(res.data);
        setDepartmentsList(res.data);
      }
    };

    getDepartments();
  }, []);

  // const [disabledAddDepartmentsButton, setDisabledAddDepartmentsButton] =
  //   React.useState(true);
  // React.useEffect(() => {
  //   if (!hotelId) {
  //     setDisabledAddDepartmentsButton(true);
  //   } else {
  //     setDisabledAddDepartmentsButton(false);
  //   }
  // }, [hotelId]);

  const saveDepartment = function () {
    const name = document.getElementById('departmentName').value.trim();

    axios
      .post(process.env.REACT_APP_BASE_API_URL + '/hotels/save-department', {
        name,
      })

      .catch((e) => {
        console.log(e);
      });

    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ textTransform: 'unset' }}
        size="small"
        // disabled={disabledAddDepartmentsButton}
      >
        Add Department
      </Button>

      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Add Department
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {departmentsList.map((dep) => {
            return (
              <Typography value={dep._id} name={dep.name} key={dep._id}>
                {dep.name}
              </Typography>
            );
          })}
          <Input
            id="departmentName"
            fullWidth
            placeholder="Enter New Department Name"
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={saveDepartment}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
