import React from 'react';
import { Container, Button, Dialog } from '@mui/material';

import axios from 'axios';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function RemoveSubjectEnDialog(props) {
  const {
    closeDeleteSubjectDialog,
    openDeleteSubjectDialog,
    subjectToRemove,
    positiveSubjectsList,
    setPositiveSubjectsList,
    negativeSubjectsList,
    setNegativeSubjectsList,
  } = props;
  const deleteSubject = async () => {
    await axios
      .delete(
        process.env.REACT_APP_BASE_API_URL + '/subjects-en/delete-subject-en',
        {
          params: {
            subject: subjectToRemove,
          },
        },
      )
      .catch((error) => {
        if (error.response.data) {
          const { message } = error.response.data;
          console.log(message);
        } else {
          console.log(error);
        }
      });

    if (subjectToRemove.sentiment === 'positive') {
      const positiveList = positiveSubjectsList.filter(
        (subject) => subject._id !== subjectToRemove._id,
      );
      setPositiveSubjectsList([...positiveList]);
    } else {
      //deleteSubject.sentiment === negative
      const negativeList = negativeSubjectsList.filter(
        (subject) => subject._id !== subjectToRemove._id,
      );
      setNegativeSubjectsList([...negativeList]);
    }

    closeDeleteSubjectDialog();
  };

  return (
    <Container>
      <Dialog
        open={openDeleteSubjectDialog}
        onClose={closeDeleteSubjectDialog}
        // buttonIndex={buttonIndex}
        // buttonDir={buttonDir}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Remove Subject'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You about to remove a subject are you sure you want to remove this
            subject?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteSubject}>Remove</Button>
          <Button onClick={closeDeleteSubjectDialog} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default RemoveSubjectEnDialog;
