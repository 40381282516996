import { Box, Typography } from "@mui/material";
import { Bar } from "react-chartjs-2";
import React from "react";

export const options = {};

const Index = (props) => {
  const { question, positiveSubjects, negativeSubjects } = props;

  const getChartsData = function () {
    const data = [];
    const labels = [];
    const positiveData = [];
    const negativeData = [];

    const maxLength = Math.max(
      positiveSubjects.length,
      negativeSubjects.length
    );

    for (let i = 0; i < maxLength; i++) {
      let nSubject = null;
      // find the same subject name (positiveSubjects[i].subject) in negativeSubjects
      // in case that we have the same subject name we push positive AND negavive count
      // to data array (useed for open questions Q1 and Q4)
      if (positiveSubjects[i]) {
        nSubject = negativeSubjects.find(
          (nSubject) => nSubject.subject === positiveSubjects[i].subject
        );
      }
      if (nSubject) {
        data.push({
          label: positiveSubjects[i].subject,
          pCount: positiveSubjects[i].count,
          nCount: nSubject.count,
        });
      } else {
        // in case that we have only positive subject we push only positive subjet count
        if (positiveSubjects[i]) {
          data.push({
            label: positiveSubjects[i].subject,
            pCount: positiveSubjects[i].count,
            nCount: 0,
          });
        }
        // in case that we have only negative subject we push only negative subjet count
        if (negativeSubjects[i]) {
          data.push({
            label: negativeSubjects[i].subject,
            pCount: 0,
            nCount: negativeSubjects[i].count,
          });
        }
      }
    }

    // cteate labels array, positiveData array and negativeData array
    for (let i = 0; i < data.length; i++) {
      labels.push(data[i].label);
      positiveData.push(data[i].pCount);
      negativeData.push(data[i].nCount);
    }

    // return charts data
    return {
      labels,
      width: 1,
      datasets: [
        {
          label: "Positive",
          data: positiveData,
          backgroundColor: "#5FEFC0",
          barThickness: "10",
          borderRadius: "5",
        },
        {
          label: "Negative",
          data: negativeData,
          backgroundColor: "#FF9C90",
          barThickness: "10",
          borderRadius: "5",
        },
      ],
    };
  };

  const data = getChartsData();
  return (
    <React.Fragment>
      <Typography dir="rtl" sx={{ fontSize: "14px", fontWeight: "bold" }}>
        {question}
      </Typography>
      <Box sx={{ height: "100%" }} backgroundColor="#FFFFFF">
        <Bar options={options} data={data} height="100px" />
      </Box>
    </React.Fragment>
  );
};

export default Index;
