import React from "react";
import DashboardComponent from "../../components/dashboard";
const Dashboard = () => {
  return (
    <React.Fragment>
      <DashboardComponent />
    </React.Fragment>
  );
};

export default Dashboard;
