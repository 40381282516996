import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import axios from 'axios';
import xlsx from 'xlsx';
import { types } from '../../register/constants';

import {
  TextField,
  Box,
  Typography,
  Modal,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@mui/material';
import { languages } from '../constants';

export default function BasicModal(props) {
  const { clientId, setStoresList, storesList, version } = props;
  const [disableButton, setDisableButton] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState('');
  const [language, setLanguage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [foreignStoreId, setForeignStoreId] = React.useState(0);
  // const [version, setVersion] = React.useState(4);
  // const [storesList, setStoresList] = useState([]);
  React.useEffect(() => {
    if (clientId) {
      setDisableButton(false);
    }
  }, [clientId]);

  const saveStoreToDb = async () => {
    setErrorMessage('');
    const storeName = document.getElementById('storeName').value.trim();
    if (storeName.length <= 0) {
      setErrorMessage('Upload store excel');
      return null;
    }

    if (type.length <= 0) {
      setErrorMessage('Select store type');
      return null;
    }
    const question1 = document.getElementById('question1').value.trim();
    let question2 = '';
    let question3 = '';
    let question4 = '';
    if (version === 4) {
      question2 = document.getElementById('question2').value.trim();
      question3 = document.getElementById('question3').value.trim();
      question4 = document.getElementById('question4').value.trim();
    }

    const questions = [question1, question2, question3, question4];

    const storeDetails = {
      storeName,
      type,
      language,
      version,
      foreignStoreId,
      questions,
    };

    const addedStore = await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + '/stores/add-store-to-user-id',
        {
          storeDetails,
          clientId,
        },
      )
      .catch((error) => {
        setErrorMessage('Store name already exists');
        console.log(error);
      });
    if (addedStore) {
      storesList.push(addedStore.data);
      setStoresList([...storesList]);
      setOpen(false);
    }
  };

  const readStoreDetalis = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    const storeNameForeignId = await readStoreNameForeignId(e);

    document.getElementById('storeName').value = storeNameForeignId.storeName;
    setForeignStoreId(storeNameForeignId.foreignStoreId);
  };

  const readStoreNameForeignId = async (e) => {
    // setErrorMessage("");
    return new Promise((resolve, reject) => {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);

          // const version = Object.values(json[0]).includes('Q4 Text') ? 4 : 1;
          const storeDetailsArray = Object.keys(json[0])[0].split(' ');
          // get full store name including spaces
          let storeName = '';
          // start from 3rd element // ['Store', 'ID=31', 'Name=GN', 'rannana', '', 'Reviews', 'count=21']
          // and get name until the word Reviews
          for (let i = 2; i < storeDetailsArray.length; i++) {
            if (storeDetailsArray[i] !== 'Reviews') {
              storeName += storeDetailsArray[i] + ' ';
            } else {
              break;
            }
          }
          // after the loop we stay with Name=GN rannana so we remove Name=
          storeName = storeName.split('=')[1];

          const foreignStoreId = storeDetailsArray[1].split('=')[1];

          resolve({ storeName, foreignStoreId });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      } else {
        reject({});
      }
    });
  };

  const slectedType = (e) => {
    // console.log(e.target.value);
    setType(e.target.value);
  };
  const slectedlanguage = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 1, cursor: 'pointer' }}>
        <Button
          style={{ textTransform: 'unset', color: 'black' }}
          variant="outlined"
          sx={{ cursor: 'pointer', background: '#5fefc0', width: '130px' }}
          disabled={disableButton}
          onClick={() => setOpen(true)}
        >
          + Add Store
        </Button>
      </Box>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Sheet
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
              bgcolor: '#F5F5F5',
              textAlign: 'center',
            }}
          >
            <Typography>Add new store (Branch) name</Typography>
            <div style={{ padding: '5px', color: 'red', fontWeight: 'bold' }}>
              {errorMessage}
            </div>
            <div>
              <Button
                variant="outlined"
                style={{ textTransform: 'unset' }}
                sx={{
                  color: 'black',
                  cursor: 'pointer',
                  background: '#5fefc0',
                }}
                component="label"
              >
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  hidden
                  onChange={readStoreDetalis}
                />
                Upload Excel
              </Button>
              <TextField
                id="storeName"
                size="small"
                placeholder="Store Name"
                disabled
                maxWidth="300px"
              />
            </div>
            {version === 1 ? (
              <div>
                <TextField
                  id="question1"
                  size="small"
                  label="Question 1"
                  maxWidth="300px"
                />
              </div>
            ) : (
              <div>
                <TextField
                  id="question1"
                  size="small"
                  label="Question 1"
                  maxWidth="300px"
                />
                <TextField
                  id="question2"
                  size="small"
                  label="Question 2"
                  maxWidth="300px"
                />
                <TextField
                  id="question3"
                  size="small"
                  label="Question 3"
                  maxWidth="300px"
                />
                <TextField
                  id="question4"
                  size="small"
                  label="Question 4"
                  maxWidth="300px"
                />
              </div>
            )}
            <Box maxWidth="300px" paddingTop="10px">
              <FormControl fullWidth size="small">
                <InputLabel>Store type</InputLabel>
                <Select onChange={slectedType}>
                  {types.map((type, index) => (
                    <MenuItem key={index} value={type} name={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box maxWidth="300px" paddingTop="10px">
              <FormControl fullWidth size="small">
                <InputLabel>language</InputLabel>
                <Select onChange={slectedlanguage}>
                  {languages.map((language, inedx) => (
                    <MenuItem key={inedx} value={language} name={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Grid container direction="row">
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                variant="outlined"
                sx={{ cursor: 'pointer', background: '#5fefc0' }}
                onClick={saveStoreToDb}
              >
                Save
              </Button>
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                variant="outlined"
                sx={{ cursor: 'pointer', background: '#5fefc0' }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Sheet>
        </div>
      </Modal>
    </React.Fragment>
  );
}
