import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import axios from 'axios';

import {
  Box,
  Typography,
  Modal,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@mui/material';

export default function BasicModal(props) {
  const { storesList, setStoresList } = props;
  const [open, setOpen] = React.useState(false);
  const [storeId, setStoreId] = React.useState('');

  const [errorMessage, setErrorMessage] = React.useState('');

  const deleteStore = async function () {
    setErrorMessage('');
    if (storeId) {
      await axios.delete(
        process.env.REACT_APP_BASE_API_URL + '/stores/delete-store',
        { params: { storeId } },
      );
      const deletedStoreArray = storesList.filter(
        (store) => store._id !== storeId,
      );

      setStoresList([...deletedStoreArray]);
    } else {
      setErrorMessage('Select Store');
    }
    setOpen(false);
  };
  const openDeleteStore = () => {
    setOpen(true);
  };
  const selectedStoreId = (event) => {
    setStoreId(event.target.value);
  };
  return (
    <React.Fragment>
      <Box sx={{ p: 1, cursor: 'pointer' }}>
        <Button
          style={{ textTransform: 'unset', color: 'black' }}
          variant="outlined"
          sx={{ cursor: 'pointer', background: '#5fefc0', width: '130px' }}
          // onClick={handleOpen}

          onClick={openDeleteStore}
        >
          - Delete Store
        </Button>
      </Box>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Sheet
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
              bgcolor: '#F5F5F5',
              textAlign: 'center',
            }}
          >
            <Typography>Delete store</Typography>
            <div style={{ padding: '5px', color: 'red', fontWeight: 'bold' }}>
              {errorMessage}
            </div>
            <Box maxWidth="300px" paddingTop="10px">
              <FormControl fullWidth size="small">
                <InputLabel>Select Sotre</InputLabel>
                <Select onChange={selectedStoreId} defaultValue="">
                  {storesList.map((store) => (
                    <MenuItem
                      value={store._id}
                      key={store._id}
                      name={store.name}
                    >
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Grid container direction="row">
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                variant="outlined"
                sx={{ cursor: 'pointer', background: '#5fefc0' }}
                onClick={deleteStore}
              >
                Delete
              </Button>
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                variant="outlined"
                sx={{ cursor: 'pointer', background: '#5fefc0' }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Sheet>
        </div>
      </Modal>
    </React.Fragment>
  );
}
