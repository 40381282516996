import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Box,
  Typography,
  //   Card,
  //   Container,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  //   Divider,
  //   Menu,
  //   Paper,
  Input,
  IconButton,
  //   Button,
  //   IconButton,
  //   Dialog,
  //   ListItemText,
  //   ListItem,
  //   List,
  //   AppBar,
  //   Toolbar,
  //   Slide,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

function AllNotFoundSentencesEn(props) {
  const { selectedType } = props;
  const [sentences, setSentences] = useState([]);
  useEffect(() => {
    const getSentencesData = async () => {
      const notFoundSentences = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/reviews/not-found-sentences',
        { params: { language: 'English' } },
      );
      setSentences(notFoundSentences.data);
    };
    getSentencesData();
  }, []);

  const [error, setError] = useState('');

  const [positiveSubjects, setPositiveSubjects] = useState([]);
  const [negativeSubjects, setNegativeSubjects] = useState([]);
  useEffect(() => {
    setError('');
    if (selectedType) {
      const getSubjectsData = async (sentiment) => {
        const response = await axios.get(
          process.env.REACT_APP_BASE_API_URL +
            '/subjects-en/subjects-en-by-sentiment',
          {
            params: {
              type: selectedType,
              sentiment,
            },
          },
        );
        if (sentiment === 'positive') {
          setPositiveSubjects([...response.data]);
        } else {
          setNegativeSubjects([...response.data]);
        }
      };
      getSubjectsData('positive');
      getSubjectsData('negative');
    } else {
      setError('select type');
    }
  }, [selectedType]);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const selectSubject = (event) => {
    setSelectedSubject(event.target.value);
  };
  const [sentiment, setSentiment] = useState('positive');
  const [positiveSelected, setPositiveSelected] = useState(true);
  const handleChange = (event) => {
    const sentiment = event.target.value;

    setSentiment(sentiment);
    setPositiveSelected(sentiment === 'positive');
    // if (sentiment === 'positive') {
    //   setPositiveSelected(true);
    // } else {
    //   setPositiveSelected(false);
    // }
  };

  const deleteSentence = async (sentence, index) => {
    await axios.delete(
      process.env.REACT_APP_BASE_API_URL + '/reviews/delete-sentence',
      {
        params: {
          id: sentence._id,
        },
      },
    );
    sentences.splice(index, 1);
    setSentences([...sentences]);
  };

  const saveSentence = async (sentence) => {
    const newSentence = sentence;
    const sentenceText = document.getElementById(sentence._id).value;
    if (selectedSubject) {
      newSentence.text = sentenceText;
      newSentence.subject = selectedSubject.name;
      newSentence.sentiment = selectedSubject.sentiment;
      newSentence.questionNumber = 1;
      newSentence.language = 'English';

      await axios
        .post(process.env.REACT_APP_BASE_API_URL + '/reviews/save-sentence', {
          sentence: newSentence,
          subjectId: selectedSubject._id,
        })
        .then(deleteSentenceFromUi(sentence));
    }
  };

  const deleteSentenceFromUi = (sentence) => {
    const updatedSentences = sentences.filter(
      (senten) => senten._id !== sentence._id,
    );
    setSentences([...updatedSentences]);
  };

  return (
    <Grid Container>
      <h3 style={{ color: 'red' }}>{error}</h3>
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">
          Pick Subject Sentiment
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="positive"
          value={sentiment}
          onChange={handleChange}
        >
          <FormControlLabel
            value="positive"
            control={<Radio />}
            label="Positive"
          />
          <FormControlLabel
            value="negative"
            control={<Radio />}
            label="Negative"
          />
        </RadioGroup>
      </FormControl>
      {positiveSelected ? (
        <Box maxWidth="300px" paddingTop="10px">
          <FormControl fullWidth size="small">
            <InputLabel>Positive Subjects</InputLabel>
            <Select onChange={selectSubject} defaultValue="">
              {positiveSubjects.map((subject) => (
                <MenuItem value={subject} name={subject.name} key={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Box maxWidth="300px" paddingTop="10px">
          <FormControl fullWidth size="small">
            <InputLabel>Negative Subjects</InputLabel>
            <Select onChange={selectSubject} defaultValue="">
              {negativeSubjects.map((subject) => (
                <MenuItem value={subject} name={subject.name} key={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Typography>Sentences that were not found</Typography>
      <Grid
        item
        xs={6}
        dir="rtl"
        sx={{
          // py: 3,
          // px: 2,
          // minHeight: 300,
          //Width: 400,
          border: '1px solid',
          overflow: 'auto',
        }}
      >
        {sentences.map((sentence, index) => (
          <Grid key={sentence._id}>
            <IconButton
              aria-label="delete"
              onClick={() => deleteSentence(sentence, index)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="save"
              onClick={() => saveSentence(sentence)}
            >
              <SaveIcon />
            </IconButton>
            <Input
              id={sentence._id}
              dir="rtl"
              sx={{ p: 1, minWidth: `${sentence.text.length * 8 + 10}px` }}
              defaultValue={sentence.text}
              // color="#FFFFFF"
            ></Input>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default AllNotFoundSentencesEn;
