import { Button, Grid, Box, Typography, Container } from "@mui/material";
import React, { useState, useEffect } from "react";
import registerSide from "./../../assets/registerSide.png";
import logo from "./../../assets/logo.png";
import message from "./../../assets/message.png";
import { useNavigate } from "react-router-dom";

import "./index.css";

const Index = () => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    if (!userEmail) {
      navigate(-1);
    }
    setUserEmail(userEmail);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item md={5} className="main-grid ">
            <img src={registerSide} alt="" />
          </Grid>
          <Grid
            item
            md={7}
            className="ScrollMenu"
            sx={{
              p: 6,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <img src={logo} alt="" />
              <Button
                variant="outlined"
                className="back-home"
                onClick={() => navigate("/")}
              >
                Go Back Home
              </Button>
            </Box>
            <Box className="main-box">
              <div className="image-box ">
                <img src={message} alt="" />
              </div>
            </Box>
            <Typography
              className="email-sent"
              sx={{
                my: 2,
              }}
            >
              Email sent
            </Typography>
            <Typography className="reset-instruction">
              Verification url has been sent to {userEmail} (make sure to check your junk mail)
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Index;
