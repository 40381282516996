import React from "react";
import SpeechToTextSignin from "../../components/speechToTextSignin/index";
const Index = () => {
  return (
    <React.Fragment>
      <SpeechToTextSignin />
    </React.Fragment>
  );
};

export default Index;