import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Box,
  Typography,
  //   Card,
  //   Container,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  //   Divider,
  //   Menu,
  //   Paper,
  Input,
  IconButton,
  //   Button,
  //   IconButton,
  //   Dialog,
  //   ListItemText,
  //   ListItem,
  //   List,
  //   AppBar,
  //   Toolbar,
  //   Slide,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

function AllNotFoundSentences(props) {
  const { storeVersion } = props;
  const [sentences, setSentences] = useState([]);
  useEffect(() => {
    const getSentencesData = async () => {
      const notFoundSentences = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/reviews/not-found-sentences',
        { params: { language: 'Hebrew' } },
      );
      setSentences(notFoundSentences.data);
    };
    getSentencesData();
  }, []);

  const [positiveSubjects, setPositiveSubjects] = useState([]);
  const [negativeSubjects, setNegativeSubjects] = useState([]);
  useEffect(() => {
    const getSubjects = async () => {
      const subjects = await axios.get(
        process.env.REACT_APP_BASE_API_URL +
          '/italianSubjects/all-subjects-by-sentiment-and-question-number',
        { params: { questionNumber: storeVersion } },
      );
      setPositiveSubjects(subjects.data.pSubjects);
      setNegativeSubjects(subjects.data.nSubjects);
    };
    getSubjects();
  }, []);

  const [selectedSubject, setSelectedSubject] = useState(null);
  const selectSubject = (event) => {
    setSelectedSubject(event.target.value);
  };
  const [sentiment, setSentiment] = useState('positive');
  const [isPositiveRadioSelected, setIsPositiveRadioSelected] = useState(true);
  const handleChange = (event) => {
    setSentiment(event.target.value);
    if (event.target.value === 'positive') {
      setIsPositiveRadioSelected(true);
    } else {
      setIsPositiveRadioSelected(false);
    }
  };

  const deleteSentence = async (sentence) => {
    await axios.delete(
      process.env.REACT_APP_BASE_API_URL + '/reviews/delete-sentence',
      {
        params: {
          id: sentence._id,
        },
      },
    );
    deleteSentenceFromUi(sentence);
  };

  const saveSentence = async (sentence) => {
    const newSentence = sentence;
    const sentenceText = document.getElementById(sentence._id).value;
    if (selectedSubject) {
      newSentence.text = sentenceText;
      newSentence.subject = selectedSubject.name;
      newSentence.sentiment = selectedSubject.direction;
      newSentence.questionNumber = selectedSubject.questionNumber;
      newSentence.language = 'Hebrew';

      await axios
        .post(process.env.REACT_APP_BASE_API_URL + '/reviews/save-sentence', {
          sentence: newSentence,
          subjectId: selectedSubject._id,
        })
        .then(deleteSentenceFromUi(sentence));
    }
  };

  const deleteSentenceFromUi = (sentence) => {
    const updatedSentences = sentences.filter(
      (senten) => senten._id !== sentence._id,
    );
    setSentences([...updatedSentences]);
  };

  return (
    <Grid Container>
      <FormControl>
        <FormLabel id="demo-controlled-radio-buttons-group">
          Pick Subject Sentiment
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          defaultValue="positive"
          value={sentiment}
          onChange={handleChange}
        >
          <FormControlLabel
            value="positive"
            control={<Radio />}
            label="Positive"
          />
          <FormControlLabel
            value="negative"
            control={<Radio />}
            label="Negative"
          />
        </RadioGroup>
      </FormControl>
      {isPositiveRadioSelected ? (
        <Box maxWidth="300px" paddingTop="10px">
          <FormControl fullWidth size="small">
            <InputLabel>Positive Subjects</InputLabel>
            <Select onChange={selectSubject}>
              {positiveSubjects.map((subject) => (
                <MenuItem value={subject} name={subject.name} key={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Box maxWidth="300px" paddingTop="10px">
          <FormControl fullWidth size="small">
            <InputLabel>Negative Subjects</InputLabel>
            <Select onChange={selectSubject}>
              {negativeSubjects.map((subject) => (
                <MenuItem value={subject} name={subject.name} key={subject._id}>
                  {subject.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Typography>Sentences that were not found</Typography>
      <Grid
        item
        xs={6}
        dir="rtl"
        sx={{
          // py: 3,
          // px: 2,
          // minHeight: 300,
          //Width: 400,
          border: '1px solid',
          overflow: 'auto',
        }}
      >
        {sentences.map((sentence) => (
          <Grid key={sentence._id}>
            <IconButton
              aria-label="delete"
              onClick={() => deleteSentence(sentence)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              aria-label="save"
              onClick={() => saveSentence(sentence)}
            >
              <SaveIcon />
            </IconButton>
            <Input
              id={sentence._id}
              dir="rtl"
              sx={{ p: 1, minWidth: `${sentence.text.length * 8 + 10}px` }}
              defaultValue={sentence.text}
              // color="#FFFFFF"
            ></Input>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default AllNotFoundSentences;
