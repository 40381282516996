import React from 'react';
import AdminOpitons4questionCompotenet from '../../components/adminOptions4Question';

const AdminOptionis4Questions = () => {
  return (
    <React.Fragment>
      <AdminOpitons4questionCompotenet />
    </React.Fragment>
  );
};

export default AdminOptionis4Questions;
