import React from 'react';
import { getTokenOrRefresh } from './token_util';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@material-ui/core/Button';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@material-ui/core/IconButton';
import MicIcon from '@material-ui/icons/Mic';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../../assets/logo.png';
import { TextField } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import FormData from 'form-data';
// import { getJobId, getJobStatus, getPredidtion } from "./jobs";
import EmotionsChart from './EmotionChart';

import axios from 'axios';

const speechsdk = require('microsoft-cognitiveservices-speech-sdk');

const theme = createTheme();

const Index = () => {
  const [disabledButton, setDisableButton] = React.useState(true);

  React.useEffect(() => {
    const getSpeechToken = async () => {
      const tokenRes = await getTokenOrRefresh();
      if (tokenRes.authToken === null) {
        // TODO 'FATAL_ERROR: ' + tokenRes.error
      }
    };
    getSpeechToken();
    document.getElementById('timer').textContent = CUONTDWON_TIME;
  }, []);

  let [transcript, setTranscript] = React.useState('');
  React.useEffect(() => {
    const fixFinalTranscript = async () => {
      if (transcript) {
        transcript = transcript.replaceAll('\n', ' ');

        await axios
          .post(
            process.env.REACT_APP_BASE_API_URL +
              '/speechToText/fix-final-transcript',
            { text: transcript },
          )
          //await axios.post(process.env.REACT_APP_BASE_API_URL+"/procceseFeedbackWords/fix-text",{text:transcript})
          .then((res) => {
            document.getElementById('textField').value = res.data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    };
    fixFinalTranscript();
  }, [transcript]);

  const CUONTDWON_TIME = 60; // 60 sec
  // Set the start time in seconds
  let time = CUONTDWON_TIME;

  // Store the timer ID
  let timerId = React.useRef(null);

  // Function to start the timer
  function startTimer() {
    setDisableButton(true);
    document.getElementById('textField').value = '';
    setIsRunning(true);
    startRecognize();
    timerId.current = setInterval(function () {
      // Decrement the time by one second
      time--;

      // Calculate the seconds
      let seconds = time % 60;

      // Add leading zeros if necessary
      //minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      // Update the timer display
      document.getElementById('timer').textContent = `${seconds}`;

      // If the time reaches zero, stop the timer
      if (time === 0) {
        clearInterval(timerId.current);
        setIsRunning(false);
        alert("Time's up!");
      }
    }, 1000);
  }

  // Function to stop the timer
  function stopTimer() {
    clearInterval(timerId.current);
    time = CUONTDWON_TIME;
    setIsRunning(false);
    clearTimeout(recognaizerTimer.current);
    // Update the timer display
    document.getElementById('timer').textContent = `60`;

    globalRecognizer.stopContinuousRecognitionAsync();
  }

  // const fixFinalTranscript = async (finalTranscript) => {
  //   const res = await axios.post(process.env.REACT_APP_BASE_API_URL+'/speechToText/fix-final-transcript',{
  //     finalTranscript
  //   });
  //   console.log(res.data);
  //   document.getElementById('textField').value = res.data;
  // }

  const [isRunning, setIsRunning] = React.useState(false);

  let recognaizerTimer = React.useRef(null);
  const finalTranscriptArray = [];

  const [globalRecognizer, setGlobalRecognizer] = React.useState(null);

  const startRecognize = async () => {
    const tokenObj = await getTokenOrRefresh();
    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(
      tokenObj.authToken,
      tokenObj.region,
    );
    speechConfig.speechRecognitionLanguage = 'en-US';
    speechConfig.outputFormat = 1;

    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new speechsdk.SpeechRecognizer(
      speechConfig,
      audioConfig,
    );
    setGlobalRecognizer(recognizer);

    recognizer.startContinuousRecognitionAsync();
    await new Promise(function (resolve, reject) {
      recognizer.recognized = (s, e) => {
        if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
          console.log(`RECOGNIZED: Text=${e.result.text}`);
          finalTranscriptArray.push(e.result.text);
        } else if (e.result.reason === speechsdk.ResultReason.NoMatch) {
          console.log('NOMATCH: Speech could not be recognized.');
        }
      };
      recognizer.sessionStopped = (s, e) => {
        console.log('\n    Session stopped event.');
        recognizer.stopContinuousRecognitionAsync();
        const finalTranscript = finalTranscriptArray
          .join(' ')
          .toLocaleLowerCase();
        setTranscript(finalTranscript);
        console.log(finalTranscript);
        //fixFinalTranscript(finalTranscript);
        return resolve();
      };

      recognaizerTimer.current = setTimeout(function () {
        console.log('stop timer');
        if (isRunning) {
          clearTimeout(recognaizerTimer.current);
          stopTimer();
        }

        recognizer.stopContinuousRecognitionAsync();
      }, 60000);
    });
  };

  const copyToClipboard = () => {
    const text = document.getElementById('textField').value;
    // const arrText = text.split(' ');

    // console.log(arrText);

    navigator.clipboard.writeText(text).then(
      () => {
        /* clipboard successfully set */
        window.alert('copy to clipboard');
      },
      () => {
        /* clipboard write failed */
        window.alert('copy to clipboard failed');
      },
    );
  };

  const textFieldChange = (e) => {
    const textLeft = e.target.value.replaceAll('\n', '');

    if (textLeft) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  const fixTextButton = async () => {
    let text = document.getElementById('textField').value.trim();

    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL +
          '/speechToText/fix-final-transcript',
        { text },
      )
      .then((res) => {
        document.getElementById('textField').value = res.data;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadAudioFile = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append('file', e.target.files[0]);

    document.getElementById('audioFile').value = null;

    const uploadedAudioFileName = await axios.post(
      process.env.REACT_APP_BASE_API_URL + '/speechToText/upload-audio-file',
      formData,
    );
    // console.log(uploadedAudioFileName);
    const transcript = await axios.get(
      process.env.REACT_APP_BASE_API_URL +
        '/speechToText/get-audio-file-transcript',
      { params: { fileName: uploadedAudioFileName.data } },
    );

    console.log(transcript.data);

    // document.getElementById("textField").value = transcript.data;

    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL +
          '/speechToText/fix-final-transcript',
        { text: transcript.data },
      )
      .then((res) => {
        document.getElementById('textField').value = res.data;
      })
      .catch((e) => {
        console.log(e);
      });

    // const jobId = await getJobId(uploadedAudioFileName.data);

    // let jobStatus = await getJobStatus(jobId);
    // while (jobStatus !== "COMPLETED" && jobStatus !== "FAILED") {
    //   sleep(1000);
    //   jobStatus = await getJobStatus(jobId);
    //   console.log(jobStatus);
    // }

    // const predictions =
    //   jobStatus === "FAILED" ? {} : await getPredidtion(jobId);

    // setPredicions(predictions)
  };

  // function sleep(ms) {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" height="100%">
        <CssBaseline />
        <Grid
          direction="column"
          spacing={5}
          container
          justifyContent="center"
          alignItems="center"
          style={{
            margin: '0',
            //height: '100vh',
            minHeight: '100vh',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Grid>
            <img src={logo} alt="" style={{ cursor: 'pointer' }} />
          </Grid>
          <Grid>
            <EmotionsChart />
            <Button
              style={{
                border: '1px solid #E6E6E6',
                textTransform: 'unset',
                backgroundColor: '#E6E6E6',
                color: 'green',
                fontSize: '12px',
                fontFamily: 'Elephant',
              }}
              component="label"
            >
              <input
                type="file"
                name="audioFile"
                id="audioFile"
                hidden
                accept="audio/wav"
                onChange={uploadAudioFile}
              />
              upload wav file
            </Button>
          </Grid>

          <Grid>
            <Typography
              style={{ marginTop: '20px' }}
              fontSize="18px"
              fontFamily="Elephant"
            >
              Record your 60 seconds message.
            </Typography>
          </Grid>

          <Grid style={{ marginTop: '15px' }}>
            <IconButton
              size="large"
              onClick={isRunning ? stopTimer : startTimer}
              style={{
                backgroundColor: '#E6E6E6',
              }}
            >
              <MicIcon
                style={{
                  color: isRunning ? 'red' : 'green',
                  fontSize: '40px',
                }}
              />
            </IconButton>
          </Grid>
          <Grid>
            <InputLabel
              id="timer"
              style={{ fontSize: '18px', fontFamily: 'Elephant' }}
            />
          </Grid>

          <Grid style={{ marginTop: '15px' }}>
            <Typography fontSize="18px" fontFamily="Elephant">
              Or enter text
            </Typography>
          </Grid>

          <Grid style={{ marginTop: '25px', marginLeft: '60px' }}>
            <TextField
              id="textField"
              variant="outlined"
              placeholder="Enter text..."
              multiline
              minRows={10}
              maxRows={10}
              onChange={textFieldChange}
              style={{
                width: 360,
                overflow: 'auto',
              }}
            />
            <Tooltip title="Copy to Clipboard">
              <IconButton onClick={copyToClipboard} size="large">
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid style={{ marginTop: '15px' }}>
            <Button
              onClick={fixTextButton}
              disabled={disabledButton}
              style={{
                border: '1px solid #E6E6E6',
                //borderRadius: "20px",
                textTransform: 'unset',
                backgroundColor: '#E6E6E6',
                color: disabledButton ? '#c0c0c0' : 'green',
                fontSize: '18px',

                fontFamily: 'Elephant',
                borderRadius: '50%',
                // width:"110px",
                // height: '110px',
                // minWidth: 0,
                // padding: 0,
              }}
            >
              Fix text
            </Button>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Index;
