import React from "react";
import Register from "./../../components/register/index";

const Index = () => {
  return (
    <React.Fragment>
      <Register />
    </React.Fragment>
  );
};

export default Index;
