import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppState } from '../../AppStateContext';

import {
  Grid,
  Box,
  Typography,
  MenuItem,
  Menu,
  Button,
  Container,
  FormControl,
  Select,
} from '@mui/material';

import axios from 'axios';

import { Loader } from 'semantic-ui-react';
import logo from './../../assets/logo1.png';
// import bell from './../../assets/bell.png';
import down from './../../assets/arrow-down.ico';
import home from './../../assets/home.png';
import signal from './../../assets/signal.png';

import StoreEmailsContactsDialog from './storeEmailsContactsDialog';
// import StorePhonesContactsDialog from './storePhonesContactsDialog';
import CustomerServiceReport from './customerServiceReport.js';

const User = ({ children }) => {
  const [userName, setUserName] = React.useState('');
  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const [storeId, setStoreId] = useState();
  const { updateStoreId, updateStoreVersion } = useAppState();
  const selectedStoreId = (event) => {
    const selectedStore = storesList.find(
      (store) => store._id === event.target.value,
    );

    updateStoreVersion(selectedStore.version);
    // updateStoreId trigger storeId = setStoreId
    updateStoreId(event.target.value);
    setStoreId(event.target.value);
  };

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BASE_API_URL + `/users/check-admin`, {
        token: localStorage.getItem('token'),
      })
      .then((res) => {
        if (localStorage.getItem('userEmail')) {
          localStorage.removeItem('userEmail');
        }

        if (res.data.role === 'admin') {
          setIsAdmin(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        }
      });

    axios
      .post(process.env.REACT_APP_BASE_API_URL + `/users/get-user`, {
        token: localStorage.getItem('token'),
      })
      .then((res) => {
        setUserName(res.data.name);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem('token');
          navigate('/login');
        }
      });
    document.getElementById('dashboard').style.background = '#d5f4ec';
    navigate('/user/dashboard');
  }, []);

  const [storesList, setStoresList] = useState([]);
  const [selectDefaultValue, setSelectDefaultValue] = useState('');
  useEffect(() => {
    async function fetchData() {
      let res = '';
      if (isAdmin) {
        res = await axios.get(
          process.env.REACT_APP_BASE_API_URL + `/stores/get-stores`,
          {
            params: {
              token: localStorage.getItem('token'),
            },
          },
        );
      } else {
        res = await axios.get(
          process.env.REACT_APP_BASE_API_URL + `/stores/get-user-stores`,
          {
            params: {
              token: localStorage.getItem('token'),
            },
          },
        );
      }
      setStoresList(res.data);
      if (res.data[0]) {
        setStoreId(res.data[0]._id);
        setSelectDefaultValue(res.data[0]._id);
        updateStoreId(res.data[0]._id); // trigger dashboard and analytics calcs
      }
    }
    fetchData();
  }, [isAdmin]);

  const handleLogOut = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const openAdminOptions = () => {
    navigate('/admin-options-main');
  };

  const dashboardClick = (e) => {
    e.target.style.background = '#d5f4ec';
    document.getElementById('historyAnalytics').style.background = '';
    navigate('/user/dashboard');
  };
  const historyAnalyticsClick = (e) => {
    e.target.style.background = '#d5f4ec';
    document.getElementById('dashboard').style.background = '';
    navigate('/user/analytics');
  };

  return (
    <>
      <Box sx={{ background: 'black', py: 1, px: 6 }}>
        <Grid container justifyContent={'space-between'}>
          <Grid item xs={2} onClick={() => navigate('/')}>
            <img src={logo} alt="" style={{ cursor: 'pointer' }} />
          </Grid>
          <Grid item>
            <StoreEmailsContactsDialog storeId={storeId} />
            {/* <StorePhonesContactsDialog storeId={storeId} /> */}
          </Grid>
          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            {/* <img src={bell} style={{ padding: '15px' }} alt="" /> */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              id="basic-button"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <img alt="" src={down} />
              <Typography sx={{ color: '#fff' }}>{userName}</Typography>
            </Box>
            {isAdmin ? (
              <Button style={{ padding: '15px' }} onClick={openAdminOptions}>
                Admin
              </Button>
            ) : (
              <Loader />
            )}
          </Grid>
        </Grid>
      </Box>

      <Container>
        <Box sx={{ background: '#edf5f5', py: 2 }}>
          <Grid container justifyContent={'space-between'}>
            <Grid item container xs={6}>
              <Grid item>
                <Box
                  id="dashboard"
                  sx={{
                    p: 2,
                    //background: "#d5f4ec",
                    width: '110px',
                    borderRadius: '50px',
                    display: 'flex',
                    justifyContent: 'space-around',
                    cursor: 'pointer',
                  }}
                  onClick={dashboardClick}
                >
                  <img src={home} alt="" />
                  Dashboard
                </Box>
              </Grid>
              <Grid item>
                <Box
                  id="historyAnalytics"
                  className="history"
                  sx={{
                    p: 2,
                    cursor: 'pointer',
                  }}
                  onClick={historyAnalyticsClick}
                >
                  <img src={signal} alt="" />
                  History & Analytics
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={3.5} className="grid1">
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open1}
                onClose={handleClose1}
              >
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
              <Box>
                <CustomerServiceReport storeId={storeId} />
              </Box>
              <Box sx={{ p: 1, cursor: 'pointer' }}>
                <FormControl fullWidth size="small">
                  <Select
                    sx={{
                      backgroundColor: '#5FEFC0',
                      // padding: "1px",
                      width: '175px',
                      height: '35px',
                      border: '1px solid',
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={storeId}
                    defaultValue={selectDefaultValue}
                    key={selectDefaultValue}
                    onChange={selectedStoreId}
                  >
                    {storesList.map((store) => {
                      return (
                        <MenuItem
                          value={store._id}
                          key={store._id}
                          name={store.name}
                        >
                          {store.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Outlet />
      </Container>
    </>
  );
};

export default User;
