import React from 'react';
import AdminOpitonsMainCompotenet from '../../components/adminOptionsMain';

const AdminOptionisMain = () => {
  return (
    <React.Fragment>
      <AdminOpitonsMainCompotenet />
    </React.Fragment>
  );
};

export default AdminOptionisMain;
