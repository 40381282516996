import React from "react";
import VerifyForgotPassword from "./../../components/verifyForgotPassword/index";
const Index = () => {
  return (
    <React.Fragment>
      <VerifyForgotPassword />
    </React.Fragment>
  );
};

export default Index;
