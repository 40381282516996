import React from "react";
import Home from "./../../components/home/index";

const Index = () => {
  return (
    <React.Fragment>
      <Home />
    </React.Fragment>
  );
};

export default Index;
