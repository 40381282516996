import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";

import Video from "./../../assets/video.png";
import Resource from "./../../assets/resource.png";
import Resource1 from "./../../assets/rsource1.PNG";
import video1 from "./../../assets/video1.mp4"
import Modal from '@mui/material/Modal';

import "./index.css";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign:"right",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Resources = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <React.Fragment>
      <Box sx={{ background: "#fff", p: 6 }} id="resource">
        <Grid>
          <Grid xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Box className="feature-box">INTRODUCE</Box>
          </Grid>
          <Typography
            className="packeg-typography"
            sx={{
              my: 4,
            }}
          >
            Resources.
          </Typography>
        </Grid>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={2.5}>
            <Typography
              className="resource-typography"
              sx={{
                lineHeight: "1",
                mt: 10,
              }}
            >
              About Registration & Features
            </Typography>
            <Typography sx={{ color: "#a0a0a0", my: 3 }}>
              Our platform creates customize landing page with unique QR code, shows data
              analytics, allow you to read your reviews and see scores and offer professional
              solutions for your issue & much more…
            </Typography>
            <img onClick={handleOpen} src={Video} alt="" width="100%" />            
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
          >
            <Box sx={style}>
              <Button onClick={handleClose}>X</Button>
              <video src={video1} controls autoplay="true" />
            </Box>
          </Modal>

          <Grid item xs={6}>
            <img src={Resource} alt="" width={"100%"} />
          </Grid>
        </Grid>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={4}>
            <img src={Resource1} alt="" width={"100%"} />
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ width: "250px" }}>
              <Typography
                className="resource-typography1"
                sx={{
                  lineHeight: "1",
                  mt: 10,
                }}
              >
                About Big Data Analysis, Machine Learning & Artificial Intelligence
              </Typography>
              <Typography sx={{ color: "#a0a0a0", my: 3, width: "250px" }}>
                Our advance software tech gives you the best results with the
                Highest accuracy
              </Typography>
              <img src={Video} alt="" width="100%" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Resources;
