import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import axios from 'axios';
import { types } from '../../register/constants';
import { languages } from '../constants';
import {
  TextField,
  Box,
  Typography,
  Modal,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
} from '@mui/material';

export default function BasicModal(props) {
  const { storeId, setStoresList, storesList, version } = props;
  const [disableButton, setDisableButton] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState('');
  const [language, setLanguage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [storeName, setStoreName] = React.useState('');
  const [question1, setQuestion1] = React.useState('');
  const [question2, setQuestion2] = React.useState('');
  const [question3, setQuestion3] = React.useState('');
  const [question4, setQuestion4] = React.useState('');
  // const [storesList, setStoresList] = useState([]);

  React.useEffect(() => {
    const getStoreDetails = async () => {
      const storeDetails = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/stores/get-store',
        { params: { id: storeId } },
      );
      const detalis = storeDetails.data;

      setStoreName(detalis.name);
      setQuestion1(detalis.questions[0]);
      setQuestion2(detalis.questions[1]);
      setQuestion3(detalis.questions[2]);
      setQuestion4(detalis.questions[3]);
      setType(detalis.type);
      setLanguage(detalis.language);
    };
    if (storeId) {
      setDisableButton(false);
      getStoreDetails();
    }
  }, [storeId]);

  const saveNewStoreDetails = async () => {
    setErrorMessage('');
    const storeName = document.getElementById('storeName').value.trim();
    if (storeName.length <= 0) {
      setErrorMessage('Enter store name');
      return null;
    }

    if (type.length <= 0) {
      setErrorMessage('Select store type');
      return null;
    }
    const question1 = document.getElementById('question1').value.trim();
    let question2 = '';
    let question3 = '';
    let question4 = '';
    if (version === 4) {
      question2 = document.getElementById('question2').value.trim();
      question3 = document.getElementById('question3').value.trim();
      question4 = document.getElementById('question4').value.trim();
    }

    const questions = [question1, question2, question3, question4];
    const storeDetails = {
      name: storeName,
      type,
      questions,
      language,
    };

    const updatedStore = await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + '/stores/update-store-details',
        {
          storeDetails,
          storeId,
        },
      )
      .catch((error) => {
        setErrorMessage('Store name already exists');
        console.log(error);
      });

    const storeToUpdateIndex = storesList.findIndex(
      (store) => store._id === updatedStore.data._id,
    );

    if (storeToUpdateIndex !== -1) {
      storesList[storeToUpdateIndex] = {
        ...storesList[storeToUpdateIndex],
        ...updatedStore.data,
      };
      setStoresList([...storesList]);
      setOpen(false);
    }
  };

  //   const updateStoreListWithUpdateStore = (updatedStore) => {
  //     const storeToUpdateIndex = storeList.findIndex(
  //       (store) => store.id === updatedStore._id
  //     );
  //     if (storeToUpdateIndex !== -1) {
  //       storeList[storeToUpdateIndex] = {
  //         ...storeList[storeToUpdateIndex],
  //         ...updatedStore,
  //       };
  //     }
  //   };

  const slectedType = (e) => {
    // console.log(e.target.value);
    setType(e.target.value);
  };
  const slectedlanguage = (e) => {
    setLanguage(e.target.value);
  };
  const handleStoreNameChange = (event) => {
    setStoreName(event.target.value);
  };
  const handleQuestion1Change = (event) => {
    setQuestion1(event.target.value);
  };
  const handleQuestion2Change = (event) => {
    setQuestion2(event.target.value);
  };
  const handleQuestion3Change = (event) => {
    setQuestion3(event.target.value);
  };
  const handleQuestion4Change = (event) => {
    setQuestion4(event.target.value);
  };

  return (
    <React.Fragment>
      <Box sx={{ p: 1, cursor: 'pointer' }}>
        <Button
          style={{ textTransform: 'unset', color: 'black' }}
          variant="outlined"
          sx={{ cursor: 'pointer', background: '#5fefc0', width: '130px' }}
          disabled={disableButton}
          onClick={() => {
            setOpen(true);
          }}
        >
          * Edit Store
        </Button>
      </Box>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Sheet
            variant="outlined"
            sx={{
              width: '100%',
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
              bgcolor: '#F5F5F5',
              textAlign: 'center',
            }}
          >
            <Typography>Update store (Branch) details</Typography>
            <div style={{ padding: '5px', color: 'red', fontWeight: 'bold' }}>
              {errorMessage}
            </div>
            <div>
              <TextField
                id="storeName"
                value={storeName}
                size="small"
                label="Store Name"
                maxWidth="300px"
                onChange={handleStoreNameChange}
              />
            </div>
            {version === 1 ? (
              <div>
                <TextField
                  id="question1"
                  value={question1}
                  size="small"
                  label="Question 1"
                  maxWidth="300px"
                  onChange={handleQuestion1Change}
                />
              </div>
            ) : (
              <div>
                <TextField
                  id="question1"
                  value={question1}
                  size="small"
                  label="Question 1"
                  maxWidth="300px"
                  onChange={handleQuestion1Change}
                />
                <TextField
                  id="question2"
                  value={question2}
                  size="small"
                  label="Question 2"
                  maxWidth="300px"
                  onChange={handleQuestion2Change}
                />
                <TextField
                  value={question3}
                  id="question3"
                  size="small"
                  label="Question 3"
                  maxWidth="300px"
                  onChange={handleQuestion3Change}
                />
                <TextField
                  value={question4}
                  id="question4"
                  size="small"
                  label="Question 4"
                  maxWidth="300px"
                  onChange={handleQuestion4Change}
                />
              </div>
            )}

            <Box maxWidth="300px" paddingTop="10px">
              <FormControl fullWidth size="small">
                <InputLabel>Store type</InputLabel>
                <Select onChange={slectedType} defaultValue={type}>
                  {types.map((type) => (
                    <MenuItem value={type} name={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box maxWidth="300px" paddingTop="10px">
              <FormControl fullWidth size="small">
                <InputLabel>language</InputLabel>
                <Select onChange={slectedlanguage} defaultValue={language}>
                  {languages.map((language, inedx) => (
                    <MenuItem key={inedx} value={language} name={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Grid container direction="row">
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                variant="outlined"
                sx={{ cursor: 'pointer', background: '#5fefc0' }}
                onClick={saveNewStoreDetails}
              >
                Save
              </Button>
              <Button
                style={{ textTransform: 'unset', color: 'black' }}
                variant="outlined"
                sx={{ cursor: 'pointer', background: '#5fefc0' }}
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </Grid>
          </Sheet>
        </div>
      </Modal>
    </React.Fragment>
  );
}
