import * as React from 'react';
import axios from 'axios';
import { Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

export default function ClientsSelect({ setClientId }) {
  //   const {clientsList} = props;
  const [clientsList, setClientsList] = React.useState([]);
  React.useEffect(() => {
    const getHotelsClients = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/hotels/get-hotels-users')
        .catch((e) => {
          if (e.response.status === 404) {
            console.log(e.response.data.message);
            // return e.response.data.message;
          }
        });
      if (res) {
        setClientsList(res.data);
      }
    };
    getHotelsClients();
  }, []);

  const changeSelectClients = function (e) {
    setClientId(e.target.value);
  };
  return (
    <React.Fragment>
      <Grid>
        <FormControl sx={{ minWidth: '250px', mt: 2 }} variant="standard">
          <InputLabel shrink htmlFor="bootstrap-input">
            Clients
          </InputLabel>
          <Select
            style={{ backgroundColor: '#CDCDCD' }}
            onChange={changeSelectClients}
          >
            {clientsList.map((client) => {
              return (
                <MenuItem
                  value={client._id}
                  name={client.name}
                  key={client._id}
                >
                  {client.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
