import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import ScriptsComparison from './scriptsComparison';
import RegisterModal from './registerModal';

function ResponsiveAppBar() {
  const [selectedCompany, setSelectedCompany] = React.useState('');
  const selectCompany = (event) => {
    setSelectedCompany(event.target.value);
  };
  return (
    <Grid>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button sx={{ my: 2, color: 'white', display: 'block' }}>
                Scripts
              </Button>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Button sx={{ my: 2, color: 'white', display: 'block' }}>
                Sentences
              </Button>
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <RegisterModal />
            </Box>

            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <FormControl fullWidth>
                <Select
                  value={selectedCompany}
                  // defaultValue={selectDefaultValue}
                  onChange={selectCompany}
                  autoWidth
                  sx={{ color: 'white', display: 'block', width: 150 }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                  <MenuItem value={40}>חברה עם שם ארוך</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <ScriptsComparison />
    </Grid>
  );
}
export default ResponsiveAppBar;
