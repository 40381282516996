import axios from 'axios';
// import xlsx from 'xlsx';
import React from 'react';
import { Grid, Typography } from '@mui/material';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

// import Dialog from '@mui/material/Dialog';
// import ListItemText from '@mui/material/ListItemText';
// import ListItem from '@mui/material/ListItem';
// import List from '@mui/material/List';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import CloseIcon from '@mui/icons-material/Close';
// import Slide from '@mui/material/Slide';

import DeleteSubjectDialog from './dialogs/deleteSubjectDialog';
import EditSubjectDialog from './dialogs/editSubjectDialog';
import SaveSentenceDialog from './dialogs/saveSentenceDialog';
import SentencesDialog from './dialogs/sentencesDialog';

import SaveSubjectDialog from './dialogs/saveSubjectDialog';
// import Highlighter from 'react-highlight-words';
//import useKeypress from 'react-use-keypress';
// import { count } from "../../../../backend/models/subject";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const Column = (props) => {
  const { sentiment } = props;
  const [subjectList, setSubjectList] = React.useState([]);
  const [sentencesList, setSentencesList] = React.useState([]);

  React.useEffect(() => {
    const getSubjects = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/hotels/get-subject', {
          params: { sentiment },
        })
        .catch((e) => {
          if (e.response.status === 404) {
            console.log(e.response.data.message);
            // return e.response.data.message;
          }
        });
      if (res) {
        setSubjectList(res.data);
      }
    };
    getSubjects();
  }, []);

  // const [selectedSubject, setSelectedSubject] = React.useState(null);
  const selectSubject = function (subject) {
    setSentencesList(subject.sentences);
  };
  return (
    <React.Fragment>
      <Grid container direction="column" xs={5} minWidth="50%">
        <Grid
          style={{ border: '1px solid #5FEFC0', textAlign: 'center' }}
          xs={0}
        >
          <h2>{sentiment} subjects</h2>

          {subjectList.map((subject) => {
            return (
              <Grid onClick={() => selectSubject(subject)}>
                <DeleteSubjectDialog
                  subject={subject}
                  subjectList={subjectList}
                  setSubjectList={setSubjectList}
                />
                <EditSubjectDialog
                  subject={subject}
                  subjectList={subjectList}
                  setSubjectList={setSubjectList}
                />
                <SentencesDialog
                  subject={subject}
                  sentencesList={sentencesList}
                  setSentencesList={setSentencesList}
                />
                <SaveSentenceDialog subject={subject} />
              </Grid>
            );
          })}

          <SaveSubjectDialog
            sentiment={sentiment}
            subjectList={subjectList}
            setSubjectList={setSubjectList}
          />
        </Grid>
        <Grid xs={0} padding={'20px'}>
          <Typography textAlign="center">
            <h4>{sentiment} subjects found</h4>
          </Typography>
          <Grid textAlign="center" style={{ border: '1px solid #5FEFC0' }}>
            {/* {positiveSubjectCount.map((subject) => {
            return (
              <Typography>
                {subject.count} {subject.subject} {subject.type}
              </Typography>
            );
          })} */}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Column;
