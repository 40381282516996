// import React, { useEffect, useState } from 'react';
// import ReactApexChart from 'react-apexcharts';

const EmotionsChart = (props) => {
  // const [options, setOptions] = React.useState({
  //   colors: ['#8AF3D6', '#F89E93'],
  //   chart: {
  //     type: 'line',
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: 'smooth',
  //     width: 2,
  //   },
  //   grid: {
  //     borderColor: '#f8f8fa',
  //     row: {
  //       colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
  //       opacity: 0.5,
  //     },
  //   },
  //   xaxis: {
  //     //[]
  //     categories: [
  //       'Jan',
  //       'Feb',
  //       'Mar',
  //       'Apr',
  //       'May',
  //       'Jun',
  //       'Jul',
  //       'Aug',
  //       'Sep',
  //       'Oct',
  //       'Nov',
  //       'Dec',
  //     ],
  //     axisBorder: {
  //       show: true,
  //     },
  //     axisTicks: {
  //       show: false,
  //     },
  //   },
  //   legend: {
  //     show: true,
  //   },
  // });
  // const [series, setSeries] = useState([
  //   {
  //     name: 'Series A',
  //     type: 'area',
  //     //data: [18, 10, 38, 25, 30, 18, 42, 28, 30, 23, 30, 40],
  //     data: [28, 12, 15, 30, 25, 30, 20, 25, 45, 35, 28, 35],
  //   },
  //   {
  //     name: 'Series B',
  //     type: 'area',
  //     //data: [22, 14, 44, 29, 34, 25, 47, 35, 40, 30, 35, 50],
  //     data: [20, 15, 7, 22, 20, 35, 12, 20, 35, 37, 24, 30],
  //   },
  // ]);
  // const emotionColor = {
  //   Satisfaction: "#8AF3D6",
  //   Disappointment: "#F89E93",
  //   Fear: "#FF7F27",
  // };
  // useEffect(() => {
  //   // console.log(predictions);
  //   const categories = ['a', 'b', 'c', 'd', 'e'];
  //   const series = [{ data: [1, 2, 3, 4, 5] }];
  //   // for (let i = 0; i < predictions.length; i++){
  //   //   const emotions = predictions[i].emotions;
  //   //   const emotionMaxScore = emotions.reduce((prev, current) => {return prev.score > current.score ? prev : current});
  //   //   // console.log(emotionMaxScore);
  //   //   categories.push(emotionMaxScore.name);
  //   //   // series.push({data:[(emotionMaxScore.score * 10).toFixed(2)], name:emotionMaxScore.name, type:"column", color: emotionColor[emotionMaxScore.name]});
  //   // }
  //   //   console.log(categories, series);
  //   setOptions({ xaxis: { categories: categories } });
  //   setSeries(series);
  // }, [predictions]);
  // return (
  //   <React.Fragment>
  //     {/* <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "space-between",
  //         alignItems: "center",
  //         pt: 2,
  //         px: 2,
  //       }}
  //     >
  //       <div style={{ display: "flex", alignItems: "center" }}>
  //       </div>
  //     </Box> */}
  //     <ReactApexChart
  //       options={options}
  //       series={series}
  //       type="area"
  //       // height={400}
  //       className="apex-charts"
  //     />
  //   </React.Fragment>
  // );
};

export default EmotionsChart;
