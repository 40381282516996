import React from "react";
import RandomSentence from "./../../components/randomSentence/index";

const Index = () => {
  return (
    <React.Fragment>
      <RandomSentence />
    </React.Fragment>
  );
};

export default Index;