import React, { useState } from 'react';
import axios from 'axios';
import xlsx from 'xlsx';
import { useNavigate } from 'react-router-dom';
import logo from './../../assets/logo1.png';
import { types } from '../register/constants';
// import { questions } from "./../adminOptionsItalian/constnats";
import PosNegTables from '../adminPosNegTables/PosNegTables';
import PosNegQ4Tables from '../adminPosNegTables/PosNegQ4Tables';
// import PosNegQ1Tables from './PosNegQ1Tables';
// import ExcelChartsFileDialog from './ExcelChartsFileDialog';

import AllNotFoundSentences from '../adminOptions1Question/AllNotFoundSentences';

import {
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Button,
} from '@mui/material';

const Index = () => {
  const navigate = useNavigate();

  // const [openExcelChartsFileDialog, setOpenExcelChartsFileDialog] =
  //   React.useState(false);

  // const handleCloseExcelChartsFileDialog = () =>
  //   setOpenExcelChartsFileDialog(false);

  // const [excelFileName, setExcelFileName] = useState('');
  // const [storeName, setStoreName] = useState('');

  const [selectedType, setSelectedType] = useState('');
  const [selectedQuestionNuber, setSelectedQuestionNuber] = useState();

  const [error, setError] = useState('');

  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedDirection, setSelectedDirection] = useState('');

  const [positiveTextQ1, setPositiveTextQ1] = useState('');
  const [negativeTextQ1, setNegativeTextQ1] = useState('');
  const [positiveTextQ2, setPositiveTextQ2] = useState('');
  const [negativeTextQ2, setNegativeTextQ2] = useState('');
  const [positiveTextQ3, setPositiveTextQ3] = useState('');
  const [negativeTextQ3, setNegativeTextQ3] = useState('');
  const [positiveTextQ4, setPositiveTextQ4] = useState('');
  const [negativeTextQ4, setNegativeTextQ4] = useState('');

  // useEffect(() => {
  //   const getStoreQuestions = async function () {
  //     const storeQuestions = await axios.get(
  //       process.env.REACT_APP_BASE_API_URL + '/stores/get-store-questions',
  //       { params: { storeId } },
  //     );
  //     setQuestions(storeQuestions.data);
  //   };
  //   if (storeId) {
  //     getStoreQuestions();
  //   }
  // }, [storeId]);

  const addSentenceKeyDown = (e) => {
    if (e.key === 'Enter') {
      addSentence();
    }
  };

  const changeSelectType = async (e) => {
    const selectedType = e.target.value;
    setSelectedType(selectedType);
    setError('');
  };

  const addSentence = async () => {
    const sentence = document.getElementById('sentenceInput').value.trim();

    await axios
      .post(
        process.env.REACT_APP_BASE_API_URL + '/italianSubjects/add-sentence',
        {
          name: selectedSubject,
          type: selectedType,
          questionNumber: selectedQuestionNuber,
          direction: selectedDirection,
          sentence,
        },
      )
      .then((res) => {
        const { message } = res.data;
        setError(message);
      })
      .catch((error) => {
        const { message } = error.response.data;
        setError(message);
      });
    document.getElementById('sentenceInput').value = '';
  };

  const readUploadFile = async (e) => {
    e.preventDefault();
    setError('');
    let type = document.getElementById('typeSelect').textContent;
    if (type.length <= 1) {
      setError('Select Type');
      return null;
    }

    // if (storeId) {
    //   const excelData = await readExcelData(e, storeId);
    //   await axios
    //     .post(
    //       process.env.REACT_APP_BASE_API_URL + '/reviews/insert-excel-data',
    //       {
    //         excelData,
    //         storeId,
    //       },
    //     )
    //     .then((res) => {
    //       console.log(res.data.message);
    //       setError(res.data.message);
    //     })
    //     .catch((e) => {
    //       if (e.response.status === 401) {
    //         setError(e.response.data.message);
    //       }
    //     });
    // } else {
    //   setError('Select client end Store');
    //   return null;
    // }

    const questions = await readFeedbacks(e);

    // setStoreName(questions.storeName);

    setPositiveTextQ1(questions.question1positive);
    setNegativeTextQ1(questions.question1negative);

    setPositiveTextQ2(questions.question2positive);
    setNegativeTextQ2(questions.question2negative);

    setPositiveTextQ3(questions.question3positive);
    setNegativeTextQ3(questions.question3negative);

    setPositiveTextQ4(questions.question4positive);
    setNegativeTextQ4(questions.question4negative);
  };

  // const readExcelData = (e, storeId) => {
  //   return new Promise((resolve, reject) => {
  //     const feedbacks = [];
  //     // console.log(e.target.files);

  //     if (e.target.files) {
  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         const data = e.target.result;
  //         const workbook = xlsx.read(data, { type: 'array' });
  //         const sheetName = workbook.SheetNames[0];
  //         const worksheet = workbook.Sheets[sheetName];
  //         const json = xlsx.utils.sheet_to_json(worksheet);

  //         for (const row of json) {
  //           if (row.__rowNum__ > 1) {
  //             const obj = {};
  //             obj.store = storeId;
  //             obj.date = convertDateFormat(row.__EMPTY_6, row.__EMPTY_7);
  //             // obj.time = row.__EMPTY_7;
  //             // obj.date = convertDateFormat(Object.values(row)[0]);
  //             // obj.time = row.__EMPTY;
  //             obj.question1 = row.__EMPTY_1;
  //             obj.question2 = row.__EMPTY_2;
  //             obj.question3 = row.__EMPTY_3;
  //             obj.question4 = row.__EMPTY_4;
  //             obj.stars = row.__EMPTY_5;

  //             feedbacks.push(obj);
  //           }
  //         }

  //         resolve(feedbacks);
  //       };
  //       reader.readAsArrayBuffer(e.target.files[0]);
  //     } else {
  //       reject(feedbacks);
  //     }
  //   });
  // };

  // const convertDateFormat = function (date, time) {
  //   // convert from dd/mm/yyyy
  //   const dateArr = date.split('/'); // [dd,mm,yyyy]
  //   const reverseDateArr = dateArr.reverse(); // [yyyy,mm,dd]
  //   const timeArr = time.split(':'); // [h,min,sec]
  //   const fixedDate = new Date(
  //     Number(reverseDateArr[0]), // yyyy
  //     Number(reverseDateArr[1]) - 1, // mm
  //     Number(reverseDateArr[2]), // dd
  //     Number(timeArr[0]), // h
  //     Number(timeArr[1]), // min
  //     Number(timeArr[2]), // sec
  //   );
  //   const timeZoneOffset = fixedDate.getTimezoneOffset();
  //   const timestamp = fixedDate.getTime() - timeZoneOffset * 60 * 1000;
  //   const utcDate = new Date(timestamp);

  //   return utcDate.toISOString();
  //   // console.log(fixedDate.toISOString());
  //   // return fixedDate.toISOString();
  // };

  const readFeedbacks = (e) => {
    return new Promise((resolve, reject) => {
      // let feedbacks345stars = "";
      // let feedbacks123stars = "";
      let storeName = '';
      let question1positive = '';
      let question1negative = '';
      let question2positive = '';
      let question2negative = '';
      let question3positive = '';
      let question3negative = '';
      let question4positive = '';
      let question4negative = '';
      let stars = '';
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);

          const storsDetailsArray = Object.keys(json[0])[0].split(' ');
          storeName = storsDetailsArray[2].split('=')[1];

          for (const row of json) {
            if (row.__rowNum__ > 1) {
              stars = row.__EMPTY_5;

              // if (stars >= 3) {
              //   question1positive += " " + row.__EMPTY_1;
              // }
              // if (stars <= 3) {
              //   question1negative += " " + row.__EMPTY_1;
              // }
              question1positive += ' ' + row.__EMPTY_1;
              question1negative += ' ' + row.__EMPTY_1;

              if (row.__EMPTY_2 >= 3) {
                question2positive += ' ' + row.__EMPTY_2;
              } else {
                question2negative += ' ' + row.__EMPTY_2;
              }

              if (row.__EMPTY_3 >= 3) {
                question3positive += ' ' + row.__EMPTY_3;
              } else {
                question3negative += ' ' + row.__EMPTY_3;
              }

              question4positive += ' ' + row.__EMPTY_4;
              question4negative += ' ' + row.__EMPTY_4;
              // if (stars >= 3) {
              //   question4positive += " " + row.__EMPTY_4;
              // }
              // if (stars <= 3) {
              //   question4negative += " " + row.__EMPTY_4;
              // }
            }
          }

          resolve({
            storeName,
            stars,
            question1positive,
            question2positive,
            question3positive,
            question4positive,
            question1negative,
            question2negative,
            question3negative,
            question4negative,
          });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
      document.getElementById('upload').value = '';
      e.target.value = '';
    });
  };

  // const generateExcel = async () => {
  //   handleClickOpenExcelChartsFileDialog();
  //   const res = await axios
  //     .post(
  //       process.env.REACT_APP_BASE_API_URL +
  //         '/italianSubjects/generate-excel-charts',
  //       {
  //         positiveCountArray1,
  //         positiveCountArray2,
  //         positiveCountArray3,
  //         positiveCountArray4,
  //         negativeCountArray1,
  //         negativeCountArray2,
  //         negativeCountArray3,
  //         negativeCountArray4,
  //         storeId,
  //       },
  //     )
  //     .catch((e) => console.log(e));

  //   setExcelFileName(res.data);
  // };

  return (
    <React.Fragment>
      <Container>
        <Box
          textAlign="center"
          style={{ backgroundColor: 'black' }}
          item
          xs={2}
          onClick={() => navigate('/user/dashboard')}
        >
          <img src={logo} alt="" style={{ cursor: 'pointer' }} />
        </Box>
        <center>
          <Typography style={{ color: 'red' }}>{error}</Typography>
        </center>
        <Grid>
          <AllNotFoundSentences storeVersion={4} />
        </Grid>

        <Box maxWidth="300px" paddingTop="10px">
          <FormControl fullWidth size="small">
            <InputLabel>Type</InputLabel>
            <Select id="typeSelect" onChange={changeSelectType} defaultValue="">
              {types.map((type) => (
                <MenuItem value={type} name={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box container spacing={3} marginTop="5%" margin="50px">
          <Grid
            container
            direction="row"
            spacing={0}
            //display="-ms-flexbox"
            xs={12}
          >
            <PosNegQ4Tables
              questionNumber="1"
              // question={questions[0]}
              positiveText={positiveTextQ1}
              negativeText={negativeTextQ1}
              selectedType={selectedType}
              setSelectedQuestionNuber={setSelectedQuestionNuber}
              setSelectedSubject={setSelectedSubject}
              setSelectedDirection={setSelectedDirection}
              // setPositiveCountArray={setPositiveCountArray1}
              // setNegativeCountArray={setNegativeCountArray1}
              //countSubjectsArray={countSubjectsArray}
              //setCountSubjectsArray={setCountSubjectsArray}
            />
            <PosNegTables
              questionNumber="2"
              // question={questions[1]}
              positiveText={positiveTextQ2}
              negativeText={negativeTextQ2}
              selectedType={selectedType}
              setSelectedQuestionNuber={setSelectedQuestionNuber}
              setSelectedSubject={setSelectedSubject}
              setSelectedDirection={setSelectedDirection}
              // setPositiveCountArray={setPositiveCountArray2}
              // setNegativeCountArray={setNegativeCountArray2}
              //countSubjectsArray={countSubjectsArray}
              //setCountSubjectsArray={setCountSubjectsArray}
            />
            <PosNegTables
              questionNumber="3"
              // question={questions[2]}
              positiveText={positiveTextQ3}
              negativeText={negativeTextQ3}
              selectedType={selectedType}
              setSelectedQuestionNuber={setSelectedQuestionNuber}
              setSelectedSubject={setSelectedSubject}
              setSelectedDirection={setSelectedDirection}
              // setPositiveCountArray={setPositiveCountArray3}
              // setNegativeCountArray={setNegativeCountArray3}
              //countSubjectsArray={countSubjectsArray}
              //setCountSubjectsArray={setCountSubjectsArray}
            />

            <PosNegQ4Tables
              questionNumber="4"
              // question={questions[3]}
              positiveText={positiveTextQ4}
              negativeText={negativeTextQ4}
              selectedType={selectedType}
              setSelectedQuestionNuber={setSelectedQuestionNuber}
              setSelectedSubject={setSelectedSubject}
              setSelectedDirection={setSelectedDirection}
              // setPositiveCountArray={setPositiveCountArray4}
              // setNegativeCountArray={setNegativeCountArray4}
              //countSubjectsArray={countSubjectsArray}
              //setCountSubjectsArray={setCountSubjectsArray}
            />
          </Grid>
          <Grid textAlign="right">
            <h3>
              {' '}
              table:{selectedQuestionNuber} {selectedType} {selectedSubject}{' '}
              {selectedDirection}
            </h3>
            <Input
              id="sentenceInput"
              dir="rtl"
              style={{ backgroundColor: '#BDDAFF' }}
              onKeyDown={addSentenceKeyDown}
            ></Input>
            <Button
              style={{ textTransform: 'unset' }}
              size="small"
              variant="outlined"
              onClick={addSentence}
            >
              Add Sentence
            </Button>
          </Grid>
        </Box>

        <Grid style={{ textAlign: 'right', marginTop: '2.5%' }}>
          <Button
            variant="contained"
            style={{ textTransform: 'unset' }}
            component="label"
          >
            <input
              type="file"
              name="upload"
              id="upload"
              hidden
              onChange={readUploadFile}
            />
            Upload File
          </Button>
          {/* <Button
            variant="contained"
            style={{ textTransform: 'unset' }}
            component="label"
            onClick={generateExcel}
            disabled={generateExcelButtonActive}
          >
            Generate Excel
          </Button> */}
        </Grid>
        {/* <ExcelChartsFileDialog
          open={openExcelChartsFileDialog}
          setOpen={setOpenExcelChartsFileDialog}
          onClose={handleCloseExcelChartsFileDialog}
          // fileName={excelFileName}
          storeName={storeName}
        /> */}
      </Container>
    </React.Fragment>
  );
};

export default Index;
