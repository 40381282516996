import * as React from 'react';

import { Grid, FormControl, Select, MenuItem, InputLabel } from '@mui/material';

export default function DepartmentsSelect(props) {
  const { setSelectedDepartment, departmentsList } = props;

  const changeSelectDepartment = function (e) {
    setSelectedDepartment(e.target.value);
  };

  return (
    <React.Fragment>
      <Grid>
        <FormControl sx={{ minWidth: '250px', mt: 2 }} variant="standard">
          <InputLabel shrink htmlFor="bootstrap-input">
            Select new department
          </InputLabel>
          <Select
            style={{ backgroundColor: '#CDCDCD' }}
            onChange={changeSelectDepartment}
          >
            {departmentsList.map((dep) => {
              return (
                <MenuItem value={dep._id} name={dep.name} key={dep._id}>
                  {dep.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </React.Fragment>
  );
}
