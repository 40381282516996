import axios from 'axios';
import xlsx from 'xlsx';
import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Input,
  Button,
  IconButton,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { types } from './../register/constants';
import logo from './../../assets/logo1.png';
import { useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
//import useKeypress from 'react-use-keypress';
// import { count } from "../../../../backend/models/subject";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor:
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'red',
    border: 'none',
    fontSize: 16,
    width: '100%', //'auto
    padding: '15px 12px',
    // width: '100%',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Index = () => {
  const [buttonIndex, setButtonIndex] = useState(-1);
  const [buttonDir, setButtonDir] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const [positiveSubjectsList, setPositiveSubjectsList] = useState([]);
  const [negativeSubjectsList, setNegativeSubjectsList] = useState([]);

  const [error, setError] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedDirection, setSelectedDirection] = useState('');

  const [positiveSubjectCount, setPositiveSubjectCount] = useState([]);
  const [negativeSubjectCount, setNegativeSubjectCount] = useState([]);

  // const [wordsToRemove, setWordsToRemove] = useState([]);
  const [feedbacks123stars, setfeedbacks123stars] = useState('');
  const [feedbacks345stars, setfeedbacks345stars] = useState('');

  const [positiveWords, setPositiveWords] = useState([]);
  const [negativeWords, setNegativeWords] = useState([]);

  const [subjectSentences, setSubjectSentences] = useState([]);

  const [openRemoveSubjectModal, setOpenRemoveSubjectModal] =
    React.useState(false);
  const handleClickOpenRemoveSubjectModal = () => {
    setOpenRemoveSubjectModal(true);
  };

  const handleCloseRemoveSubjectModal = () => {
    setOpenRemoveSubjectModal(false);
  };

  // search bar
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState([]);
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // useEffect(() => {
  //     axios.get(process.env.REACT_APP_BASE_API_URL+"/words/all-words",
  //     ).then(res => {
  //         setWordsToRemove([...res.data]);
  //     }).catch(e => {
  //         console.log(e);
  //     })
  // }, []);

  // search bar
  useEffect(() => {
    const results = subjectSentences.filter((sentence) =>
      sentence.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    setSearchResults(results);
  }, [searchTerm, subjectSentences]);

  const addPositiveSubject = () => {
    setError('');
    const type = document.getElementById('typeSelect').textContent;
    const subject = document
      .getElementById('positiveSubjectInput')
      .value.trim();

    if (type.length > 1 && subject.length > 0) {
      axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/subjects/add-positive-subject',
          {
            name: subject,
            type,
          },
        )
        .then((res) => {
          if (res.status === 200) {
            positiveSubjectsList.push(subject);
            setPositiveSubjectsList([...positiveSubjectsList]);
            document.getElementById('positiveSubjectInput').value = '';
          }
        })
        .catch((e) => {
          console.log(e);
          setError('Subject Exists');
        });
    } else {
      setError('All fileds required');
    }
  };

  const addNegativeSubject = () => {
    setError('');
    const type = document.getElementById('typeSelect').textContent;
    const subject = document
      .getElementById('negativeSubjectInput')
      .value.trim();

    if (type.length > 1 && subject.length > 0) {
      axios
        .post(
          process.env.REACT_APP_BASE_API_URL + '/subjects/add-negative-subject',
          {
            name: subject,
            type,
          },
        )
        .then((res) => {
          if (res.status === 200) {
            negativeSubjectsList.push(subject);
            setNegativeSubjectsList([...negativeSubjectsList]);
            document.getElementById('negativeSubjectInput').value = '';
          }
        })
        .catch((e) => {
          console.log(e);
          setError('Subject Exists');
        });
    } else {
      setError('All fileds required');
    }
  };

  const deletePositiveSubject = (btnIndex) => {
    setError('');
    setSelectedSubject('');
    setSelectedDirection('');
    //const subjectToDelete = e.target.parentElement.textContent;
    // const subjectToDelete = document.getElementById("pos" + btnIndex).textContent;
    // const type = document.getElementById("typeSelect").textContent;
    // const index = positiveSubjectsList.indexOf(subjectToDelete);

    handleClickOpenRemoveSubjectModal();
    setButtonIndex(btnIndex);
    setButtonDir('positive');
  };
  const deleteNegativeSubject = (btnIndex) => {
    setError('');
    setSelectedSubject('');
    setSelectedDirection('');
    // const subjectToDelete = document.getElementById("neg" + btnIndex).textContent;;
    // const type = document.getElementById("typeSelect").textContent;
    // const index = negativeSubjectsList.indexOf(subjectToDelete);

    handleClickOpenRemoveSubjectModal();
    setButtonIndex(btnIndex);
    setButtonDir('negative');
  };

  const deleteSubject = (buttonIndex, buttonDir) => {
    setError('');
    setSelectedSubject('');
    setSelectedDirection('');
    handleCloseRemoveSubjectModal();

    let subjectToDelete = '';
    let type = '';
    let index = -1;

    if (buttonDir === 'negative') {
      subjectToDelete = document.getElementById(
        'neg' + buttonIndex,
      ).textContent;
      type = document.getElementById('typeSelect').textContent;
      index = negativeSubjectsList.indexOf(subjectToDelete);
      if (index > -1) {
        // only splice array when item is found
        negativeSubjectsList.splice(index, 1); // 2nd parameter means remove one item only
        setNegativeSubjectsList([...negativeSubjectsList]);

        // delete subject from db
        axios
          .delete(
            process.env.REACT_APP_BASE_API_URL +
              '/subjects/delete-negative-subject',
            {
              params: {
                name: subjectToDelete,
                type,
              },
            },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log('not found');
      }
    } else {
      //positive
      subjectToDelete = document.getElementById(
        'pos' + buttonIndex,
      ).textContent;
      type = document.getElementById('typeSelect').textContent;
      index = positiveSubjectsList.indexOf(subjectToDelete);
      if (index > -1) {
        // only splice array when item is found
        positiveSubjectsList.splice(index, 1); // 2nd parameter means remove one item only
        setPositiveSubjectsList([...positiveSubjectsList]);

        // delete subject from db
        axios
          .delete(
            process.env.REACT_APP_BASE_API_URL +
              '/subjects/delete-positive-subject',
            {
              params: {
                name: subjectToDelete,
                type,
              },
            },
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log('not found');
      }
    }
  };

  const changeSelectType = (e) => {
    const selectedType = e.target.value;
    setError('');
    //get type's positive subjects
    axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          '/subjects/positive-subjects-by-type',
        {
          params: {
            type: selectedType,
          },
        },
      )
      .then((res) => {
        setPositiveSubjectsList([...res.data]);
      })
      .catch((e) => {
        console.log(e);
      });

    //get type's negative subjects
    axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          '/subjects/negative-subjects-by-type',
        {
          params: {
            type: selectedType,
          },
        },
      )
      .then((res) => {
        setNegativeSubjectsList([...res.data]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const selectSubject = (e, dir) => {
    setSelectedSubject(e.target.textContent);
    setSelectedDirection(dir);
    handleClickOpen();

    axios
      .get(
        process.env.REACT_APP_BASE_API_URL + '/subjects/all-subject-sentences',
        {
          params: {
            subject: e.target.textContent,
          },
        },
      )
      .then((res) => {
        setSubjectSentences(res.data);
      })
      .catch((e) => console.log(e));
  };

  const deleteSentenceFromSubject = async (index, selectedSubject) => {
    const sentenceToDelete = subjectSentences[index];
    subjectSentences.splice(index, 1);
    setSubjectSentences([...subjectSentences]);

    await axios.delete(
      process.env.REACT_APP_BASE_API_URL +
        '/subjects/delete-sentence-from-subject',
      {
        params: {
          sentence: sentenceToDelete,
          subject: selectedSubject,
        },
      },
    );
  };

  const addSentence = () => {
    setError('');
    const type = document.getElementById('typeSelect').textContent;
    const sentence = document.getElementById('sentenceInput').value.trim();

    if (type.length <= 1) {
      setError('select type');
      return;
    }
    if (!sentence) {
      setError('add sencense to the input');
      return;
    }
    if (sentence.indexOf(' ') === -1) {
      setError('sentence has only one word!');
      return;
    }
    if (!selectedSubject || !selectedDirection) {
      setError('select subject');
      return;
    }

    axios
      .get(
        process.env.REACT_APP_BASE_API_URL +
          '/subjects/check-if-sentence-exists',
        {
          params: {
            sentence,
          },
        },
      )
      .then((res) => {
        const sentenceExists = res.data;
        if (sentenceExists) {
          setError('sentence allready exists in a diffrent subject');
        } else {
          axios
            .post(
              process.env.REACT_APP_BASE_API_URL + '/subjects/add-sentence',
              {
                name: selectedSubject,
                type,
                direction: selectedDirection,
                sentence,
              },
            )
            .then((res) => {
              document.getElementById('sentenceInput').value = '';
            })
            .catch((e) => {
              console.log(e);
              setError('sentence allready exists in this subject');
            });
        }
      })
      .catch((e) => console.log(e));
  };

  const readUploadFile = async (e) => {
    setError('');
    e.preventDefault();
    let type = document.getElementById('typeSelect').textContent;
    if (type.length <= 1) {
      type = 'General';
    }
    const feedbacks = await readFeedbacks(e);

    let feedbacks123stars = feedbacks.feedbacks123;
    let feedbacks345stars = feedbacks.feedbacks345;

    // const res1 = await axios.post(process.env.REACT_APP_BASE_API_URL+"/procceseFeedback/fix-negative-sentences",{feedbacks:feedbacks123stars})
    // feedbacks123stars = res1.data;

    // const res2 = await axios.post(process.env.REACT_APP_BASE_API_URL+"/procceseFeedback/fix-positive-sentences",{feedbacks:feedbacks345stars})
    // feedbacks345stars = res2.data;

    const res3 = await axios.post(
      process.env.REACT_APP_BASE_API_URL +
        '/procceseFeedback/proccese-negative-feedback',
      { feedbacks: feedbacks123stars, type },
    );
    feedbacks123stars = res3.data.feedback;
    setNegativeSubjectCount([...res3.data.arr]);

    const res4 = await axios.post(
      process.env.REACT_APP_BASE_API_URL +
        '/procceseFeedback/proccese-positive-feedback',
      { feedbacks: feedbacks345stars, type },
    );
    feedbacks345stars = res4.data.feedback;
    setPositiveSubjectCount([...res4.data.arr]);

    const positiveWordsToRemark = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/subjects/sentences-words',
      {
        params: {
          type,
          direction: 'positive',
        },
      },
    );
    setPositiveWords(positiveWordsToRemark.data);

    const negativeWordsToRemark = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/subjects/sentences-words',
      {
        params: {
          type,
          direction: 'negative',
        },
      },
    );
    setNegativeWords(negativeWordsToRemark.data);

    setfeedbacks123stars(feedbacks123stars);
    setfeedbacks345stars(feedbacks345stars);
  };

  const readFeedbacks = (e) => {
    return new Promise((resolve, reject) => {
      let feedbacks345stars = '';
      let feedbacks123stars = '';
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);

          for (const row of json) {
            if (row.__rowNum__ > 1) {
              const stars = row.__EMPTY_2;
              const feedback = row.__EMPTY_1;
              if (stars >= 3) {
                feedbacks345stars += ' ' + feedback;
              }
              if (stars <= 3) {
                feedbacks123stars += ' ' + feedback;
              }
            }
          }
          resolve({
            feedbacks123: feedbacks123stars,
            feedbacks345: feedbacks345stars,
          });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
      document.getElementById('upload').value = '';
    });
  };

  // const addWord = () => {
  //     setError("");
  //     const word = document.getElementById("addWord").value;
  //     wordsToRemove.push(word);
  //     setWordsToRemove([...wordsToRemove]);
  //     axios.post(process.env.REACT_APP_BASE_API_URL+"/words/add-word", {
  //         word,
  //     }).then(res => {

  //     }).catch(e => {
  //         console.log(e);
  //     })
  //     document.getElementById("addWord").value = "";
  // }

  // const deleteWord = (index) => {
  //     setError("");
  //     const wordToRemove =  wordsToRemove[index];
  //     wordsToRemove.splice(index, 1);
  //     setWordsToRemove([...wordsToRemove]);

  //     axios.delete(process.env.REACT_APP_BASE_API_URL+"/words/delete-word", {params:{
  //         word: wordToRemove,
  //     }}).then(res => {

  //     }).catch(e => {
  //         console.log(e);
  //     })
  // }

  const positiveSubjectKeyDown = (e) => {
    if (e.key === 'Enter') {
      addPositiveSubject();
    }
  };

  const negativeSubjectKeyDown = (e) => {
    if (e.key === 'Enter') {
      addNegativeSubject();
    }
  };

  const addSentenceKeyDown = (e) => {
    if (e.key === 'Enter') {
      addSentence();
    }
  };

  // const downloadExcelWordsNotFound = async () => {
  //   setError('');
  //   let exists = await axios.get(
  //     process.env.REACT_APP_BASE_API_URL +
  //       '/speechToText/words-not-found-excel-file-exists',
  //   );

  //   if (exists.data) {
  //     await axios
  //       .get(
  //         process.env.REACT_APP_BASE_API_URL +
  //           '/speechToText/download-excel-words-not-found',
  //         { responseType: 'blob' },
  //       )
  //       .then((response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'wordsNotFound.xlsx');
  //         document.body.appendChild(link);
  //         link.click();
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   } else {
  //     setError('wordsNotFound.xlsx dose not exists');
  //   }

  //   exists = await axios.get(
  //     process.env.REACT_APP_BASE_API_URL +
  //       '/speechToText/words-not-found-excel-file-exists',
  //   );

  //   if (exists.data) {
  //     await axios.delete(
  //       process.env.REACT_APP_BASE_API_URL +
  //         '/speechToText/delete-words-not-found-excel-file',
  //     );
  //   }
  // };

  return (
    // <React.Fragment>
    <>
      <Container>
        <Box
          textAlign="center"
          style={{ backgroundColor: 'black' }}
          item
          xs={2}
          onClick={() => navigate('/user/dashboard')}
        >
          <img src={logo} alt="" style={{ cursor: 'pointer' }} />
        </Box>
        <center>
          <Button onClick={() => navigate('/admin-options-main')}>
            Admin Main Menu
          </Button>
          <Typography style={{ color: 'red' }}>{error}</Typography>
        </center>
        <Box container spacing={3} marginTop="5%" margin="50px">
          <Grid xs={5}>
            <FormControl sx={{ minWidth: '100%', mt: 2 }} variant="standard">
              <InputLabel shrink htmlFor="bootstrap-input">
                Type*
              </InputLabel>
              <Select
                id="typeSelect"
                style={{ width: '300px' }}
                onChange={changeSelectType}
                input={<BootstrapInput />}
              >
                {types.map((type) => (
                  <MenuItem value={type} name={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <div>
            <Dialog
              open={openRemoveSubjectModal}
              onClose={handleCloseRemoveSubjectModal}
              buttonIndex={buttonIndex}
              buttonDir={buttonDir}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Remove Jbject'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  You about to remove a subject are you sure you want to remove
                  this subject?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => deleteSubject(buttonIndex, buttonDir)}>
                  Remove
                </Button>
                <Button onClick={handleCloseRemoveSubjectModal} autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <Grid marginTop="50px" container spacing={2} direction="row" xs={12}>
            <Grid style={{ border: '1px solid #5FEFC0' }} xs={5}>
              <h2 dir="rtl">
                <center>נושאים חיוביים</center>
              </h2>
              {positiveSubjectsList.map((subject, index) => {
                return (
                  <div dir="rtl">
                    <IconButton
                      onClick={() => deletePositiveSubject(index)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Button
                      id={'pos' + index}
                      value={subject}
                      name={subject}
                      onClick={(e) => selectSubject(e, 'positive')}
                      style={{ textTransform: 'unset' }}
                    >
                      {subject}
                    </Button>
                  </div>
                );
              })}
              <Input
                style={{ backgroundColor: '#BDDAFF' }}
                dir="rtl"
                id="positiveSubjectInput"
                onKeyDown={positiveSubjectKeyDown}
              ></Input>
              <Button
                style={{ textTransform: 'unset', margin: '4px' }}
                size="small"
                variant="outlined"
                onClick={addPositiveSubject}
              >
                הוסף נושא חיובי
              </Button>
            </Grid>
            <Grid style={{ border: '1px solid #5FEFC0' }} xs={5}>
              <h2 dir="rtl">
                <center>נושאים שליליים</center>
              </h2>
              {negativeSubjectsList.map((subject, index) => {
                return (
                  <div dir="rtl">
                    <IconButton
                      onClick={() => deleteNegativeSubject(index)}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Button
                      id={'neg' + index}
                      value={subject}
                      name={subject}
                      onClick={(e) => selectSubject(e, 'negative')}
                      style={{ textTransform: 'unset' }}
                    >
                      {subject}
                    </Button>
                  </div>
                );
              })}
              <Input
                style={{ backgroundColor: '#BDDAFF' }}
                dir="rtl"
                id="negativeSubjectInput"
                onKeyDown={negativeSubjectKeyDown}
              ></Input>
              <Button
                style={{ textTransform: 'unset', margin: '4px' }}
                size="small"
                variant="outlined"
                onClick={addNegativeSubject}
              >
                הוסף נושא שלילי
              </Button>
            </Grid>
          </Grid>
          <Grid textAlign="right">
            <h3>
              {selectedSubject} {selectedDirection}
            </h3>
            <Input
              id="sentenceInput"
              dir="rtl"
              style={{ backgroundColor: '#BDDAFF' }}
              onKeyDown={addSentenceKeyDown}
            ></Input>
            <Button
              style={{ textTransform: 'unset' }}
              size="small"
              variant="outlined"
              onClick={addSentence}
            >
              הוסף משפט
            </Button>
          </Grid>
        </Box>
        <Grid container spacing={2} xs={10} display="flex">
          <Grid xs={5}>
            <Typography>
              <center>
                <h4>נושאים חיוחיים שנמצאו</h4>
              </center>
            </Typography>
            <Grid style={{ border: '1px solid #5FEFC0' }}>
              {positiveSubjectCount.map((subject) => {
                return (
                  <Typography dir="rtl" paddingRight="4px">
                    {subject.count} {subject.subject} {subject.type}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
          <Grid xs={5}>
            <Typography>
              <center>
                <h4>נושאים חיוביים שנמצאו</h4>
              </center>
            </Typography>
            <Grid style={{ border: '1px solid #5FEFC0' }}>
              {negativeSubjectCount.map((subject) => {
                return (
                  <Typography dir="rtl" paddingRight="4px">
                    {subject.count} {subject.subject} {subject.type}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ textAlign: 'right', marginTop: '2.5%' }}>
          <Button
            variant="contained"
            style={{ textTransform: 'unset' }}
            component="label"
          >
            <input
              type="file"
              name="upload"
              id="upload"
              hidden
              onChange={readUploadFile}
            />
            Upload File
          </Button>
        </Grid>
        {/* <Grid container xs={20}   justifyContent="center" style={{ border: "1px solid #5FEFC0", marginTop:"5%" }}>
                        <Typography><h3>Words to delete from text</h3></Typography>
                        <Grid container xs={20} justifyContent="flex-end">
                            {
                                wordsToRemove.map((word,index) => {
                                    return(
                                        <Typography> {word} 
                                            <IconButton aria-label="delete" onClick={()=>deleteWord(index)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Typography>
                                    )
                                })
                            }
                        </Grid>
                        <Grid container justifyContent="flex-end">
                            <Input dir="rtl" id="addWord" style={{ backgroundColor:"#BDDAFF" }} onKeyDown={addWordKeyDown}></Input>
                            <Button style={{textTransform: "unset"}} size="small" variant="outlined" onClick={addWord}>Add Word</Button>
                        </Grid>
                </Grid> */}

        <Box marginTop="2%" marginBottom="2%">
          <Grid
            dir="rtl"
            style={{ border: '1px solid #5FEFC0', wordWrap: 'break-word' }}
            marginBottom="10px"
          >
            <Typography>
              <center>
                <h4>feedbacks חיוביים</h4>
              </center>
            </Typography>
            <Highlighter
              searchWords={positiveWords}
              autoEscape={true}
              textToHighlight={feedbacks345stars}
            />
          </Grid>
          <Grid
            dir="rtl"
            style={{ border: '1px solid #5FEFC0', wordWrap: 'break-word' }}
          >
            <Typography>
              <center>
                <h4>feedbacks שליליים</h4>
              </center>
            </Typography>
            <Highlighter
              searchWords={negativeWords}
              autoEscape={true}
              textToHighlight={feedbacks123stars}
            />
          </Grid>
        </Box>
        <Dialog
          // fullScreen
          scroll="paper"
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {selectedSubject} {selectedDirection}
              </Typography>
            </Toolbar>
            <Input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleChange}
            />
          </AppBar>
          <List>
            {searchResults.map((sentence, index) => {
              return (
                <ListItem>
                  <ListItemText dir="rtl" primary={sentence} />
                  <IconButton
                    aria-label="delete"
                    onClick={() =>
                      deleteSentenceFromSubject(index, selectedSubject)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
        </Dialog>
      </Container>
    </>
    //   </React.Fragment>
  );
};

export default Index;
