import React from "react";
import AdminOpitonsEnglishCompotenet from "../../components/adminOptioinsEnglish";

const AdminOptionisEnglish = () => {
  return (
    <React.Fragment>
      <AdminOpitonsEnglishCompotenet />
    </React.Fragment>
  );
};

export default AdminOptionisEnglish;