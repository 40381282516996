import React from 'react';
import { Container, Button, Dialog } from '@mui/material';

import axios from 'axios';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function RemoveSubjectDialog(props) {
  const deleteSubject = async () => {
    const {
      subjectToRemove,
      subjectSentiment,
      selectedType,
      questionNumber,
      positiveSubjectsList,
      setPositiveSubjectsList,
      negativeSubjectsList,
      setNegativeSubjectsList,
    } = props;

    await axios
      .delete(
        process.env.REACT_APP_BASE_API_URL + '/italianSubjects/delete-subject',
        {
          params: {
            name: subjectToRemove,
            type: selectedType,
            direction: subjectSentiment,
            questionNumber,
          },
        },
      )
      .then((res) => {
        const { message } = res.data;
        console.log(message);
        //setError(message);
      })
      .catch((error) => {
        if (error.response.data) {
          const { message } = error.response.data;
          console.log(message);
        } else {
          console.log(error);
        }
      });

    if (subjectSentiment === 'positive') {
      const indexToRemove = positiveSubjectsList.indexOf(subjectToRemove);
      if (indexToRemove > -1) {
        // only splice array when item is found
        positiveSubjectsList.splice(indexToRemove, 1); // 2nd parameter means remove one item only
        setPositiveSubjectsList([...positiveSubjectsList]);
      } else {
        console.log('not found');
      }
    } else {
      //subjectSentiment === negative
      const indexToRemove = negativeSubjectsList.indexOf(subjectToRemove);
      if (indexToRemove > -1) {
        // only splice array when item is found
        negativeSubjectsList.splice(indexToRemove, 1); // 2nd parameter means remove one item only
        setNegativeSubjectsList([...negativeSubjectsList]);
      } else {
        console.log('not found');
      }
    }

    props.handleCloseRemoveSubjectModal();
  };

  return (
    <Container>
      <Dialog
        open={props.openRemoveSubjectModal}
        onClose={props.handleCloseRemoveSubjectModal}
        // buttonIndex={buttonIndex}
        // buttonDir={buttonDir}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Remove Subject'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You about to remove a subject are you sure you want to remove this
            subject?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteSubject()}>Remove</Button>
          <Button onClick={props.handleCloseRemoveSubjectModal} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default RemoveSubjectDialog;
