import React from 'react';
import xlsx from 'xlsx';
import axios from 'axios';

import { Button } from '@mui/material';

function SaveHotelButton(props) {
  const { clientId, setHotelsList, hotelsList, handleClose, hotelVersion } =
    props;
  const [disabledAddHotelButton, setDisabledAddHotelButton] =
    React.useState(true);
  React.useEffect(() => {
    if (!hotelVersion) {
      setDisabledAddHotelButton(true);
    } else {
      setDisabledAddHotelButton(false);
    }
  }, [hotelVersion]);
  const readExcelData = async function (e) {
    e.preventDefault();
    if (!hotelVersion) {
      return;
    }
    const hotelDetails = await geHotelNameAndForeignIdFromExcel(e);

    handleClose();
    await axios
      .post(process.env.REACT_APP_BASE_API_URL + '/hotels/save-hotel', {
        name: hotelDetails.hotelName,
        version: hotelVersion,
        foreignId: hotelDetails.foreignId,
        clientId,
      })
      .then((res) => {
        const savedHotel = res.data;
        hotelsList.push(savedHotel);
        setHotelsList([...hotelsList]);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const geHotelNameAndForeignIdFromExcel = function (e) {
    return new Promise((resolve, reject) => {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = xlsx.utils.sheet_to_json(worksheet);
          const hotelDetails = Object.keys(json[0])[0].split(' '); // ['Store', 'ID=31', 'Name=Hotel', 'rannana', 'Reviews', 'count=21']

          // foreignId
          const foreignId = hotelDetails[1].split('=')[1]; // 'ID=31'.split('=')
          // hotelName
          hotelDetails.splice(0, 2); // remove 2 first items // ['Name=Hotel', 'rannana', 'Reviews', 'count=21']
          const index = hotelDetails.indexOf('Reviews'); // get Index of eht word Reviews
          hotelDetails.splice(index, hotelDetails.length); // remove from index to end ['Name=Hotel', 'rannana']
          const hotelName = hotelDetails.join(' ').split('=')[1]; // ['Name=Hotel', 'rannana'].join(' ') => 'Name=Hotel rannana'.split('=') =>[Name, hotel rannana]
          resolve({ hotelName, foreignId });
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      } else {
        reject({});
      }
      document.getElementById('upload').value = '';
    });
  };
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        style={{ textTransform: 'unset', margin: '4px' }}
        size="small"
        component="label"
        disabled={disabledAddHotelButton}
      >
        <input
          type="file"
          name="upload"
          id="upload"
          hidden
          onChange={readExcelData}
        />
        Add Client Hotel Excel
      </Button>
    </React.Fragment>
  );
}

export default SaveHotelButton;
