import * as React from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import { Select, MenuItem, TextField } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function SaveHotelDepartmentDialog({
  selectedHotelId,
  // hotelDepartmentsList,
  // setHotelDepartmentsList,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [disabledAddDepartmentButton, setDisabledAddDepartmentButton] =
    React.useState(true);
  React.useEffect(() => {
    if (!selectedHotelId) {
      setDisabledAddDepartmentButton(true);
    } else {
      setDisabledAddDepartmentButton(false);
    }
  }, [selectedHotelId]);

  const [departmentsList, setDepartmentsList] = React.useState([]);
  React.useEffect(() => {
    const getDepartments = async function () {
      const res = await axios
        .get(process.env.REACT_APP_BASE_API_URL + '/hotels/get-departments')
        .catch((e) => {
          if (e.response.status === 404) {
            console.log(e.response.data.message);
            // return e.response.data.message;
          }
        });
      if (res) {
        setDepartmentsList(res.data);
      }
    };
    if (selectedHotelId) {
      getDepartments();
    }
  }, [selectedHotelId]);

  const [hotelDepartmentsList, setHotelDepartmentsList] = React.useState([]);
  React.useEffect(() => {
    const getHotelDepartments = async function () {
      const res = await axios
        .get(
          process.env.REACT_APP_BASE_API_URL + '/hotels/get-hotel-departments',
          { params: { selectedHotelId } },
        )
        .catch((e) => {
          if (e.response.status === 404) {
            console.log(e.response.data.message);
            // return e.response.data.message;
          }
        });
      if (res) {
        // setHotelDepartmentsList(res.data);
        setHotelDepartmentsList(res.data);
      }
    };
    if (selectedHotelId) {
      getHotelDepartments();
    }
  }, [selectedHotelId]);

  const [
    departmentsAddToHotelDepartments,
    setDepartmentsAddToHotelDepartments,
  ] = React.useState([]);
  React.useEffect(() => {
    // Finding intersection
    const intersection = departmentsList.filter((dep1) =>
      hotelDepartmentsList.some((dep2) => dep2.department === dep1._id),
    );
    // Finding what's left in array1 from the intersection
    const leftInDepartmentsList = departmentsList.filter(
      (dep1) => !intersection.some((dep2) => dep2._id === dep1._id),
    );
    // console.log(leftInDepartmentsList);
    setDepartmentsAddToHotelDepartments(leftInDepartmentsList);
  }, [hotelDepartmentsList, departmentsList]);

  const [departmentId, setDepartmentId] = React.useState('');
  const changeSelectDepartments = function (e) {
    setDepartmentId(e.target.value);
  };

  const saveHotelDepartment = function () {
    // const name = document.getElementById('departmentName').value.trim();
    let emails = document
      .getElementById('departmentEmails')
      .value.split(/[\n ]+/);
    emails = emails.filter((email) => email !== '');

    // console.log(name, emails);
    axios
      .post(
        process.env.REACT_APP_BASE_API_URL + '/hotels/save-hotel-department',
        {
          departmentId,
          hotelId: selectedHotelId,
          emails,
        },
      )
      .then((res) => {
        const savedDepartment = res.data;
        departmentsList.push(savedDepartment);
        setDepartmentsList([...departmentsList]);
      })
      .catch((e) => {
        console.log(e);
      });

    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{ textTransform: 'unset' }}
        size="small"
        disabled={disabledAddDepartmentButton}
      >
        Add Hotel Department
      </Button>

      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, textAlign: 'center' }}
          id="customized-dialog-title"
        >
          Add Hotel Department
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {/* <Input id="departmentName" placeholder="Enter Department Name" /> */}
          <TextField
            id="departmentEmails"
            label="Enter an Emails list"
            multiline
            fullWidth
            // maxRows={4}
            variant="filled"
          />
        </DialogContent>
        <Typography>Select department</Typography>
        <Select
          style={{ backgroundColor: '#CDCDCD' }}
          onChange={changeSelectDepartments}
          // disabled={disabledDepartmentsSelect}
        >
          {departmentsAddToHotelDepartments.map((department) => {
            return (
              <MenuItem
                value={department._id}
                name={department.name}
                key={department._id}
              >
                {department.name}
              </MenuItem>
            );
          })}
        </Select>
        <DialogActions>
          <Button autoFocus onClick={saveHotelDepartment}>
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
