import {
  Grid,
  // Box,
  // Typography,
  Card,
  Container,
  // Menu,
  // MenuItem,
  // Modal,
  InputLabel,
  Button,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useAppState } from '../../AppStateContext';
import QuestionsCharts from '../graph/questionCharts';
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import puppeteer from "puppeteer";
import './index.css';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// import logo from "./../../assets/logo1.png";
import downLoadPdfIcon from './../../assets/downLoadPdfIcon.png';

// import html2pdf from "html2pdf.js";

// // Ensure pdfjs worker is loaded
// pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// RANGE DATES
// import { DateRangePicker } from "react-dates";
// import "react-dates/lib/css/_datepicker.css";
// import "react-dates/initialize";

// const questions = [
//   "איך היתה חוויות השירות בתהליך ההזמנה?",
//   "האם ההזמנה היתה מוכנה בזמן?",
//   "איזה דירוג תתנו לאיכות המנות שהזמנתם?",
//   "משהו נוסף שחשוב שנדע?",
// ];

// const downloadPdfSrc =
const Index = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [isOpenStartDate, setIsOpenStartDate] = useState(false);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);

  const changeStartDate = (e) => {
    const timeZoneOffset = e.getTimezoneOffset();
    const startDateTimestamp = e.getTime() - timeZoneOffset * 60 * 1000;
    const utcStartDate = new Date(startDateTimestamp);
    setStartDate(utcStartDate);
    setIsOpenStartDate(false);
  };

  const changeEndDate = (e) => {
    const timeZoneOffset = e.getTimezoneOffset();
    const endDateTimestamp = e.getTime() - timeZoneOffset * 60 * 1000;
    const utcEndDate = new Date(endDateTimestamp);
    setEndDate(utcEndDate);
    setIsOpenEndDate(false);
  };

  const startDateClick = () => {
    setIsOpenStartDate(!isOpenStartDate);
  };

  const endDateClick = () => {
    setIsOpenEndDate(!isOpenEndDate);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const isInvited = Boolean(url.searchParams.get('invited'));
    if (isInvited) {
      document.getElementById('historyAnalytics').style.background = '#d5f4ec';
    }
  }, []);

  const { storeId, storeVersion } = useAppState(); // storeId = sotorId
  useEffect(() => {
    if (startDate && endDate && storeId) {
      getReviewsChartsData(startDate, endDate, storeId);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const getOldestRiview = async function () {
      const oldestReviwe = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/reviews/oldest-review',
        { params: { storeId } },
      );
      // setStartDate to oldest date in reviews data base
      if (Object.keys(oldestReviwe.data).length > 0) {
        setStartDate(new Date(oldestReviwe.data.date));
      } else {
        setStartDate(new Date());
      }
    };
    if (storeId) {
      getOldestRiview();
    }
  }, [storeId]);

  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    const getStoreQuestions = async function () {
      const storeQuestions = await axios.get(
        process.env.REACT_APP_BASE_API_URL + '/stores/get-store-questions',
        { params: { storeId: storeId } },
      );
      setQuestions(storeQuestions.data);
    };
    if (storeId) {
      getStoreQuestions();
    }
  }, [storeId]);

  const [q1Positive, setQ1Positive] = useState([]);
  const [q2Positive, setQ2Positive] = useState([]);
  const [q3Positive, setQ3Positive] = useState([]);
  const [q4Positive, setQ4Positive] = useState([]);

  const [q1Negative, setQ1Negative] = useState([]);
  const [q2Negative, setQ2Negative] = useState([]);
  const [q3Negative, setQ3Negative] = useState([]);
  const [q4Negative, setQ4Negative] = useState([]);

  const getReviewsChartsData = async function (startDate, endDate, storeId) {
    const start = new Date(startDate).toISOString();
    const end = new Date(endDate).toISOString();
    // console.log("H");
    const questionsChartsData = await axios.get(
      process.env.REACT_APP_BASE_API_URL + '/reviews/get-reviews-charts-data',
      {
        params: {
          storeId,
          start,
          end,
        },
      },
    );

    setQ1Positive(questionsChartsData.data.q1Positive.subjectCount);
    setQ2Positive(questionsChartsData.data.q2Positive.subjectCount);
    setQ3Positive(questionsChartsData.data.q3Positive.subjectCount);
    setQ4Positive(questionsChartsData.data.q4Positive.subjectCount);

    setQ1Negative(questionsChartsData.data.q1Negative.subjectCount);
    setQ2Negative(questionsChartsData.data.q2Negative.subjectCount);
    setQ3Negative(questionsChartsData.data.q3Negative.subjectCount);
    setQ4Negative(questionsChartsData.data.q4Negative.subjectCount);
  };

  const chartsRef12 = useRef(null);
  const chartsRef34 = useRef(null);

  const downloadPDFReport = async function () {
    const pdf = new jsPDF('landscape', 'px');
    const canvas12 = await html2canvas(chartsRef12.current);
    const chartImage12 = canvas12.toDataURL('image/jpeg', 1.0);
    pdf.addImage(
      chartImage12,
      'JPEG',
      10,
      10,
      pdf.internal.pageSize.getWidth() - 20,
      pdf.internal.pageSize.getHeight() - 20,
    );

    pdf.addPage();

    const canvas34 = await html2canvas(chartsRef34.current);
    const chartImage34 = canvas34.toDataURL('image/jpeg', 1.0);
    pdf.addImage(
      chartImage34,
      'JPEG',
      10,
      10,
      pdf.internal.pageSize.getWidth() - 20,
      pdf.internal.pageSize.getHeight() - 20,
    );

    pdf.save('charts.pdf');
  };

  return (
    <div>
      <React.Fragment>
        <Container>
          <Grid container>
            <Grid container direction="row" spacing={2}>
              <Grid xs={3} style={{ textAlign: 'center' }}>
                <Button
                  style={{ textTransform: 'unset' }}
                  onClick={startDateClick}
                >
                  <b>Select start date</b>
                </Button>
                <InputLabel>
                  <b>{moment(startDate).format('MMMM Do YYYY')}</b>
                </InputLabel>
                <Grid style={{ display: isOpenStartDate ? 'inline' : 'none' }}>
                  <Calendar value={startDate} onChange={changeStartDate} />
                </Grid>
              </Grid>
              <Grid xs={3} style={{ textAlign: 'center' }}>
                <Button
                  style={{ textTransform: 'unset' }}
                  onClick={endDateClick}
                >
                  <b>Select end date</b>
                </Button>
                <InputLabel>
                  <b>{moment(endDate).format('MMMM Do YYYY')}</b>
                </InputLabel>
                <Grid style={{ display: isOpenEndDate ? 'inline' : 'none' }}>
                  <Calendar
                    value={endDate}
                    onChange={changeEndDate}
                    minDate={startDate}
                  />
                </Grid>
              </Grid>
              {/* <Grid> */}
              <Button
                variant="text"
                // size="small"
                style={{
                  width: '32px',
                  height: '32px',
                  fontSize: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  textTransform: 'unset',
                  marginTop: '15px',
                }}
                onClick={downloadPDFReport}
              >
                <img alt="" width="32px" height="32px" src={downLoadPdfIcon} />
              </Button>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sx={{ pr: 2, mt: 3 }}
              justifyContent={'space-between'}
              ref={chartsRef12}
              // id="pdf-content"
            >
              <Grid item xs={12} style={{ paddingBottom: '4px' }}>
                <Card sx={{ p: 3 }}>
                  <QuestionsCharts
                    question={questions[0]}
                    positiveSubjects={q1Positive}
                    negativeSubjects={q1Negative}
                  />
                </Card>
              </Grid>
              {storeVersion === 1 ? (
                ''
              ) : (
                <Grid item xs={12} style={{ paddingBottom: '4px' }}>
                  <Card sx={{ p: 3 }}>
                    <QuestionsCharts
                      question={questions[1]}
                      positiveSubjects={q2Positive}
                      negativeSubjects={q2Negative}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
            {storeVersion === 1 ? (
              ''
            ) : (
              <Grid
                item
                container
                xs={12}
                sx={{ pr: 2, mt: 3 }}
                justifyContent={'space-between'}
                ref={chartsRef34}
                // id="pdf-content"
              >
                <Grid item xs={12} style={{ paddingBottom: '4px' }}>
                  <Card sx={{ p: 3 }}>
                    <QuestionsCharts
                      question={questions[2]}
                      positiveSubjects={q3Positive}
                      negativeSubjects={q3Negative}
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: '4px' }}>
                  <Card sx={{ p: 3 }}>
                    <QuestionsCharts
                      question={questions[3]}
                      positiveSubjects={q4Positive}
                      negativeSubjects={q4Negative}
                    />
                  </Card>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
};

export default Index;
