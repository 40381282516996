import React from 'react';
import { Grid, Box, Container } from '@mui/material';

import SelectClientStores from './stores/SelectClientStores';

import logo from './../../assets/logo1.png';
import { useNavigate } from 'react-router-dom';
import './index.css';

const Index = () => {
  const navigate = useNavigate();
  return (
    // <React.Fragment>
    <>
      <Container>
        <Box
          textAlign="center"
          style={{ backgroundColor: 'black' }}
          item
          xs={2}
          onClick={() => navigate('/user/dashboard')}
        >
          <img src={logo} alt="" style={{ cursor: 'pointer' }} />
        </Box>
        <center>
          <h1>Admin Main</h1>
        </center>
        <div div className="button-row">
          <button
            className="btn"
            onClick={() => navigate('/admin-options-1question')}
          >
            1 question עברית
          </button>
          <button
            className="btn"
            onClick={() => navigate('/admin-options-1question-eng')}
          >
            1 question English
          </button>
          <button
            className="btn"
            onClick={() => navigate('/admin-options-4questions')}
          >
            4 questions עברית
          </button>
          <button
            className="btn"
            onClick={() => navigate('/admin-options-english')}
          >
            English
          </button>
          <button className="btn" onClick={() => navigate('/admin-options-he')}>
            עברית
          </button>
          <button
            className="btn"
            onClick={() => navigate('/admin-options-hotels')}
          >
            Hotels
          </button>
          <button className="btn" onClick={() => navigate('/words-search')}>
            Words Search
          </button>
        </div>
        <Grid container spacing={2} style={{ marginTop: '50px' }}>
          <SelectClientStores storesVersion={1} />
          <SelectClientStores storesVersion={4} />
        </Grid>
      </Container>
    </>
    //   </React.Fragment>
  );
};

export default Index;
